export default {
  container: {
    margin: '0 -22px',
    padding: '40px 22px 22px',
    borderBottom: '1px solid #dedede'
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  holder: {
    position: 'relative',
    '&:after': {
      content: '""',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      position: 'absolute',
      left: '0',
      top: '0',
      width: '100%',
      height: '100%',
      pointerEvents: 'none',
      opacity: '0',
      transition: '0.3s ease-in'
    }
  },
  pending: {
    '& $holder': {
      '&:after': {
        opacity: '1',
        pointerEvents: 'auto'
      }
    }
  },
  title: {
    fontSize: '26px',
    lineHeight: '30px',
    fontWeight: 'normal',
    margin: '0',
    color: '#999'
  }
}
