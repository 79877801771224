import React from 'react'
import styles from './presentations-list.styles'
import injectSheet from 'react-jss'
import { Table, HeadCell } from 'pobble-components/dist/table'
import Presentation from './presentation/presentation.container'
import LoadMoreButton from './load-more-button/load-more-button.container'

const PresentationsList = ({ classes, presentations, hideLoadMore }) => (
  <div>
    <Table className={classes.table}>
      <thead>
        <tr>
          <HeadCell>Date</HeadCell>
          <HeadCell>Account</HeadCell>
          <HeadCell>Title</HeadCell>
          <HeadCell className={classes.center}>Status</HeadCell>
          <HeadCell />
        </tr>
      </thead>
      <tbody>
        {presentations.map(presentation => (
          <Presentation key={presentation.id} id={presentation.id} />
        ))}
      </tbody>
    </Table>
    {!hideLoadMore && <LoadMoreButton />}
  </div>
)

export default injectSheet(styles)(PresentationsList)
