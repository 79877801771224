import React, { Component, Fragment } from 'react'
import injectSheet from 'react-jss'

import styles from './comment-body.styles.js'

import CommentBodyModal from '../comment-body-modal/comment-body-modal.container.js'

import Button from 'pobble-components/dist/button'
import TextTruncate from 'react-text-truncate'

class CommentBody extends Component {
  constructor(props) {
    super(props)
    this.state = { openModal: false }
  }

  changeBodyHandler(event) {
    this.setState({ editedBody: event.target.value })
  }

  openModalHandler = () => {
    this.setState({ openModal: true })
  }

  closeModalHandler = () => {
    this.setState({ openModal: false })
  }

  render() {
    const { classes, id, body, onUpdate } = this.props
    return (
      <Fragment>
        <Button
          simple
          className={classes.container}
          action={this.openModalHandler}>
          <TextTruncate line={6} truncateText="…" text={body} />
        </Button>
        <CommentBodyModal
          isOpen={this.state.openModal}
          id={id}
          body={body}
          onUpdate={onUpdate}
          onClose={this.closeModalHandler}
        />
      </Fragment>
    )
  }
}

export default injectSheet(styles)(CommentBody)
