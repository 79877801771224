import { connect } from 'react-redux'
import DocumentsTable from './documents-table'
import { withRouter } from 'react-router'

const mapStateToProps = (state, { match }) => ({
  id: match.params.id
})

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(DocumentsTable)
)
