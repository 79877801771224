import { connect } from 'react-redux'
import ParentsTable from './parents-table'
import { withRouter } from 'react-router'

const mapStateToProps = (state, { match }) => ({
  pupilId: match.params.id
})

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(ParentsTable)
)
