import { connect } from 'react-redux'
import Documents from './student-details-documents'
import { withRouter } from 'react-router'
import { fetchStudentDocuments } from '../../../../../stores/student-details/student-details.actions'
import {
  getStudentDocumentsResult,
  getFetchStudentDocumentsRequest
} from '../../../../../stores/student-details/student-details.selectors'
import { errorHandler } from 'pobble-components/dist/error-handler'

const mapStateToProps = (state, { match }) => {
  return {
    permissions: getStudentDocumentsResult(state),
    pendingPermissions: getFetchStudentDocumentsRequest(state).pending
  }
}

const mapDispatchToProps = (dispatch, { match }) => ({
  onMount: () =>
    dispatch(fetchStudentDocuments(match.params.id)).catch(errorHandler)
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Documents)
)
