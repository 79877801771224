import { connect } from 'react-redux'
import UserDetailsAvatarForm from './user-details-avatar-form'
import {
  getUploadAvatarRequest,
  getRemoveAvatarRequest
} from '../../../../../stores/user-details/user-details.selectors'

const mapStateToProps = state => {
  const uploadAvatarRequest = getUploadAvatarRequest(state)
  const removeAvatarRequest = getRemoveAvatarRequest(state)
  return {
    pending: uploadAvatarRequest.pending || removeAvatarRequest.pending
  }
}

export default connect(
  mapStateToProps,
  null
)(UserDetailsAvatarForm)
