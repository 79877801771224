import React, { Component } from 'react'
import styles from './campaigns.styles'
import injectSheet from 'react-jss'
import Container from '../../shared/container/container'
import Main from '../../shared/main/main'
import Paper from 'pobble-components/dist/paper'
import SectionTitle from '../../shared/section-title/section-title'
import CampaignsList from './campaigns-list/campaigns-list.container'
import Spinner from 'pobble-components/dist/spinner'
import Button from 'pobble-components/dist/button'

class Campaigns extends Component {
  componentDidMount() {
    this.props.onMount()
  }
  render() {
    const { classes, pending } = this.props
    return (
      <Container>
        <Main>
          <Paper className={classes.container}>
            <SectionTitle title="Campaigns" />
            <Button href="/campaigns/new" tall className={classes.add}>
              Create a new campaign
            </Button>
            {pending && <Spinner className={classes.spinner} />}
            {!pending && <CampaignsList />}
          </Paper>
        </Main>
      </Container>
    )
  }
}

export default injectSheet(styles)(Campaigns)
