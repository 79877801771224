import {
  getWorks,
  apiFlagWork,
  apiUnflagWork,
  apiDeleteWork
} from '../../common/api/work'

const fetchWorks = (params, initiator) => ({
  requestPrefix: 'WORKS:FETCHING',
  params: { initiator },
  request: () =>
    getWorks({
      include:
        'work.names,work.timestamps,work.school.names,work.status,work.lesson,work.lesson.status',
      ...params
    })
})

const loadMoreWorks = () => ({
  type: 'WORKS:LOAD_MORE'
})

const resetFilters = () => ({ type: 'WORKS:RESET_FILTERS' })
const setSearchFilters = filters => ({
  type: 'WORKS:SET_SEARCH_FILTERS',
  filters
})

const flagWork = id => ({
  requestPrefix: 'WORKS:FLAG',
  request: () => apiFlagWork(id, { reason: 'Flagged via admin interface' })
})

const unflagWork = id => ({
  requestPrefix: 'WORKS:UNFLAG',
  request: () => apiUnflagWork(id)
})

const deleteWork = id => ({
  requestPrefix: 'WORKS:DELETE',
  params: { id },
  request: () => apiDeleteWork(id)
})

export {
  fetchWorks,
  flagWork,
  unflagWork,
  deleteWork,
  loadMoreWorks,
  setSearchFilters,
  resetFilters
}
