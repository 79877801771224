import React, { Component } from 'react'
import styles from './works-filters.styles'
import injectSheet from 'react-jss'
import FormInput from 'pobble-components/dist/form-elements/form-input'
import FormRadio from 'pobble-components/dist/form-elements/form-radio'
import debounce from 'lodash/debounce'

const DEBOUNCE_DELAY = 200

class WorksFilters extends Component {
  constructor(props) {
    super(props)
    this.state = {
      published: props.published,
      unpublished: props.unpublished,
      deleted: props.deleted,
      workTitle: props.workTitle,
      workId: props.workId,
      studentId: props.studentId,
      schoolId: props.schoolId,
      flagged: props.flagged
    }
  }

  onClearFilters = () => {
    const filters = {
      published: false,
      unpublished: false,
      deleted: false,
      flagged: false,
      workTitle: '',
      workId: '',
      studentId: '',
      schoolId: ''
    }
    this.setState(filters)
    this.props.onFiltersUpdate(filters)
  }
  clearStatuses = () => {
    this.setState({
      published: false,
      unpublished: false,
      deleted: false,
      flagged: false
    })
  }
  debouncedFiltersUpdate = debounce(
    () => this.props.onFiltersUpdate(this.state),
    DEBOUNCE_DELAY
  )
  handleChange = (value, key, callback) => {
    key !== 'workTitle' &&
      key !== 'studentId' &&
      key !== 'schoolId' &&
      key !== 'workId' &&
      this.clearStatuses()
    this.setState({ [key]: value })
    this.debouncedFiltersUpdate()
  }

  render() {
    const {
      published,
      unpublished,
      workTitle,
      workId,
      studentId,
      schoolId,
      flagged,
      deleted
    } = this.state
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <div className={classes.row}>
          <FormRadio
            name="status"
            id="unpublished"
            checked={unpublished}
            onChange={e =>
              this.handleChange(
                e.target.checked,
                'unpublished',
                'onUnpublishedChange'
              )
            }
          />
          <label className={classes.labelRadio} htmlFor="unpublished">
            Evidence bank
          </label>
          <FormRadio
            name="status"
            id="published"
            checked={published}
            onChange={e =>
              this.handleChange(
                e.target.checked,
                'published',
                'onPublishedChange'
              )
            }
          />
          <label className={classes.labelRadio} htmlFor="published">
            Published
          </label>
          <FormRadio
            name="status"
            id="flagged"
            checked={flagged}
            onChange={e =>
              this.handleChange(e.target.checked, 'flagged', 'onFlaggedChange')
            }
          />
          <label className={classes.labelRadio} htmlFor="flagged">
            Flagged
          </label>
          <FormRadio
            name="status"
            id="deleted"
            checked={deleted}
            onChange={e =>
              this.handleChange(e.target.checked, 'deleted', 'onFlaggedChange')
            }
          />
          <label className={classes.labelRadio} htmlFor="deleted">
            Deleted
          </label>
        </div>
        <div className={classes.row}>
          <label className={classes.labelInput} htmlFor="workTitle">
            Work
          </label>
          <FormInput
            id="workTitle"
            tall
            placeholder="Title"
            value={workTitle}
            onChange={e =>
              this.handleChange(
                e.target.value,
                'workTitle',
                'onWorkTitleChange'
              )
            }
          />
          <label className={classes.labelInput} htmlFor="workId">
            ID
          </label>
          <FormInput
            id="workId"
            tall
            placeholder="ID"
            value={workId}
            onChange={e =>
              this.handleChange(e.target.value, 'workId', 'onWorkIdChange')
            }
          />
          <label className={classes.labelInput} htmlFor="studentId">
            Student
          </label>
          <FormInput
            id="studentId"
            tall
            placeholder="Student ID"
            value={studentId}
            onChange={e =>
              this.handleChange(
                e.target.value,
                'studentId',
                'onStudentIdChange'
              )
            }
          />
          <label className={classes.labelInput} htmlFor="schoolId">
            School
          </label>
          <FormInput
            id="schoolId"
            tall
            placeholder="School ID"
            value={schoolId}
            onChange={e =>
              this.handleChange(e.target.value, 'schoolId', 'onSchoolIdChange')
            }
          />
        </div>
      </div>
    )
  }
}

export default injectSheet(styles)(WorksFilters)
