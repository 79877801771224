import {
  getTeachers,
  apiApprovePendingTeacher,
  apiDeclinePendingTeacher,
  apiAddTeacher,
  apiAddAsPendingTeacher
} from '../../common/api/teacher'

import { apiUpdateAccountDetails } from '../../common/api/admin'

const fetchTeachers = (params, initiator) => ({
  requestPrefix: 'TEACHERS:FETCHING',
  params: { initiator },
  request: () =>
    getTeachers({
      include: `collection.order.desc,collection.page.${(params &&
        params.page) ||
        1},account.names,account.subscriptions,account.email_identity,account.email_identity.email,account.policy,account.note,account.signin_details,account.timestamps,account.teacher_type,account.parent_types,account.pending_teacher_type,account.pending_teacher_type.school,account.pending_teacher_type.school.names,account.pending_teacher_type.school.urn,account.pending_teacher_type.details,account.signup_details,account.student_type,account.avatar,account.links,account.teacher_type.school,account.pending_teacher_type.school.paid,account.pending_teacher_type.school.address,account.pending_teacher_type.school.group_subs_seats,account.pending_teacher_type.school.last_login,account.pending_teacher_type.school.leaders_inactive,account.pending_teacher_type.school.school_organisations,account.pending_teacher_type.school.school_organisation.details,account.pending_teacher_type.school.website,account.pending_teacher_type.school.teacher_types`,
      ...params
    })
})

const setTeachers = teachers => ({
  type: 'TEACHERS:SET',
  data: teachers
})

const approveTeacher = teacherId => ({
  requestPrefix: 'TEACHER:APPROVE',
  params: { teacherId },
  request: () => apiApprovePendingTeacher(teacherId)
})

const disapproveTeacher = teacherId => ({
  requestPrefix: 'TEACHER:DISAPPROVE',
  params: { teacherId },
  request: () => apiDeclinePendingTeacher(teacherId)
})

const updateTeacherNotes = (teacherId, note) => ({
  requestPrefix: 'TEACHER:UPDATE_NOTES',
  params: { teacherId, note },
  request: () =>
    apiUpdateAccountDetails(
      teacherId,
      { note },
      {
        include: 'account.note'
      }
    )
})

const addTeacher = (schoolId, teacherEmail, teacherId) => ({
  requestPrefix: 'TEACHER:APPROVE',
  params: { schoolId, teacherEmail, teacherId },
  request: () => apiAddTeacher(schoolId, teacherEmail)
})

const addAsPendingTeacher = (schoolId, teacherId, schoolTitle) => ({
  requestPrefix: 'TEACHER:ADD_AS_PENDING_TEACHER',
  params: { schoolId, teacherId, schoolTitle },
  request: () => apiAddAsPendingTeacher(schoolId, teacherId)
})

const loadMoreTeachers = () => ({
  type: 'TEACHERS:LOAD_MORE'
})

export {
  setTeachers,
  fetchTeachers,
  approveTeacher,
  disapproveTeacher,
  updateTeacherNotes,
  addTeacher,
  loadMoreTeachers,
  addAsPendingTeacher
}
