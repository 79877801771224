import { connect } from 'react-redux'
import SchoolStudentsFilter from './students-filter'
import { getFilterSearch } from '../../../../stores/school-students-filter/school-students-filter.selectors'
import { setSearchValue } from '../../../../stores/school-students-filter/school-students-filter.actions.js'

const mapStateToProps = state => ({
  searchValue: getFilterSearch(state)
})

const mapDispatchToProps = dispatch => ({
  onSearchChange: e => dispatch(setSearchValue(e.target.value))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SchoolStudentsFilter)
