import { connect } from 'react-redux'
import ChildEntry from './child-entry'
import { getUserChildren } from '../../../../../../../stores/user-details/user-details.selectors'
import { unassignParent } from '../../../../../../../stores/user-details/user-details.actions'
import { withRouter } from 'react-router'

const mapStateToProps = (state, { id }) => {
  const child = getUserChildren(state).entries[id]
  const permission =
    child.permission === 'approved'
      ? { permissionStatus: true, permissionTooltip: 'Approved' }
      : child.permission === 'declined'
        ? { permissionStatus: false, permissionTooltip: 'Declined' }
        : { permissionStatus: 'pending', permissionTooltip: 'Pending' }

  return {
    name: child.full_name,
    ...permission
  }
}

const mapDispatchToProps = dispatch => ({ dispatch })

const mergeProps = (stateProps, { dispatch }, ownProps) => ({
  ...ownProps,
  ...stateProps,
  onDelete: () =>
    dispatch(unassignParent(ownProps.match.params.id, ownProps.id))
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(ChildEntry)
)
