import { createSelector } from 'reselect'

const getTeachersIds = state => state.TeachersStore.teachers.result
const getTeachersData = state => state.TeachersStore.teachers.entities.teachers
const isTeacherBeingUpdated = (state, id) =>
  state.TeachersStore.teachersUpdating[id] || false

const getAllTeachers = createSelector(
  [getTeachersIds, getTeachersData],
  (teachersIds, teachersData) => teachersIds.map(id => teachersData[id])
)

const getTeacher = (state, id) => getTeachersData(state)[id]

const getFetchTeachersRequest = state => state.TeachersStore.loading

const getFetchTeachersRequestInitiator = createSelector(
  [getFetchTeachersRequest],
  req => (!req.params ? null : req.params.initiator)
)

const getLimit = state => state.TeachersStore.currentLimit

const getServerParams = createSelector([getLimit], currentLimit => ({
  limit: currentLimit,
  page: currentLimit < 21 ? 1 : 2
}))

const lessTeachersThanLimit = createSelector(
  [getTeachersIds, getLimit],
  (teachers, currentLimit) =>
    !teachers ? true : teachers.length < currentLimit
)

const getPendingTeachers = createSelector([getAllTeachers], teachers =>
  teachers.filter(teacher => teacher.pending_teacher_type)
)

const getPendingTeachersNumber = createSelector(
  [getPendingTeachers],
  teachers => teachers.length
)

const areTeachersFetching = state => state.TeachersStore.loading

const getPendingTeachersWithoutSchool = createSelector(
  [getAllTeachers],
  teachers =>
    teachers.filter(
      teacher =>
        teacher.pending_teacher_type && teacher.pending_teacher_type.description
    )
)

const getPendingTeachersWithoutSchoolNumber = createSelector(
  [getPendingTeachersWithoutSchool],
  teachers => teachers.length
)

export {
  getAllTeachers,
  getTeacher,
  getPendingTeachers,
  getPendingTeachersNumber,
  areTeachersFetching,
  getTeachersIds,
  isTeacherBeingUpdated,
  getFetchTeachersRequest,
  getFetchTeachersRequestInitiator,
  lessTeachersThanLimit,
  getServerParams,
  getPendingTeachersWithoutSchool,
  getPendingTeachersWithoutSchoolNumber
}
