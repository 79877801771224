import { connect } from 'react-redux'
import UserDetailsAvatar from '../../../../shared/details/details-avatar/details-avatar'
import {
  getUserAvatar,
  getUser
} from '../../../../../stores/user-details/user-details.selectors'

const mapStateToProps = state => {
  const userAvatar = getUserAvatar(state)
  let user = getUser(state)
  const fullName = user.full_name === ' ' ? '' : user.full_name
  return {
    name: fullName || '-',
    url: !userAvatar ? null : userAvatar.url
  }
}

export default connect(
  mapStateToProps,
  null
)(UserDetailsAvatar)
