import { connect } from 'react-redux'
import SchoolStudents from './school-students'
import { getStudentsSchool } from '../../../stores/school-retirees/school-students.selectors'

const mapStateToProps = state => {
  const school = getStudentsSchool(state)
  return {
    sectionTitle: school ? `${school} - Retired Students` : ''
  }
}

export default connect(
  mapStateToProps,
  null
)(SchoolStudents)
