import { createSelector } from 'reselect'
import { getTeachers } from '../school-teachers/school-teachers.selectors.js'

const getFilterSearch = state => state.SchoolTeachersFilter.search

const getFilteredTeachers = createSelector(
  [getFilterSearch, getTeachers],
  (searchValue, teachers) =>
    !searchValue
      ? teachers
      : teachers.filter(teacher => {
          let searchTerm = searchValue.toLowerCase()
          return (
            (teacher.account.full_name &&
              teacher.account.full_name.toLowerCase().indexOf(searchTerm) !==
                -1) ||
            (teacher.account.email_identity &&
              teacher.account.email_identity.email
                .toLowerCase()
                .indexOf(searchTerm) !== -1)
          )
        })
)

export { getFilteredTeachers, getFilterSearch }
