import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Work from './work'
import { getWork } from '../../../../../stores/works/works.selectors'
import {
  flagWork,
  unflagWork,
  deleteWork
} from '../../../../../stores/works/works.actions'
import { errorHandler } from 'pobble-components/dist/error-handler'

import dateFns from 'date-fns'

const mapStateToProps = (state, { id }) => {
  const work = getWork(state, id)
  return {
    title: work.title,
    updated_at: dateFns.format(work.updated_at, 'MM/DD/YYYY H:s') || '',
    school: work.school && work.school.title,
    status: work.status,
    lessonDelivered: work.lesson && work.lesson.delivered
  }
}

const mapDispatchToProps = (dispatch, { id }) => ({
  onWorkFlag: id => dispatch(flagWork(id)).catch(errorHandler),
  onWorkUnflag: id => dispatch(unflagWork(id)).catch(errorHandler),
  onWorkDelete: id => dispatch(deleteWork(id)).catch(errorHandler)
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Work)
)
