import { createSelector } from 'reselect'

const getFetchUserRequest = state => state.UserDetails.fetchUserRequest
const getUpdateAccountRequest = state => state.UserDetails.updateAccountRequest
const getUploadAvatarRequest = state => state.UserDetails.uploadAvatarRequest
const getRemoveAvatarRequest = state => state.UserDetails.removeAvatarRequest
const getPromoteTeacherRequest = state =>
  state.UserDetails.promoteTeacherRequest
const getDemoteTeacherRequest = state => state.UserDetails.demoteTeacherRequest
const getDeleteTeacherRequest = state => state.UserDetails.deleteTeacherRequest
const getDeclineTeacherRequest = state =>
  state.UserDetails.declineTeacherRequest
const getUnassignParentRequest = state =>
  state.UserDetails.unassignParentRequest
const getPromotePobblerRequest = state =>
  state.UserDetails.promotePobblerRequest
const getDemotePobblerRequest = state => state.UserDetails.demotePobblerRequest
const getPromoteAdminRequest = state => state.UserDetails.promoteAdminRequest
const getDemoteAdminRequest = state => state.UserDetails.demoteAdminRequest

const getUser = state => state.UserDetails.user

const getUserAvatar = createSelector(
  [getUser],
  user => (!user ? null : user.avatar)
)

const getUserSchools = createSelector([getUser], user => {
  const schoolsIds = []
  const schools = {}
  if (user.teacher_type) {
    const school = user.teacher_type.school
    const id = school.id + '_teacher'
    schools[id] = {
      role: 'teacher',
      school,
      school_leader: user.teacher_type.school_leader
    }
    schoolsIds.push(id)
  }
  if (user.pending_teacher_type) {
    const school = user.pending_teacher_type.school
    if (school) {
      const id = school.id + '_pending_teacher'
      schools[id] = { role: 'pending_teacher', school }
      schoolsIds.push(id)
    }
  }
  return {
    entries: schools,
    result: schoolsIds
  }
})

const getUserChildren = createSelector([getUser], user => {
  const result = []
  const entries = {}
  user.parent_types.forEach(child => {
    result.push(child.student_type.account.id)
    entries[child.student_type.account.id] = {
      ...child.student_type.account,
      permission: child.permission
    }
  })
  return {
    result,
    entries
  }
})

export {
  getFetchUserRequest,
  getUserAvatar,
  getUser,
  getUpdateAccountRequest,
  getUploadAvatarRequest,
  getRemoveAvatarRequest,
  getUserSchools,
  getPromoteTeacherRequest,
  getDemoteTeacherRequest,
  getDeleteTeacherRequest,
  getDeclineTeacherRequest,
  getUserChildren,
  getUnassignParentRequest,
  getPromotePobblerRequest,
  getDemotePobblerRequest,
  getPromoteAdminRequest,
  getDemoteAdminRequest
}
