import { apiClient } from 'pobble-components/dist/api'

const apiConnectToWonde = (id, wondeId) =>
  apiClient.post(
    `v3/schools/${id}/wonde_operations/connect?include=school.wonde`,
    { school_wonde_id: wondeId }
  )

const apiDisconnectFromWonde = id =>
  apiClient.post(
    `v3/schools/${id}/wonde_operations/disconnect?include=school.wonde`
  )

const apiPullFromWonde = id =>
  apiClient.post(`v3/schools/${id}/wonde_operations/pull?include=school.wonde`)

const apiSearchWondeSchools = params =>
  apiClient.get('/v3/wonde_operations/search', { params })

export {
  apiConnectToWonde,
  apiDisconnectFromWonde,
  apiPullFromWonde,
  apiSearchWondeSchools
}
