import React, { Component } from 'react'
import injectSheet from 'react-jss'

import styles from './teacher.styles.js'
import { Link } from 'react-router-dom'

import TeacherNotes from '../teacher-notes/teacher-notes'
import AssignTeacher from '../assign-teacher/assign-teacher.container'
import CircleStatus from 'pobble-components/dist/circle-status'

import Button from 'pobble-components/dist/button'
import { Cell } from 'pobble-components/dist/table'

import dateFns from 'date-fns'
import Tooltip from 'pobble-components/dist/tooltip'
import classNames from 'classnames'
class Teacher extends Component {
  render() {
    const {
      classes,
      id,
      signUp,
      name,
      email,
      school,
      suggestedSchool,
      notes,
      status,
      pobbleSchool,
      onApprove,
      onDisapprove,
      showLoading,
      individualSubscription,
      schoolSeatsBought,
      schoolSeatsAssigned,
      schoolInactivity,
      schoolLastLogin,
      schoolOrgs,
      requestedAt,
      schoolOrgsSubs
    } = this.props
    return (
      <tr
        className={classNames({
          [classes.loading]: showLoading,
          [classes.hasSchoolAlready]: !suggestedSchool,
          [classes.highlighted]:
            school && school.teacher_types && school.teacher_types.length < 1
        })}>
        <Cell>{signUp}</Cell>
        <Cell>{requestedAt}</Cell>
        <Cell>
          <Link to={`/users/${id}`} className={classes.plainLink}>
            {name}
          </Link>
        </Cell>
        <Cell>{email}</Cell>
        <Cell>
          {school && (
            <span>
              <Link to={`/schools/${school.id}`} className={classes.plainLink}>
                {school.title}
              </Link>
              {school.country ? <span> ({school.country})</span> : ''}
            </span>
          )}
          {suggestedSchool && <AssignTeacher id={id} />}
        </Cell>
        <Cell>{school && <span>{school.website || '-'}</span>}</Cell>
        <Cell center>
          {school && (
            <span>
              {(school &&
                school.teacher_types &&
                school.teacher_types.length > 0 &&
                school.teacher_types.length) || (
                <strong className={classes.inactive}>0</strong>
              )}
            </span>
          )}
        </Cell>
        <Cell>
          {individualSubscription
            ? individualSubscription.plan_marketing_name +
              '(' +
              individualSubscription.status +
              ')'
            : '-'}
        </Cell>
        <Cell center>
          {schoolSeatsBought
            ? schoolSeatsAssigned + '/' + schoolSeatsBought
            : '-'}
        </Cell>
        <Cell center>{(school && schoolOrgsSubs) || '-'}</Cell>
        <Cell center>
          <CircleStatus status={pobbleSchool} />
        </Cell>
        <Cell center>
          <span className={schoolInactivity ? classes.inactive : null}>
            {schoolLastLogin
              ? dateFns.format(schoolLastLogin, 'MM/DD/YY')
              : '-'}
          </span>
        </Cell>
        <Cell>
          <TeacherNotes id={id} notes={notes} />
        </Cell>
        <Cell className={classes.action}>
          {status !== 'declined' && (
            <Button
              success
              tall
              className={classes.button}
              action={() => onApprove(id)}
              disabled={status === 'approved' || !school}>
              {status === 'approved' ? 'Approved' : 'Yes'}
            </Button>
          )}
          {status !== 'approved' && (
            <Button
              danger
              tall
              className={classes.button}
              action={() => onDisapprove(id)}
              disabled={status === 'declined'}>
              {status === 'declined' ? 'Declined' : 'No'}
            </Button>
          )}
        </Cell>
      </tr>
    )
  }
}

export default injectSheet(styles)(Teacher)
