import { apiClient } from 'pobble-components/dist/api'

const getPresentations = params =>
  apiClient.get(`v3/admin/presentations/`, { params })

const apiApprovePresentation = id =>
  apiClient.put(`v3/admin/presentations/${id}/approve`)

const apiDeclinePresentation = id =>
  apiClient.put(`v3/admin/presentations/${id}/decline`)

export { getPresentations, apiApprovePresentation, apiDeclinePresentation }
