import { createSelector } from 'reselect'

const getStudentsIds = state => state.SchoolStudentsStore.students.result
const getStudentsData = state =>
  state.SchoolStudentsStore.students.entities.students

const getStudents = createSelector(
  [getStudentsIds, getStudentsData],
  (studentsIds, studentsData) => studentsIds.map(id => studentsData[id])
)

const getStudentsSchool = state => state.SchoolStudentsStore.school.title
const getStudentsSchoolId = state => state.SchoolStudentsStore.school.id

const getStudent = (state, id) => getStudentsData(state)[id]

const isStudentsFetched = state => state.SchoolStudentsStore.studentsFetched

const areStudentsFetching = state => state.SchoolStudentsStore.loading

export {
  getStudents,
  getStudent,
  areStudentsFetching,
  isStudentsFetched,
  getStudentsSchool,
  getStudentsSchoolId
}
