import React from 'react'
import injectSheet from 'react-jss'
import styles from './approve-teachers.styles.js'

import Paper from 'pobble-components/dist/paper'

import Container from '../../shared/container/container.jsx'
import Main from '../../shared/main/main.jsx'

import TeachersFilters from './teachers-filter/teachers-filter.container.js'
import TeachersList from './teachers-list/teachers-list.container.js'
import SectionTitle from '../../shared/section-title/section-title.jsx'

const ApproveTeachers = ({ classes }) => {
  return (
    <Container>
      <Main>
        <Paper className={classes.container}>
          <SectionTitle title="Approve teachers" />
          <TeachersFilters />
          <TeachersList />
        </Paper>
      </Main>
    </Container>
  )
}

export default injectSheet(styles)(ApproveTeachers)
