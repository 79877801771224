import React from 'react'
import styles from './campaign.styles'
import injectSheet from 'react-jss'
import { Cell } from 'pobble-components/dist/table'
import CircleStatus from 'pobble-components/dist/circle-status'
import { Link } from 'react-router-dom'
import { openConfirmationDialog } from 'pobble-components/dist/modal/confirmation-dialog'
import Button from 'pobble-components/dist/button'

const Campaign = ({ classes, id, title, active, date, onDelete }) => (
  <tr>
    <Cell>
      <Link className={classes.link} to={`/campaigns/${id}`}>
        {title}
      </Link>
    </Cell>
    <Cell>{date}</Cell>
    <Cell>
      <CircleStatus status={active} />
    </Cell>
    <Cell className={classes.action}>
      <Button
        tall
        danger
        action={() =>
          openConfirmationDialog({
            bodyText: `Are you sure you want to delete this campaign?`,
            confirmText: 'Delete',
            action: () => onDelete(id)
          })
        }>
        Remove
      </Button>
    </Cell>
  </tr>
)

export default injectSheet(styles)(Campaign)
