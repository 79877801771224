import { createReducer } from 'pobble-components/dist/redux-helpers'
import { normalize, schema } from 'normalizr'

const normalizeTeachers = data => {
  const teacher = new schema.Entity(
    'teachers',
    {},
    { idAttribute: teacher => teacher.account.id }
  )
  return normalize(data, [teacher])
}

const normalizePendingTeachers = data => {
  const pending_teacher = new schema.Entity(
    'pending_teachers',
    {},
    { idAttribute: pending_teacher => pending_teacher.account.id }
  )
  return normalize(data, [pending_teacher])
}

let defaultState = {
  loading: false,
  teacherBeingAdded: false,
  teachersFetched: false,
  teachersUpdating: {},
  school: {
    id: null,
    title: null
  },
  teachers: {
    result: [],
    entities: { teachers: {} }
  },
  pending_teachers: {
    result: [],
    entities: { pending_teachers: {} }
  }
}

const handleTeacherRequest = (state, { params }) => {
  return {
    ...state,
    teachersUpdating: {
      ...state.teachersUpdating,
      [params.teacherId]: true
    }
  }
}

const handlePendingTeacherRequest = (state, { params }) => {
  return {
    ...state,
    pending_teachers: {
      ...state.pending_teachers,
      result: state.pending_teachers.result.filter(
        id => id !== params.teacherId
      )
    }
  }
}

const handleTeacherRequestError = (state, { params }) => ({
  ...state,
  teachersUpdating: {
    ...state.teachersUpdating,
    [params.teacherId]: false
  }
})

const SchoolTeachersStore = createReducer(defaultState, {
  'SCHOOL_TEACHERS:FETCHING_REQUEST': state => ({
    ...state,
    loading: true
  }),
  'SCHOOL_TEACHERS:FETCHING_REQUEST_SUCCESS': (state, { response }) => ({
    ...state,
    loading: false,
    school: {
      id: response.data.school.id,
      title: response.data.school.title
    },
    teachers: normalizeTeachers(response.data.school.teacher_types),
    pending_teachers: normalizePendingTeachers(
      response.data.school.pending_teacher_types
    ),
    teachersFetched: true
  }),
  'SCHOOL_TEACHERS:ADD_REQUEST': (state, { params }) => ({
    ...state,
    teacherBeingAdded: true
  }),
  'SCHOOL_TEACHERS:ADD_REQUEST_SUCCESS': (state, { response, params }) => ({
    ...state,
    teacherBeingAdded: false,
    teachers: {
      ...state.teachers,
      result: [params.teacher.id, ...state.teachers.result],
      entities: {
        teachers: {
          ...state.teachers.entities.teachers,
          [params.teacher.id]: {
            school_leader: response.data.account.teacher_type.school_leader,
            account: {
              id: params.teacher.id,
              full_name: response.data.account.full_name,
              email_identity: {
                email: params.teacher.email
              }
            }
          }
        }
      }
    }
  }),
  'SCHOOL_TEACHERS:ADD_REQUEST_ERROR': (state, { params }) => ({
    ...state,
    teacherBeingAdded: false
  }),
  'SCHOOL_TEACHERS:PROMOTE_REQUEST_SUCCESS': (state, { response, params }) => {
    return {
      ...state,
      teachersUpdating: {
        ...state.teachersUpdating,
        [params.teacherId]: false
      },
      teachers: {
        ...state.teachers,
        entities: {
          teachers: {
            ...state.teachers.entities.teachers,
            [params.teacherId]: {
              ...state.teachers.entities.teachers[params.teacherId],
              school_leader: true
            }
          }
        }
      }
    }
  },
  'SCHOOL_TEACHERS:DEMOTE_REQUEST_SUCCESS': (state, { response, params }) => {
    return {
      ...state,
      teachersUpdating: {
        ...state.teachersUpdating,
        [params.teacherId]: false
      },
      teachers: {
        ...state.teachers,
        entities: {
          teachers: {
            ...state.teachers.entities.teachers,
            [params.teacherId]: {
              ...state.teachers.entities.teachers[params.teacherId],
              school_leader: false
            }
          }
        }
      }
    }
  },
  'SCHOOL_TEACHERS:DELETE_REQUEST_SUCCESS': (state, { response, params }) => {
    return {
      ...state,
      teachersUpdating: {
        ...state.teachersUpdating,
        [params.teacherId]: false
      },
      teachers: {
        ...state.teachers,
        result: state.teachers.result.filter(id => id !== params.teacherId)
      }
    }
  },
  'SCHOOL_TEACHERS:APPROVE_REQUEST_SUCCESS': (state, { response, params }) => ({
    ...state,
    pending_teachers: {
      ...state.pending_teachers,
      result: state.pending_teachers.result.filter(
        id => id !== params.teacher.id
      )
    },
    teachers: {
      ...state.teachers,
      result: [params.teacher.id, ...state.teachers.result],
      entities: {
        teachers: {
          ...state.teachers.entities.teachers,
          [params.teacher.id]: {
            account: {
              id: params.teacher.id,
              full_name: params.teacher.name,
              email_identity: {
                email: params.teacher.email
              }
            }
          }
        }
      }
    }
  }),
  'SCHOOL_TEACHERS:DISAPPROVE_REQUEST_SUCCESS': handlePendingTeacherRequest,
  'SCHOOL_TEACHERS:PROMOTE_REQUEST': handleTeacherRequest,
  'SCHOOL_TEACHERS:DEMOTE_REQUEST': handleTeacherRequest,
  'SCHOOL_TEACHERS:DELETE_REQUEST': handleTeacherRequest,
  'SCHOOL_TEACHERS:PROMOTE_REQUEST_ERROR': handleTeacherRequestError,
  'SCHOOL_TEACHERS:DEMOTE_REQUEST_ERROR': handleTeacherRequestError,
  'SCHOOL_TEACHERS:DELETE_REQUEST_ERROR': handleTeacherRequestError
})

export default SchoolTeachersStore
