import React from 'react'
import Button from 'pobble-components/dist/button'
import FormFile from 'pobble-components/dist/form-elements/form-file'

const AddCover = ({ classes, onChange }) => (
  <FormFile accept=".jpg,.png" onChange={onChange}>
    <Button span tall>
      Upload cover
    </Button>
  </FormFile>
)

export default AddCover
