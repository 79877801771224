export default {
  container: {
    width: '33.333%',
    padding: '0 10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },
  sizeM: {
    width: '50%'
  },
  sizeL: {
    width: '100%'
  }
}
