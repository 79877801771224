import { getSchool, apiUpdateSchool } from '../../common/api/school'

import {
  apiConnectToWonde,
  apiDisconnectFromWonde,
  apiPullFromWonde,
  apiSearchWondeSchools
} from '../../common/api/wonde'

import {
  apiPullFromGroupCall,
  apiDisconnectFromGroupCall,
  apiConnectToGroupCall
} from '../../common/api/groupcall'

const fetchSchoolData = id => ({
  requestPrefix: 'SCHOOL_DATA:FETCH',
  request: () =>
    getSchool(id, {
      include: 'school.names,school.wonde,school.groupcall'
    })
})

const connectToWonde = (schoolId, wondeId) => ({
  requestPrefix: 'SCHOOL_DATA:CONNECT',
  request: () => apiConnectToWonde(schoolId, wondeId)
})

const disconnectFromWonde = schoolId => ({
  requestPrefix: 'SCHOOL_DATA:DISCONNECT',
  request: () => apiDisconnectFromWonde(schoolId)
})

const pullFromWonde = schoolId => ({
  requestPrefix: 'SCHOOL_DATA:PULL',
  request: () => apiPullFromWonde(schoolId)
})

const updateParentsPulling = (schoolId, data) => ({
  requestPrefix: 'SCHOOL_DATA:PARENTS',
  params: data,
  request: () => apiUpdateSchool(schoolId, data)
})

const searchWondeSchoolsToRequest = searchTerm => ({
  requestPrefix: 'SCHOOL_DATA:FETCHING_SCHOOLS',
  params: { searchTerm },
  request: () =>
    apiSearchWondeSchools({
      include: 'school.names,school.urn',
      term: searchTerm,
      limit: 10
    })
})

const pullFromGroupCall = schoolId => ({
  requestPrefix: 'SCHOOL_DATA:PULL_GC',
  request: () => apiPullFromGroupCall(schoolId)
})

const connectToGroupCall = schoolId => ({
  requestPrefix: 'SCHOOL_DATA:CONNECT_GC',
  request: () => apiConnectToGroupCall(schoolId)
})

const disconnectFromGroupCall = schoolId => ({
  requestPrefix: 'SCHOOL_DATA:DISCONNECT_GC',
  request: () => apiDisconnectFromGroupCall(schoolId)
})

export {
  fetchSchoolData,
  connectToWonde,
  disconnectFromWonde,
  pullFromWonde,
  updateParentsPulling,
  searchWondeSchoolsToRequest,
  pullFromGroupCall,
  connectToGroupCall,
  disconnectFromGroupCall
}
