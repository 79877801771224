import React, { Component } from 'react'
import injectSheet from 'react-jss'
import dateFns from 'date-fns'
import { Link } from 'react-router-dom'

import styles from './comment-entry.styles.js'

import CommentBody from '../comment-body/comment-body'

import Button from 'pobble-components/dist/button'
import { Cell } from 'pobble-components/dist/table'
import StudentIcon from 'react-icons/lib/fa/graduation-cap'

class CommentEntry extends Component {
  render() {
    const {
      classes,
      date,
      body,
      userType,
      userName,
      status,
      work,
      onApprove,
      onDisapprove,
      id,
      showLoading,
      authorId
    } = this.props
    return (
      <tr className={showLoading ? classes.loading : null}>
        <Cell>{dateFns.format(date, 'MM/DD/YYYY HH:ss')}</Cell>
        <Cell className={classes.body}>
          <CommentBody id={id} body={body} />
        </Cell>
        <Cell>
          <Link className={classes.link} to={`/users/${authorId}`}>
            {userName}
            {userType === 'student' && (
              <StudentIcon className={classes.studentIcon} />
            )}
          </Link>
        </Cell>
        <Cell>
          <Link className={classes.link} to={`/works/${work.id}`}>
            {work.id}
          </Link>
        </Cell>
        <Cell>{status}</Cell>
        <Cell className={classes.action}>
          <Button
            tall
            secondary
            className={classes.button}
            href={`https://app.pobble.com/works/${work.id}`}>
            Work
          </Button>
          <Button
            success
            tall
            className={classes.button}
            action={() => onApprove(id)}
            disabled={status === 'approved'}>
            Yes
          </Button>
          <Button
            danger
            tall
            className={classes.button}
            action={() => onDisapprove(id)}
            disabled={status === 'declined'}>
            No
          </Button>
        </Cell>
      </tr>
    )
  }
}

export default injectSheet(styles)(CommentEntry)
