import { apiClient } from 'pobble-components/dist/api'

const getWork = (id, params) => apiClient.get(`v3/works/${id}`, { params })
const getWorks = params => apiClient.get(`v3/admin/works/`, { params })

const apiDeleteWork = id => apiClient.delete(`/v3/works/${id}`)

const apiUpdateWork = (id, data) => apiClient.put(`v3/works/${id}`, data)
const apiPublishWork = id =>
  apiClient.put(`v3/works/${id}?include=work.published,work.status`, {
    state: 'published'
  })
const apiUnpublishWork = id =>
  apiClient.put(`v3/works/${id}?include=work.published,work.status`, {
    state: 'evidence_bank'
  })
const apiFlagWork = (id, data) =>
  apiClient.put(
    `v3/works/${id}/flag?include=work.flag.data,work.flag.account,work.flag.account.names`,
    data
  )
const apiUnflagWork = id =>
  apiClient.put(
    `v3/works/${id}/unflag?include=work.flag.data,work.flag.account`
  )
const apiDeleteMedia = mediaId => apiClient.delete(`/v3/media/${mediaId}`)

const apiOrderMedia = (workId, data) =>
  apiClient.put(`v3/works/${workId}/reorder_media`, { media_ids: data })

export {
  getWork,
  getWorks,
  apiDeleteWork,
  apiUpdateWork,
  apiDeleteMedia,
  apiPublishWork,
  apiUnpublishWork,
  apiFlagWork,
  apiUnflagWork,
  apiOrderMedia
}
