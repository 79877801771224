import React from 'react'
import styles from './parents-table.styles'
import injectSheet from 'react-jss'
import { Table, HeadCell } from 'pobble-components/dist/table'
import ParentEntry from './parent-entry/parent-entry.container'

const ParentsTable = ({ classes, parents, pupilId }) => (
  <Table>
    <thead>
      <tr>
        <HeadCell className={classes.left}>Email</HeadCell>
        <HeadCell>Permission to use Pobble</HeadCell>
        <HeadCell />
      </tr>
    </thead>
    <tbody>
      {parents.map(parent => (
        <ParentEntry pupilId={pupilId} key={parent} parent={parent} />
      ))}
    </tbody>
  </Table>
)

export default injectSheet(styles)(ParentsTable)
