import React from 'react'
import styles from './user-details-mod-subs.styles'
import injectSheet from 'react-jss'
import Section from '../../../../shared/details/section/section'
import FormRow from '../../../../shared/details/form-row/form-row'
import FormCell from '../../../../shared/details/form-cell/form-cell'
import FormLabel from '../../../../shared/details/form-label/form-label'
import CellText from '../../../../shared/details/cell-text/cell-text'
import FormInput from 'pobble-components/dist/form-elements/form-input'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Button from 'pobble-components/dist/button'
import classNames from 'classnames'

const requiredMsg = 'This field should not be empty'

const UserDetailsModSubs = ({
  classes,
  pending,
  plan,
  endDate,
  subsPlan,
  subsEnd,
  subsStatus,
  subsAddon,
  onSubmit
}) => (
  <Formik
    onSubmit={onSubmit}
    initialValues={{
      endDate: subsEnd || ''
    }}
    validationSchema={Yup.object().shape({
      endDate: Yup.date('').required(requiredMsg)
    })}>
    {formikProps => {
      const {
        values,
        handleBlur,
        handleChange,
        handleSubmit,
        isValid,
        errors,
        touched
      } = formikProps
      return (
        <form
          onSubmit={handleSubmit}
          className={classNames(classes.form, { [classes.pending]: pending })}>
          <Section
            title="Moderation add-on"
            renderHeader={() => (
              <Button
                className={classes.submitButton}
                disabled={!isValid}
                type="submit"
                tall>
                Edit add-on
              </Button>
            )}>
            <FormRow>
              <FormCell>
                <FormLabel>End date</FormLabel>
                <CellText>{(subsAddon && subsEnd) || '-'}</CellText>
              </FormCell>
            </FormRow>
            <FormRow>
              <FormCell>
                <FormLabel>Assign a Moderation add-on until:</FormLabel>
                <FormInput
                  name="endDate"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.endDate}
                  placeholder={'Ex: 2022-08-30'}
                  validationError={touched.endDate && errors.endDate}
                />
              </FormCell>
            </FormRow>
          </Section>
        </form>
      )
    }}
  </Formik>
)

export default injectSheet(styles)(UserDetailsModSubs)
