import { connect } from 'react-redux'
import SchoolsFilter from './schools-filter'
import { getFilterSearch } from '../../../../stores/schools-filter/schools-filter.selectors'
import { searchSchools } from '../../../../stores/schools-filter/schools-filter.actions.js'
import { fetchSchools } from '../../../../stores/schools/schools.actions.js'

const mapStateToProps = state => ({
  searchValue: getFilterSearch(state)
})

const mapDispatchToProps = dispatch => ({
  onSearchChange: e =>
    e.target.value === ''
      ? dispatch(fetchSchools())
      : dispatch(searchSchools(e.target.value))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SchoolsFilter)
