import {
  getWork,
  apiUpdateWork,
  apiDeleteMedia,
  apiPublishWork,
  apiUnpublishWork,
  apiFlagWork,
  apiUnflagWork,
  apiOrderMedia
} from '../../common/api/work'

const fetchWork = id => ({
  requestPrefix: 'WORK:FETCHING',
  request: () =>
    getWork(id, {
      include:
        'work.names,work.details,work.timestamps,work.media,work.medium.image,work.medium.details,work.medium.uploaded_by,work.medium.uploaded_by.names,work.student_type,work.student_type.account,work.student_type.account.names,work.flag,work.flag.data,work.flag.account.names,work.status,work.published_at,work.lesson,work.lesson.timestamps,work.lesson.status'
    })
})

const updateWork = (id, data) => ({
  requestPrefix: 'WORK:UPDATE',
  request: () => apiUpdateWork(id, data)
})

const publishWork = id => ({
  requestPrefix: 'WORK:PUBLISH',
  request: () => apiPublishWork(id)
})

const unpublishWork = id => ({
  requestPrefix: 'WORK:UNPUBLISH',
  request: () => apiUnpublishWork(id)
})

const flagWork = id => ({
  requestPrefix: 'WORK:FLAG',
  request: () => apiFlagWork(id, { reason: 'Flagged via admin interface' })
})

const unflagWork = id => ({
  requestPrefix: 'WORK:UNFLAG',
  request: () => apiUnflagWork(id)
})

const deleteMedia = mediaId => ({
  requestPrefix: 'WORK:DELETE_MEDIA',
  params: { mediaId },
  request: () => apiDeleteMedia(mediaId)
})

const orderMedia = (orderedIds, workId) => ({
  requestPrefix: 'WORK:ORDER_MEDIA',
  params: { orderedIds, workId },
  request: () => apiOrderMedia(orderedIds, workId)
})

export {
  fetchWork,
  updateWork,
  deleteMedia,
  publishWork,
  unpublishWork,
  flagWork,
  unflagWork,
  orderMedia
}
