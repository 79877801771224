import React, { Component, Fragment } from 'react'
import injectSheet from 'react-jss'
import classNames from 'classnames'

import styles from './review-schools-list.styles.js'

import School from '../school/school'

import { Table, HeadCell } from 'pobble-components/dist/table'
import Spinner from 'pobble-components/dist/spinner'

import LoadMoreButton from '../load-more-button/load-more-button.container'

class SchoolsList extends Component {
  componentDidMount() {
    this.props.onMount()
  }
  componentDidUpdate(prevProps) {
    this.props.onUpdate(prevProps)
  }
  render() {
    const {
      classes,
      showLoading,
      schools,
      noSchools,
      noSchoolsMatchingFilters,
      schoolGettingAdded,
      hideLoadMore
    } = this.props
    return showLoading ? (
      <Spinner className={classes.spinner} />
    ) : noSchools ? (
      <span>No schools</span>
    ) : (
      <Fragment>
        {!noSchoolsMatchingFilters && (
          <Table
            className={classNames({
              [classes.pendingAddition]: schoolGettingAdded
            })}>
            <thead>
              <tr>
                <HeadCell className={classes.cell}>ID</HeadCell>
                <HeadCell className={classes.cell}>Title</HeadCell>
                <HeadCell className={classes.cell}>URN</HeadCell>
                <HeadCell className={classes.cell}>City</HeadCell>
                <HeadCell className={classes.cell}>Country</HeadCell>
                <HeadCell className={classes.cell}>Pobbler</HeadCell>
                <HeadCell className={classes.cellCenter}>
                  Pobble School
                </HeadCell>
                <HeadCell>End date</HeadCell>
                <HeadCell>Teachers</HeadCell>
                <HeadCell>Seats</HeadCell>
                <HeadCell>Last login</HeadCell>
                <HeadCell />
              </tr>
            </thead>
            <tbody>
              {schools.map(school => (
                <School
                  key={school.id}
                  id={school.id}
                  title={school.title}
                  urn={school.urn}
                  city={school.city}
                  country={school.country}
                  pobbler={school.pobbler}
                  pobbleSchool={school.pobble_school}
                  wonde={school.wonde}
                  paidUntil={school.paid_until}
                  nbOfTeachers={
                    (school.teacher_types && school.teacher_types.length) ||
                    school.number_of_teachers
                  }
                  seats={
                    school.group_subs_seats_bought
                      ? school.group_subs_seats_assigned +
                        '/' +
                        school.group_subs_seats_bought
                      : '-'
                  }
                  lastLogin={school.last_login}
                />
              ))}
            </tbody>
          </Table>
        )}
        {/*noSchoolsMatchingFilters && <span>No schools found.</span>*/}
        {!hideLoadMore && <LoadMoreButton />}
      </Fragment>
    )
  }
}

export default injectSheet(styles)(SchoolsList)
