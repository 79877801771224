import React from 'react'
import injectSheet from 'react-jss'
import styles from './work-media.styles.js'
import Button from 'pobble-components/dist/button'
import { openConfirmationDialog } from 'pobble-components/dist/modal/confirmation-dialog'

const WorkMedia = ({ classes, onDelete, imageUrl, canBeDeleted }) => (
  <div className={classes.container}>
    <a
      href={imageUrl}
      target="_blank"
      rel="noopener noreferrer"
      className={classes.image}>
      <img src={imageUrl} width="150" alt="Media" />
    </a>
    <Button
      disabled={!canBeDeleted}
      secondary
      action={() =>
        openConfirmationDialog({
          bodyText: `Are you sure you want to delete this media?`,
          confirmText: 'Delete',
          action: onDelete
        })
      }>
      Delete
    </Button>
  </div>
)

export default injectSheet(styles)(WorkMedia)
