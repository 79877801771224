import React from 'react'
import styles from './campaign-creation.styles'
import injectSheet from 'react-jss'
import Container from '../../shared/container/container'
import Main from '../../shared/main/main'
import Paper from 'pobble-components/dist/paper'
import CampaignCreationForm from './campaign-creation-form/campaign-creation-form.container'

const CampaignCreation = ({ classes }) => (
  <Container>
    <Main>
      <Paper className={classes.paper}>
        <CampaignCreationForm />
      </Paper>
    </Main>
  </Container>
)

export default injectSheet(styles)(CampaignCreation)
