const setStatusFilter = status => ({
  type: 'TEACHER_FILTER:SET_STATUS',
  data: { status }
})

const setSearchValue = searchValue => ({
  type: 'TEACHER_FILTER:SET_SEARCH_VALUE',
  searchValue
})

export { setStatusFilter, setSearchValue }
