export default {
  form: {
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      left: '0',
      top: '0',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      pointerEvents: 'none',
      opacity: '0',
      transition: 'opacity 0.3s'
    }
  },
  pending: {
    '&:after': {
      pointerEvents: 'auto',
      opacity: '1'
    }
  }
}
