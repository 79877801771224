export default {
  holder: {
    display: 'flex',
    margin: '20px 0 0',
    flexWrap: 'wrap',
    '& label': {
      padding: '12px',
      display: 'flex',
      alignItems: 'center',
      marginRight: '20px',
      '& span:first-child': {
        marginRight: '5px'
      }
    }
  }
}
