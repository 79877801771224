import React from 'react'
import styles from './user-details-avatar-form.styles'
import injectSheet from 'react-jss'
import Section from '../../../../shared/details/section/section'
import FormRow from '../../../../shared/details/form-row/form-row'
import FormCell from '../../../../shared/details/form-cell/form-cell'
import UploadAvatar from './upload-avatar/upload-avatar.container'
import RemoveAvatar from './remove-vatar/remove-avatar.container'

const UserDetailsAvatarForm = ({ classes, pending }) => (
  <Section title="Avatar" pending={pending}>
    <FormRow noBorder>
      <FormCell>
        <UploadAvatar />
      </FormCell>
      <FormCell>
        <RemoveAvatar />
      </FormCell>
    </FormRow>
  </Section>
)

export default injectSheet(styles)(UserDetailsAvatarForm)
