import { createSelector } from 'reselect'

const getCommentsIds = state => state.CommentsStore.comments.result
const getCommentsData = state => state.CommentsStore.comments.entities.comments
const isCommentBeingUpdated = (state, id) =>
  state.CommentsStore.commentsUpdating[id] || false

const getAllComments = createSelector(
  [getCommentsIds, getCommentsData],
  (commentsIds, commentsData) => commentsIds.map(id => commentsData[id])
)

const getFetchCommentsRequest = state => state.CommentsStore.loading

const getFetchCommentsRequestInitiator = createSelector(
  [getFetchCommentsRequest],
  req => (!req.params ? null : req.params.initiator)
)

const getLimit = state => state.CommentsStore.currentLimit

const getServerParams = createSelector([getLimit], currentLimit => ({
  limit: currentLimit
}))

const lessCommentsThanLimit = createSelector(
  [getCommentsIds, getLimit],
  (comments, currentLimit) =>
    !comments ? true : comments.length < currentLimit
)

const getPendingComments = createSelector([getAllComments], comments =>
  comments.filter(comment => comment.status === 'pending')
)

const getPendingCommentsNumber = createSelector(
  [getPendingComments],
  comments => comments.length
)

const areCommentsFetching = state => state.CommentsStore.loading

export {
  getAllComments,
  getPendingComments,
  getPendingCommentsNumber,
  areCommentsFetching,
  getCommentsIds,
  isCommentBeingUpdated,
  getFetchCommentsRequest,
  getFetchCommentsRequestInitiator,
  lessCommentsThanLimit,
  getServerParams
}
