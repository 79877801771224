import { createSelector } from 'reselect'

const getNormalizedUsers = state => state.UsersStore.users
const getFilter = state => state.UsersStore.filter

const getServerFilterParams = createSelector([getFilter], filter => ({
  limit: filter.currentLimit,
  first_name: filter.firstName || null,
  last_name: filter.lastName || null,
  display_name: filter.displayName || null,
  email: filter.email || null
}))

const getUsersResult = createSelector(
  [getNormalizedUsers],
  users => (!users ? null : users.result)
)

const getUsers = createSelector(
  [getNormalizedUsers],
  users => (!users ? null : users.entities.users)
)

const getUsersList = createSelector(
  [getUsersResult, getUsers],
  (result, users) => (!result ? null : result.map(id => users[id]))
)

const getFetchUsersRequest = state => state.UsersStore.fetchUsersRequest

const getFetchUsersRequestInitiator = createSelector(
  [getFetchUsersRequest],
  req => (!req.params ? null : req.params.initiator)
)

const getUsersSchools = createSelector([getUsersList], usersList => {
  const usersMap = {}
  usersList.forEach(user => {
    const schools = []
    if (user.pending_teacher_type && user.pending_teacher_type.school)
      schools.push(user.pending_teacher_type.school)
    if (user.teacher_type && user.teacher_type.school)
      schools.push(user.teacher_type.school)
    usersMap[user.id] = schools
  })
  return usersMap
})

const getUser = (state, id) => getUsers(state)[id]
const getUserSchools = (state, id) => getUsersSchools(state)[id]

const lessUsersThanLimit = createSelector(
  [getUsersResult, getFilter],
  (users, filter) => (!users ? true : users.length < filter.currentLimit)
)

export {
  getUsersList,
  getUser,
  getFetchUsersRequest,
  getFilter,
  getServerFilterParams,
  getFetchUsersRequestInitiator,
  lessUsersThanLimit,
  getUserSchools
}
