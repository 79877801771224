import { createSelector } from 'reselect'

const getNormalizedCampaigns = state => state.CampaignsStore.campaigns

const getCampaignsResult = createSelector(
  [getNormalizedCampaigns],
  campaigns => (!campaigns ? null : campaigns.result)
)

const getCampaigns = createSelector(
  [getNormalizedCampaigns],
  campaigns => (!campaigns ? null : campaigns.entities.campaigns)
)

const getCampaignsList = createSelector(
  [getCampaignsResult, getCampaigns],
  (result, campaigns) => (!result ? null : result.map(id => campaigns[id]))
)

const getFetchCampaignsRequest = state =>
  state.CampaignsStore.fetchCampaignsRequest

const getCampaign = (state, id) => getCampaigns(state)[id]

const getEditedCampaign = state => state.CampaignsStore.campaign || {}

const getFetchCampaignRequest = state =>
  state.CampaignsStore.fetchCampaignRequest

export {
  getCampaignsList,
  getCampaign,
  getFetchCampaignsRequest,
  getEditedCampaign,
  getFetchCampaignRequest
}
