import { apiClient } from 'pobble-components/dist/api'

const getTeachers = params => apiClient.get(`v3/admin/teachers`, { params })

const apiPromoteTeacher = id =>
  apiClient.put(`v3/teachers/${id}/promote_to_school_leader`)
const apiDemoteTeacher = id =>
  apiClient.put(`v3/teachers/${id}/demote_from_school_leader`)

const apiAddTeacher = (id, email) =>
  apiClient.post(
    `v3/schools/${id}/teachers?include=account.names,account.teacher_type.status`,
    { email }
  )
const apiAddAsPendingTeacher = (schoolId, id) =>
  apiClient.put(
    `/v3/pending_teachers/${id}/assign_to_school?include=account.names,account.teacher_type.status,account.pending_teacher_type.school,account.pending_teacher_type.school.title`,
    { school_id: schoolId }
  )
const apiApprovePendingTeacher = id =>
  apiClient.put(`v3/pending_teachers/${id}/approve`)
const apiDeclinePendingTeacher = id =>
  apiClient.put(`v3/pending_teachers/${id}/decline`)

const apiDeleteTeacher = (schoolId, teacherId) =>
  apiClient.delete(`v3/schools/${schoolId}/teachers/${teacherId}`)

const apiResendInvitation = id =>
  apiClient.post(`v3/teachers/${id}/resend_invitation_email`)

export {
  getTeachers,
  apiPromoteTeacher,
  apiDemoteTeacher,
  apiAddTeacher,
  apiApprovePendingTeacher,
  apiDeclinePendingTeacher,
  apiDeleteTeacher,
  apiAddAsPendingTeacher,
  apiResendInvitation
}
