import { connect } from 'react-redux'
import SchoolTable from './school-table'
import { getUserSchools } from '../../../../../../stores/user-details/user-details.selectors'

const mapStateToProps = state => {
  const schools = getUserSchools(state)
  return { schoolsIds: schools.result }
}

export default connect(
  mapStateToProps,
  null
)(SchoolTable)
