import { createSelector } from 'reselect'
import { getStudents } from '../school-students/school-students.selectors.js'

const getFilterSearch = state => state.SchoolStudentsFilter.search

const getFilteredStudents = createSelector(
  [getFilterSearch, getStudents],
  (searchValue, students) =>
    !searchValue
      ? students
      : students.filter(student => {
          let searchTerm = searchValue.toLowerCase()
          return (
            student.account.full_name &&
            student.account.full_name.toLowerCase().indexOf(searchTerm) !== -1
          )
        })
)

export { getFilteredStudents, getFilterSearch }
