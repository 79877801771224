import { createSelector } from 'reselect'

const getTeachersIds = state => state.SchoolTeachersStore.teachers.result
const getTeachersData = state =>
  state.SchoolTeachersStore.teachers.entities.teachers

const getTeachers = createSelector(
  [getTeachersIds, getTeachersData],
  (teachersIds, teachersData) => teachersIds.map(id => teachersData[id])
)

const getPendingTeachersIds = state =>
  state.SchoolTeachersStore.pending_teachers.result
const getPendingTeachersData = state =>
  state.SchoolTeachersStore.pending_teachers.entities.pending_teachers

const getPendingTeachers = createSelector(
  [getPendingTeachersIds, getPendingTeachersData],
  (pendingTeachersIds, pendingTeachersData) =>
    pendingTeachersIds.map(id => pendingTeachersData[id])
)

const getPendingTeacher = (state, id) => getPendingTeachersData(state)[id]

const getTeachersSchool = state => state.SchoolTeachersStore.school.title

const isTeachersFetched = state => state.SchoolTeachersStore.teachersFetched

const areTeachersFetching = state => state.SchoolTeachersStore.loading
const isTeacherBeingAdded = state =>
  state.SchoolTeachersStore.teacherBeingAdded || false
const isTeacherBeingUpdated = (state, id) =>
  state.SchoolTeachersStore.teachersUpdating[id] || false

export {
  getTeachers,
  getPendingTeachers,
  getPendingTeacher,
  areTeachersFetching,
  isTeachersFetched,
  isTeacherBeingAdded,
  isTeacherBeingUpdated,
  getTeachersSchool
}
