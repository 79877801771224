import React, { Component } from 'react'
import injectSheet from 'react-jss'
import { Link } from 'react-router-dom'
import styles from './teacher.styles.js'

import { Cell } from 'pobble-components/dist/table'
import Button from 'pobble-components/dist/button'
import FormCheckbox from 'pobble-components/dist/form-elements/form-checkbox'
import { openConfirmationDialog } from 'pobble-components/dist/modal/confirmation-dialog'
import SignInIcon from 'react-icons/lib/fa/sign-in'
import dateFns from 'date-fns'
import CircleStatus from 'pobble-components/dist/circle-status'

class Teacher extends Component {
  render() {
    const {
      classes,
      id,
      name,
      email,
      schoolLeader,
      nbOfLogins,
      lastLogin,
      longInactivity,
      createdAt,
      seatAssigned,
      seatsOwned,
      onSchoolLeaderChange,
      onDelete,
      onResendInvitation,
      showLoading
    } = this.props
    return (
      <tr className={showLoading ? classes.loading : null}>
        <Cell>
          <Link to={`/users/${id}`} className={classes.link}>
            {name || 'Pending invitation'}
            &nbsp;
            <SignInIcon />
          </Link>
        </Cell>
        <Cell>{email}</Cell>
        <Cell className={classes.center}>
          <FormCheckbox
            checked={schoolLeader}
            onChange={e => onSchoolLeaderChange(e.target.checked, id)}
          />
        </Cell>
        <Cell>{dateFns.format(createdAt, 'MM/DD/YYYY HH:ss')}</Cell>
        <Cell className={longInactivity ? classes.longInactivity : null}>
          {(lastLogin && dateFns.format(lastLogin, 'MM/DD/YYYY HH:ss')) || '-'}
        </Cell>
        <Cell className={classes.center}>{nbOfLogins}</Cell>
        <Cell className={classes.center}>{seatsOwned}</Cell>
        <Cell className={classes.center}>
          {seatAssigned ? <CircleStatus status={true} /> : '-'}
        </Cell>
        <Cell className={classes.action}>
          <Button
            tall
            action={() =>
              openConfirmationDialog({
                bodyText: `Are you sure you want to resend this teacher an invitation?`,
                confirmText: 'Resend invitation',
                action: () => onResendInvitation(id)
              })
            }>
            Resend invitation
          </Button>
          <Button
            tall
            danger
            action={() =>
              openConfirmationDialog({
                bodyText: `Are you sure you want to remove this teacher from the school?`,
                confirmText: 'Remove',
                action: () => onDelete(id)
              })
            }>
            Remove
          </Button>
        </Cell>
      </tr>
    )
  }
}

export default injectSheet(styles)(Teacher)
