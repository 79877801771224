import React from 'react'
import styles from './children-table.styles'
import injectSheet from 'react-jss'
import { Table, HeadCell } from 'pobble-components/dist/table'
import ChildEntry from './child-entry/child-entry.container'

const ChildrenTable = ({ classes, userChildren }) => (
  <Table className={classes.table}>
    <thead>
      <tr>
        <HeadCell>Name</HeadCell>
        <HeadCell className={classes.permissionCell}>Permission</HeadCell>
      </tr>
    </thead>
    <tbody>
      {userChildren.map(id => (
        <ChildEntry key={id} id={id} />
      ))}
    </tbody>
  </Table>
)

export default injectSheet(styles)(ChildrenTable)
