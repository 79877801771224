import React from 'react'
import injectSheet from 'react-jss'

import styles from './form-fields.styles'

const FormFields = ({ classes, children }) => (
  <div className={classes.fields}>{children}</div>
)

export default injectSheet(styles)(FormFields)
