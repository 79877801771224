import React from 'react'
import injectSheet from 'react-jss'
import styles from './student-details-permissions.styles'
import CircleStatus from 'pobble-components/dist/circle-status'
import Section from '../../../../shared/details/section/section'

const StudentDetailsPermissions = ({ classes, pupilName, permission }) => (
  <Section title="Permissions">
    <div>
      <CircleStatus className={classes.icon} status={permission} />
      {permission && (
        <span>{pupilName} has permission to publish and comment on Pobble</span>
      )}
      {!permission && (
        <span>
          {pupilName} doesn't have permission to publish and comment on Pobble
        </span>
      )}
    </div>
  </Section>
)

export default injectSheet(styles)(StudentDetailsPermissions)
