import {
  getComments,
  apiUpdateComment,
  apiApproveComment,
  apiDeclineComment
} from '../../common/api/comments'

const fetchComments = (params, initiator) => ({
  requestPrefix: 'COMMENTS:FETCHING',
  params: { initiator },
  request: () =>
    getComments({
      include:
        'comment.details,comment.account,comment.account.names,comment.timestamps,comment.work,comment.flag',
      ...params
    })
})

const loadMoreComments = () => ({
  type: 'COMMENTS:LOAD_MORE'
})

const setComments = comments => ({
  type: 'COMMENTS:SET',
  data: comments
})

const approveComment = commentId => ({
  requestPrefix: 'COMMENT:APPROVE',
  params: { commentId },
  request: () => apiApproveComment(commentId)
})

const disapproveComment = commentId => ({
  requestPrefix: 'COMMENT:DISAPPROVE',
  params: { commentId },
  request: () => apiDeclineComment(commentId)
})

const updateCommentBody = (commentId, content) => ({
  requestPrefix: 'COMMENT:UPDATE_BODY',
  params: { commentId, content },
  request: () => apiUpdateComment(commentId, { content })
})

export {
  setComments,
  fetchComments,
  approveComment,
  disapproveComment,
  updateCommentBody,
  loadMoreComments
}
