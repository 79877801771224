import { apiGetAccounts } from '../../common/api/admin'

const fetchUsers = (params, initiator) => ({
  requestPrefix: 'USERS:FETCHING',
  params: { initiator },
  request: () =>
    apiGetAccounts({
      with_teachers: true,
      with_pending_teachers: true,
      with_regulars: true,
      with_pobblers: true,
      include:
        'account.names,account.email_identity.email,account.school,account.pending_teacher_type,account.pending_teacher_type.school.names,.school,account.teacher_type.status,account.teacher_type.school.names,account.subscriptions',
      order: 'desc',
      ...params
    })
})

const resetFilters = () => ({ type: 'USERS:RESET_FILTERS' })
const setSearchFilters = filters => ({
  type: 'USERS:SET_SEARCH_FILTERS',
  filters
})

const loadMoreUsers = () => ({
  type: 'USERS:LOAD_MORE'
})

export { fetchUsers, loadMoreUsers, setSearchFilters, resetFilters }
