import { apiClient } from 'pobble-components/dist/api'

const apiConnectToGroupCall = id =>
  apiClient.post(
    `v3/schools/${id}/groupcall_operations/on?include=school.groupcall`
  )

const apiDisconnectFromGroupCall = id =>
  apiClient.post(
    `v3/schools/${id}/groupcall_operations/off?include=school.groupcall`
  )

const apiPullFromGroupCall = id =>
  apiClient.post(
    `v3/schools/${id}/groupcall_operations/pull?include=school.groupcall`
  )

export {
  apiConnectToGroupCall,
  apiDisconnectFromGroupCall,
  apiPullFromGroupCall
}
