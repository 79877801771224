import React from 'react'
import styles from './load-more-button.styles'
import injectSheet from 'react-jss'
import Button from 'pobble-components/dist/button'

const LoadMoreButton = ({ classes, onLoadMore, pending }) => (
  <div className={classes.container}>
    <Button disabled={pending} tall secondary action={onLoadMore}>
      Load more
    </Button>
  </div>
)

export default injectSheet(styles)(LoadMoreButton)
