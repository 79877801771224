export default {
  table: {
    '& th': {
      textAlign: 'left'
    }
  },
  center: {
    textAlign: 'center !important'
  }
}
