import { connect } from 'react-redux'
import UserDetailsGroupSubs from './user-details-group-subs'
import {
  getUser,
  getUpdateAccountRequest
} from '../../../../../stores/user-details/user-details.selectors'
import { withRouter } from 'react-router'
import { createGroupSubscription } from '../../../../../stores/user-details/user-details.actions'
import { withMe } from 'pobble-components/dist/pobble-user'
import { toast } from 'pobble-components/dist/toast'
import { errorHandler } from 'pobble-components/dist/error-handler'

const mapStateToProps = (state, { me }) => {
  const user = getUser(state)
  const updateAccountDetails = getUpdateAccountRequest(state)
  return {
    pending: updateAccountDetails.pending,
    subsPlan: user.individual_subscription && user.individual_subscription.plan,
    subsStatus:
      user.individual_subscription && user.individual_subscription.status,
    subsGroupId: user.group_subscription_id,
    subscriptionIds: user && user.group_subscription_ids,
    allSeatsOwned: user && user.all_my_subscriptions_group_addons
  }
}

const mapDispatchToProps = (dispatch, { match, me }) => ({
  onSubmit: values => {
    const data = {
      plan_type: values.plan,
      subscriptions_group_id: values.subsGroupId
    }
    dispatch(createGroupSubscription(match.params.id, data))
      .then(toast.success('Group subscription successfully updated'))
      .catch(errorHandler)
  }
})

export default withMe(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(UserDetailsGroupSubs))
)
