import { createSelector } from 'reselect'
import { getAllTeachers } from '../teachers/teachers.selectors.js'

const getFilterStatus = state => state.TeachersFilter.status
const getFilterSearch = state => state.TeachersFilter.search

const getTeachersFilteredByStatus = createSelector(
  [getFilterStatus, getAllTeachers],
  (statusFilter, teachers) =>
    statusFilter
      ? teachers.filter(teacher => teacher.status === statusFilter)
      : teachers
)

const getFilteredTeachers = createSelector(
  [getFilterSearch, getAllTeachers],
  (searchValue, teachers) =>
    !searchValue
      ? teachers
      : teachers.filter(
          teacher =>
            teacher.pending_teacher_type.school &&
            teacher.pending_teacher_type.school.title
              .toLowerCase()
              .indexOf(searchValue) !== -1
        )
)

export {
  getFilterStatus,
  getFilteredTeachers,
  getFilterSearch,
  getTeachersFilteredByStatus
}
