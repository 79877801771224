export default ({ COLORS }) => ({
  container: {
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      left: '0',
      top: '120px',
      bottom: '0',
      width: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      opacity: '0',
      pointerEvents: 'none',
      transition: 'opacity: 0.3s'
    }
  },
  pending: {
    '&:after': {
      opacity: '1',
      pointerEvents: 'auto'
    }
  },
  table: {
    margin: '0 0 20px',
    '& th': {
      textAlign: 'left'
    },
    '& $center': {
      textAlign: 'center'
    }
  },
  center: {}
})
