import { createReducer } from 'pobble-components/dist/redux-helpers'

let defaultState = {
  search: '',
  status: 'pending'
}

const TeachersFilter = createReducer(defaultState, {
  'TEACHER_FILTER:SET_STATUS': (state, { data }) => ({
    ...state,
    status: data.status
  }),
  'TEACHER_FILTER:SET_SEARCH_VALUE': (state, { searchValue }) => ({
    ...state,
    search: searchValue
  })
})

export default TeachersFilter
