import React, { Component, Fragment } from 'react'
import injectSheet from 'react-jss'
import styles from './assign-teacher.styles.js'
import AssignTeacherModal from '../assign-teacher-modal/assign-teacher-modal.container.js'
import Button from 'pobble-components/dist/button'

class AssignTeacher extends Component {
  constructor(props) {
    super(props)
    this.state = { openModal: false }
  }

  openModalHandler = () => {
    this.setState({ openModal: true })
  }

  closeModalHandler = () => {
    this.setState({ openModal: false })
  }

  render() {
    const {
      classes,
      id,
      suggestedSchool,
      suggestedSchoolLabel,
      email,
      onUpdate
    } = this.props
    return (
      <Fragment>
        <em>{suggestedSchoolLabel}</em>
        <Button simple action={this.openModalHandler} className={classes.link}>
          Assign to school
        </Button>
        <AssignTeacherModal
          isOpen={this.state.openModal}
          id={id}
          suggestedSchool={suggestedSchool}
          email={email}
          onUpdate={onUpdate}
          onClose={this.closeModalHandler}
        />
      </Fragment>
    )
  }
}

export default injectSheet(styles)(AssignTeacher)
