import { connect } from 'react-redux'
import Logo from './logo'
import { deleteLogo } from '../../../../stores/school/school.actions'
import { withRouter } from 'react-router'
import { errorHandler } from 'pobble-components/dist/error-handler'

const mapDispatchToProps = (dispatch, { match }) => ({
  onDelete: state => dispatch(deleteLogo(match.params.id)).catch(errorHandler)
})

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(Logo)
)
