import { connect } from 'react-redux'
import UserDetailsForm from './user-details-form'
import {
  getUser,
  getUpdateAccountRequest
} from '../../../../../stores/user-details/user-details.selectors'
import { format } from 'date-fns'
import { withRouter } from 'react-router'
import { updateAccountDetails } from '../../../../../stores/user-details/user-details.actions'
import { withMe } from 'pobble-components/dist/pobble-user'
import { isAdmin } from '../../../../../common/api/me.selectors'
import { errorHandler } from 'pobble-components/dist/error-handler'

const mapStateToProps = (state, { me }) => {
  const user = getUser(state)
  const updateAccountDetails = getUpdateAccountRequest(state)
  return {
    pending: updateAccountDetails.pending,
    created: format(user.created_at, 'DD.MM.YYYY H:s'),
    signIns: user.number_of_sign_ins,
    lastSignIn: format(user.last_sign_in_at, 'DD.MM.YYYY H:s'),
    firstName: user.first_name,
    lastName: user.last_name,
    displayName: user.display_name,
    email: (user.email && user.email_identity.email) || '',
    showNote: isAdmin(me),
    note: user.note
  }
}

const mapDispatchToProps = (dispatch, { match, me }) => ({
  onSubmit: values => {
    const data = {
      first_name: values.firstName,
      last_name: values.lastName,
      display_name: values.displayName,
      email: values.email
    }
    if (isAdmin(me)) data.note = values.note
    dispatch(updateAccountDetails(match.params.id, data)).catch(errorHandler)
  }
})

export default withMe(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(UserDetailsForm)
  )
)
