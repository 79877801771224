import { connect } from 'react-redux'
import Presentations from './presentations'
import { fetchPresentations } from '../../../stores/presentations/presentations.actions'
import {
  getFetchPresentationsRequest,
  getServerFilterParams,
  getFetchPresentationsRequestInitiator
} from '../../../stores/presentations/presentations.selectors'

const mapStateToProps = state => ({
  pending:
    getFetchPresentationsRequest(state).pending &&
    getFetchPresentationsRequestInitiator(state) === 'INITIAL',
  filterParams: getServerFilterParams(state)
})

const mapDispatchToProps = dispatch => ({ dispatch })

const mergeProps = (stateProps, { dispatch }, ownProps) => ({
  ...ownProps,
  ...stateProps,
  onMount: () =>
    dispatch(fetchPresentations(stateProps.filterParams, 'INITIAL')),
  onUpdate: prevProps => {
    if (prevProps.filterParams !== stateProps.filterParams) {
      const initiator =
        prevProps.filterParams.limit !== stateProps.filterParams.limit
          ? 'LOAD_MORE'
          : 'SEARCH'
      dispatch(fetchPresentations(stateProps.filterParams, initiator))
    }
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Presentations)
