import React, { useState, useEffect } from 'react'
import styles from './user-details-group-subs.styles'
import injectSheet from 'react-jss'
import Section from '../../../../shared/details/section/section'
import FormRow from '../../../../shared/details/form-row/form-row'
import FormCell from '../../../../shared/details/form-cell/form-cell'
import FormLabel from '../../../../shared/details/form-label/form-label'
import FormInput from 'pobble-components/dist/form-elements/form-input'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Button from 'pobble-components/dist/button'
import FormSelect from 'pobble-components/dist/form-elements/form-select'
import classNames from 'classnames'
import TransferSub from './transfer-sub/transfer-sub.container'

const requiredMsg = 'This field should not be empty'

const UserDetailsGroupSubs = ({
  classes,
  pending,
  plan,
  subsGroupId,
  subsPlan,
  subsEnd,
  subsStatus,
  subscriptionIds,
  onSubmit,
  allSeatsOwned
}) => {
  const [isToggled, setIsToggled] = useState(false)
  const [subId, setSubId] = useState(false)

  useEffect(() => {}, [isToggled])

  useEffect(() => {}, [subId])

  const toggleBoolean = id => {
    setSubId(id)
    setIsToggled(!isToggled)
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        plan: subsPlan || '',
        subsGroupId: subsGroupId || ''
      }}
      validationSchema={Yup.object().shape({
        plan: Yup.string().required(requiredMsg),
        subsGroupId: Yup.string().required(requiredMsg)
      })}>
      {formikProps => {
        const {
          values,
          handleBlur,
          handleChange,
          handleSubmit,
          isValid,
          errors,
          touched
        } = formikProps
        return (
          <form
            onSubmit={handleSubmit}
            className={classNames(classes.form, {
              [classes.pending]: pending
            })}>
            <Section
              title="Group subscriptions"
              renderHeader={() => (
                <Button
                  className={classes.submitButton}
                  disabled={!isValid}
                  type="submit"
                  tall>
                  Edit group subscription
                </Button>
              )}>
              <FormRow>
                <FormCell>
                  <FormLabel>Select a plan</FormLabel>
                  <FormSelect
                    name="plan"
                    value={values.plan}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    validationError={touched.plan && errors.plan}>
                    <option value="">-</option>
                    <option value="pobble-silver">Silver (T&L)</option>
                    <option value="pobble-moderation">Moderation</option>
                  </FormSelect>
                </FormCell>
                <FormCell>
                  <FormLabel>Subscription group ID</FormLabel>
                  <FormInput
                    name="subsGroupId"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.subsGroupId}
                    placeholder={'Ex: 16BjlASFr8Od2Hxm'}
                    validationError={touched.subsGroupId && errors.subsGroupId}
                  />
                </FormCell>
              </FormRow>
              {subscriptionIds && subscriptionIds.length > 0 && (
                <FormRow>
                  <FormCell>
                    <FormLabel>
                      {subscriptionIds.length} assigned subscriptions seats:
                    </FormLabel>
                    <ul>
                      {subscriptionIds.map(id => (
                        <li key={id} id={id}>
                          {id}
                        </li>
                      ))}
                    </ul>
                    <hr />
                  </FormCell>
                </FormRow>
              )}
              {allSeatsOwned && allSeatsOwned.length > 0 && (
                <FormRow>
                  <FormCell>
                    <FormLabel>
                      {allSeatsOwned.length} owned subscription seats:
                    </FormLabel>
                    <ul>
                      {allSeatsOwned.map(seat => (
                        <li key={seat.id} id={seat.id}>
                          {seat.assigned_to_account_id ? (
                            <strike>{seat.id}</strike>
                          ) : (
                            <span>{seat.id}</span>
                          )}
                          {seat.addon_delegated ? (
                            <span> (delegated)</span>
                          ) : (
                            ''
                          )}
                          &nbsp;–{' '}
                          {seat.subscriptions_group &&
                            seat.subscriptions_group.subscription_id}
                          {!seat.addon_delegated && (
                            <Button
                              simple
                              action={() =>
                                toggleBoolean(
                                  seat.subscriptions_group.subscription_id
                                )
                              }>
                              Transfer
                            </Button>
                          )}
                        </li>
                      ))}
                      <TransferSub
                        isOpen={isToggled}
                        onClose={toggleBoolean}
                        id={subId}
                      />
                    </ul>
                  </FormCell>
                </FormRow>
              )}
            </Section>
          </form>
        )
      }}
    </Formik>
  )
}

export default injectSheet(styles)(UserDetailsGroupSubs)
