import React, { Component } from 'react'
import injectSheet from 'react-jss'
import styles from './sidebar.styles.js'
import Navigation from './navigation/navigation'
import NavItem from './nav-item/nav-item'

class Sidebar extends Component {
  componentDidMount() {
    this.props.onMount()
  }

  render() {
    const {
      classes,
      pendingComments,
      pendingTeachers,
      pendingPresentations,
      pendingSchools,
      adminPolicies
    } = this.props
    return (
      <nav className={classes.container}>
        <h1 className={classes.heading}>Pobble Admin</h1>
        <Navigation title="Workflow">
          {adminPolicies.can_see_approve_comments_section && (
            <NavItem path="/approve-comments" counter={pendingComments}>
              Approve comments
            </NavItem>
          )}
          {adminPolicies.can_see_approve_teachers_section && (
            <NavItem path="/approve-teachers" counter={pendingTeachers}>
              Approve teachers
            </NavItem>
          )}
          {/*adminPolicies.can_see_approve_presentations_section && (
            <NavItem
              path="/approve-presentations"
              counter={pendingPresentations}>
              Approve presentations
            </NavItem>
          )*/}
          {adminPolicies.can_see_schools_section && (
            <NavItem path="/review-schools" counter={pendingSchools}>
              Review schools
            </NavItem>
          )}
        </Navigation>
        <Navigation title="Platform management">
          {adminPolicies.can_see_schools_section && (
            <NavItem path="/schools">Schools</NavItem>
          )}
          {adminPolicies.can_see_works_section && (
            <NavItem path="/works">Works</NavItem>
          )}
          {adminPolicies.can_see_users_section && (
            <NavItem path="/users">Users</NavItem>
          )}
          {adminPolicies.can_see_campaigns_section && (
            <NavItem path="/campaigns">Campaigns</NavItem>
          )}
          <NavItem path="/organisations">Organisations</NavItem>
          <NavItem path="/other">Other</NavItem>
        </Navigation>
        {/*<Navigation title="Tools">
          <NavItem path="/merge-students" disabled>
            Merge students
          </NavItem>
        </Navigation>*/}
      </nav>
    )
  }
}

export default injectSheet(styles)(Sidebar)
