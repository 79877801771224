import { connect } from 'react-redux'
import CampaignsList from './campaigns-list'
import {
  getCampaignsList,
  getFetchCampaignsRequest
} from '../../../../stores/campaigns/campaigns.selectors'

const mapStateToProps = state => {
  const req = getFetchCampaignsRequest(state)
  return {
    campaigns: getCampaignsList(state) || [],
    pending: req.pending
  }
}

export default connect(
  mapStateToProps,
  null
)(CampaignsList)
