import React, { Component } from 'react'
import injectSheet from 'react-jss'
import styles from './school-students.styles.js'

import Paper from 'pobble-components/dist/paper'

import Container from '../../shared/container/container.jsx'
import Main from '../../shared/main/main.jsx'

import SchoolStudentsFilter from './students-filter/students-filter.container.js'
import SchoolStudentsList from './students-list/students-list.container.js'
import SectionTitle from '../../shared/section-title/section-title.jsx'
import { Link } from 'react-router-dom'
import Button from 'pobble-components/dist/button'

class SchoolsStudents extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    const { classes, sectionTitle, schoolId } = this.props
    return (
      <Container>
        <Main>
          <Paper className={classes.container}>
            <div className={classes.header}>
              <SectionTitle title={sectionTitle} />
              <Link
                className={classes.link}
                to={`/schools/${schoolId}/retired-students`}>
                <Button secondary tall>
                  Retired students
                </Button>
              </Link>
            </div>
            <SchoolStudentsFilter />
            <SchoolStudentsList />
          </Paper>
        </Main>
      </Container>
    )
  }
}

export default injectSheet(styles)(SchoolsStudents)
