import React, { useEffect } from 'react'
import styles from './organisation-edition.styles'
import injectSheet from 'react-jss'
import Container from '../../shared/container/container'
import Main from '../../shared/main/main'
import Paper from 'pobble-components/dist/paper'
import OrganisationEditionForm from './organisation-edition-form/organisation-edition-form.container'
import Spinner from 'pobble-components/dist/spinner'

const OrganisationEdition = ({ classes, pending, onMount }) => {
  useEffect(() => {
    onMount()
  }, []) // eslint-disable-line

  return (
    <Container>
      <Main>
        <Paper className={classes.paper}>
          {pending && <Spinner />}
          {!pending && <OrganisationEditionForm />}
        </Paper>
      </Main>
    </Container>
  )
}

export default injectSheet(styles)(OrganisationEdition)
