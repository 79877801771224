import { combineReducers } from 'redux'
import {
  createReducer,
  createRequestReducer
} from 'pobble-components/dist/redux-helpers'
import { normalize, schema } from 'normalizr'

const normalizeWorks = data => {
  const work = new schema.Entity('works')
  return normalize(data, [work])
}

const DEFAULT_LIMIT = 10
const LOAD_MORE_STEP = 10

const defaultFilters = {
  currentLimit: DEFAULT_LIMIT,
  studentId: '',
  schoolId: '',
  workTitle: '',
  workId: '',
  published: false,
  unpublished: false,
  deleted: false,
  flagged: false
}

const WorksReducer = combineReducers({
  fetchWorksRequest: createRequestReducer('WORKS:FETCHING'),
  filter: createReducer(defaultFilters, {
    'WORKS:LOAD_MORE': state => ({
      ...state,
      currentLimit: state.currentLimit + LOAD_MORE_STEP
    }),
    'WORKS:SET_SEARCH_FILTERS': (state, { filters }) => ({
      ...state,
      currentLimit: DEFAULT_LIMIT,
      ...filters
    }),
    'WORKS:RESET_FILTERS': state => ({ ...defaultFilters })
  }),
  works: createReducer(null, {
    'WORKS:FETCHING_REQUEST_SUCCESS': (state, { response }) =>
      normalizeWorks(response.data.works),
    'WORKS:FLAG_REQUEST_SUCCESS': (state, { response }) => ({
      ...state,
      entities: {
        works: {
          ...state.entities.works,
          [response.data.work.id]: {
            ...state.entities.works[response.data.work.id],
            status: 'flagged'
          }
        }
      }
    }),
    'WORKS:UNFLAG_REQUEST_SUCCESS': (state, { response }) => ({
      ...state,
      entities: {
        works: {
          ...state.entities.works,
          [response.data.work.id]: {
            ...state.entities.works[response.data.work.id],
            status: 'unpublished'
          }
        }
      }
    }),
    'WORKS:DELETE_REQUEST_SUCCESS': (state, { params, response }) => ({
      ...state,
      result: state.result.filter(id => id !== params.id)
    })
  })
})

export default WorksReducer
