import { connect } from 'react-redux'
import App from './app'
import { fetchMe } from '../../stores/me/me.actions'

const mapDispatchToProps = dispatch => ({
  fetchMe: () => dispatch(fetchMe())
})

export default connect(
  null,
  mapDispatchToProps
)(App)
