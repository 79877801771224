import React from 'react'
import styles from './parent-entry.styles'
import injectSheet from 'react-jss'
import { Cell } from 'pobble-components/dist/table'
import UserName from 'pobble-components/dist/user-name'
import Permission from './permission/permission'
import Button from 'pobble-components/dist/button'

const ParentEntry = ({ classes, email, permission, id, history }) => (
  <tr>
    <Cell>
      <UserName>{email}</UserName>
    </Cell>
    <Cell className={classes.center}>
      <Permission>{permission}</Permission>
    </Cell>
    <Cell className={classes.right}>
      <Button action={() => history.push(`/users/${id}`)}>View</Button>
    </Cell>
  </tr>
)

export default injectSheet(styles)(ParentEntry)
