import React, { Component, Fragment } from 'react'
import injectSheet from 'react-jss'
import classNames from 'classnames'

import styles from './teachers-list.styles.js'

import Teacher from '../teacher/teacher.container.js'
import LoadMoreButton from '../load-more-button/load-more-button.container'

import { Table, HeadCell } from 'pobble-components/dist/table'
import Spinner from 'pobble-components/dist/spinner'

class TeachersList extends Component {
  componentDidMount() {
    this.props.onMount()
  }

  componentDidUpdate(prevProps) {
    this.props.onUpdate(prevProps)
  }

  render() {
    const {
      classes,
      showLoading,
      teachers,
      noTeachers,
      teacherGettingAdded,
      noTeachersMatchingFilters,
      hideLoadMore
    } = this.props
    return showLoading ? (
      <Spinner className={classes.spinner} />
    ) : noTeachers ? (
      <span>No teachers</span>
    ) : (
      <Fragment>
        {!noTeachersMatchingFilters && (
          <Table
            className={classNames({
              [classes.loading]: teacherGettingAdded
            })}>
            <thead>
              <tr>
                <HeadCell className={classes.cell}>Sign up</HeadCell>
                <HeadCell className={classes.cell}>Request</HeadCell>
                <HeadCell className={classes.cell}>Name</HeadCell>
                <HeadCell className={classes.cell}>Email</HeadCell>
                <HeadCell className={classes.cell}>School</HeadCell>
                <HeadCell className={classes.cell}>Website</HeadCell>
                <HeadCell className={classes.cell}>Teachers</HeadCell>
                <HeadCell className={classes.cell}>Ind. sub</HeadCell>
                <HeadCell className={classes.cell}>Seats</HeadCell>
                <HeadCell className={classes.cell}>Orgs</HeadCell>
                <HeadCell className={classes.cell}>PS</HeadCell>
                <HeadCell className={classes.cell}>Last school login</HeadCell>
                <HeadCell className={classes.cell}>Notes</HeadCell>
                <HeadCell className={classes.cell} />
              </tr>
            </thead>
            <tbody>
              {teachers.map(teacher => (
                <Teacher key={teacher.id} id={teacher.id} />
              ))}
            </tbody>
          </Table>
        )}
        {noTeachersMatchingFilters && (
          <span>No teachers matching those criteria.</span>
        )}
        {!hideLoadMore && <LoadMoreButton />}
      </Fragment>
    )
  }
}

export default injectSheet(styles)(TeachersList)
