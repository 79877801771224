import React from 'react'
import injectSheet from 'react-jss'
import styles from './approve-comments.styles.js'

import Paper from 'pobble-components/dist/paper'

import Container from '../../shared/container/container.jsx'
import Main from '../../shared/main/main.jsx'

import CommentsFilters from './comments-filter/comments-filter.container.js'
import CommentsList from './comments-list/comments-list.container.js'
import SectionTitle from '../../shared/section-title/section-title.jsx'

const ApproveComments = ({ classes }) => {
  return (
    <Container>
      <Main>
        <Paper className={classes.container}>
          <SectionTitle title="Approve comments" />
          <CommentsFilters />
          <CommentsList />
        </Paper>
      </Main>
    </Container>
  )
}

export default injectSheet(styles)(ApproveComments)
