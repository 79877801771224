import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import StudentDetailsParents from './student-details-parents'
import { getStudent } from '../../../../../stores/student-details/student-details.selectors'

const mapStateToProps = (state, { match }) => ({
  parents: getStudent(state, match.params.id).student_type.parent_types
})

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(StudentDetailsParents)
)
