import { connect } from 'react-redux'
import OrganisationEdition from './organisation-edition'
import { fetchOrganisation } from '../../../stores/organisations/organisations.actions'
import { getFetchOrganisationRequest } from '../../../stores/organisations/organisations.selectors'
import { errorHandler } from 'pobble-components/dist/error-handler'
import { withRouter } from 'react-router'

const mapStateToProps = state => {
  const fetchOrganisationRequest = getFetchOrganisationRequest(state)
  return {
    pending: fetchOrganisationRequest.pending
  }
}

const mapDispatchToProps = (dispatch, { match }) => ({
  onMount: () =>
    dispatch(fetchOrganisation(match.params.id)).catch(errorHandler)
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrganisationEdition)
)
