import { connect } from 'react-redux'
import LoadMoreButton from './load-more-button'
import { loadMoreUsers } from '../../../../../stores/users/users.actions'
import {
  getFetchUsersRequest,
  getFetchUsersRequestInitiator
} from '../../../../../stores/users/users.selectors'

const mapStateToProps = state => ({
  pending:
    getFetchUsersRequest(state).pending &&
    getFetchUsersRequestInitiator(state) === 'LOAD_MORE'
})

const mapDispatchToProps = dispatch => ({
  onLoadMore: () => dispatch(loadMoreUsers())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoadMoreButton)
