import React from 'react'
import styles from './cell.styles'
import injectSheet from 'react-jss'
import classNames from 'classnames'

const Cell = ({ classes, children, cellProps, className }) => (
  <td className={classNames(classes.cell, className)} {...cellProps}>
    {children}
  </td>
)

export default injectSheet(styles)(Cell)
