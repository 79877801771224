export default {
  container: {
    padding: '20px 0',
    borderBottom: '1px solid #eee'
  },
  noBorder: {
    borderBottom: 'none'
  },
  holder: {
    display: 'flex',
    margin: '0 -10px'
  }
}
