import React from 'react'
import styles from './works-list.styles'
import injectSheet from 'react-jss'
import { Table, HeadCell } from 'pobble-components/dist/table'
import Work from './work/work.container'
import LoadMoreButton from './load-more-button/load-more-button.container'

const WorksList = ({ classes, works, hideLoadMore }) => (
  <div>
    <Table className={classes.table}>
      <thead>
        <tr>
          <HeadCell>Updated</HeadCell>
          <HeadCell>Title</HeadCell>
          <HeadCell>School</HeadCell>
          <HeadCell>Status</HeadCell>
          <HeadCell />
        </tr>
      </thead>
      <tbody>
        {works.map(work => (
          <Work key={work.id} id={work.id} />
        ))}
      </tbody>
    </Table>
    {!hideLoadMore && <LoadMoreButton />}
  </div>
)

export default injectSheet(styles)(WorksList)
