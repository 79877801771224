import { connect } from 'react-redux'
import WorksList from './works-list'
import {
  getWorksList,
  lessWorksThanLimit,
  getFetchWorksRequest,
  getFetchWorksRequestInitiator
} from '../../../../stores/works/works.selectors'

const mapStateToProps = state => {
  const req = getFetchWorksRequest(state)
  const initiator = getFetchWorksRequestInitiator(state)
  return {
    works: getWorksList(state) || [],
    pending: req.pending && initiator === 'SEARCH',
    hideLoadMore:
      !(req.pending && initiator === 'LOAD_MORE') && lessWorksThanLimit(state)
  }
}

export default connect(
  mapStateToProps,
  null
)(WorksList)
