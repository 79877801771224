import React from 'react'
import styles from './user-details-school.styles'
import injectSheet from 'react-jss'
import Section from '../../../../shared/details/section/section'
import SchoolTable from './school-table/school-table.container'

const UserDetailsSchool = ({ classes, pending, noSchool }) => (
  <Section title="School" pending={pending}>
    {!noSchool && <SchoolTable />}
    {noSchool && (
      <p className={classes.noSchool}>
        This user is not a teacher nor a pending teacher
      </p>
    )}
  </Section>
)

export default injectSheet(styles)(UserDetailsSchool)
