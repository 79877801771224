import { apiClient } from 'pobble-components/dist/api'

const apiGetMe = params =>
  apiClient.get('v3/me', {
    params: {
      include:
        'account.subscriptions,account.names,account.finish_signup_status,account.policy,account.pobbler_type,account.avatar,account.admin_policy'
    }
  })

const apiAccountDetails = (id, params) =>
  apiClient.get(`v3/accounts/${id}`, { params })

const apiUploadAvatar = (id, img) => {
  const formData = new FormData()
  formData.append('avatar', img)
  return apiClient.post(`v3/accounts/${id}/avatars`, formData)
}

const apiRemoveAvatar = id => apiClient.delete(`v3/accounts/${id}/avatars`)

const apiCreateSubscription = (id, data, params) =>
  apiClient.put(
    `/v3/admin/accounts/${id}/create_subscription_without_payment/`,
    data,
    { params }
  )

const apiAssignModerationAddon = (id, data, params) =>
  apiClient.put(
    `/v3/admin/accounts/${id}/add_moderation_addon_without_payment/`,
    data,
    { params }
  )

const apiCreateGroupSubscription = (id, data, params) =>
  apiClient.put(
    `/v3/admin/accounts/${id}/assign_subscriptions_group_addon/`,
    data,
    { params }
  )

const apiTransferGroupSubscription = (id, data, params) =>
  apiClient.post(
    '/v3/subscriptions_groups/delegate_to_email',
    { subscription_id: data.id, email: data.email },
    { params }
  )

export {
  apiGetMe,
  apiAccountDetails,
  apiUploadAvatar,
  apiRemoveAvatar,
  apiCreateSubscription,
  apiCreateGroupSubscription,
  apiAssignModerationAddon,
  apiTransferGroupSubscription
}
