import React from 'react'
import styles from './organisation-edition-form.styles'
import injectSheet from 'react-jss'
import Section from '../../../shared/details/section/section'
import FormRow from '../../../shared/details/form-row/form-row'
import FormCell from '../../../shared/details/form-cell/form-cell'
import FormLabel from '../../../shared/details/form-label/form-label'
import FormInput from 'pobble-components/dist/form-elements/form-input'
import { Formik } from 'formik'
import Button from 'pobble-components/dist/button'
import FormCheckbox from 'pobble-components/dist/form-elements/form-checkbox'
import { Link } from 'react-router-dom'

const OrganisationEditionForm = ({
  classes,
  onSubmit,
  title,
  local_authority,
  schools
}) => (
  <Formik
    onSubmit={onSubmit}
    initialValues={{
      title: title || '',
      local_authority: local_authority || false,
      schools
    }}>
    {formikProps => {
      const {
        values,
        handleBlur,
        handleChange,
        handleSubmit,
        isValid
      } = formikProps
      return (
        <form onSubmit={handleSubmit} className={classes.form}>
          <Section
            title="Edition of an Organisation"
            renderHeader={() => (
              <Button
                className={classes.submitButton}
                disabled={!isValid}
                type="submit"
                tall>
                Submit
              </Button>
            )}>
            <FormRow>
              <FormCell>
                <FormLabel>Title</FormLabel>
                <FormInput
                  name="title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title}
                  placeholder={'Ex: Devon'}
                />
              </FormCell>
            </FormRow>
            <FormRow>
              <FormCell>
                <div>
                  <FormLabel htmlFor="local_authority">
                    <FormCheckbox
                      name="local_authority"
                      id="local_authority"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values.local_authority}
                    />
                    Local authority
                  </FormLabel>
                </div>
              </FormCell>
            </FormRow>
            {schools && schools.length > 0 && (
              <FormRow>
                <FormCell>
                  <FormLabel>Schools part of this organisation:</FormLabel>
                  <ul>
                    {schools &&
                      schools.map(school => (
                        <li key={school.id} id={school.id}>
                          <Link
                            className={classes.link}
                            to={`/schools/${school.id}`}>
                            {school.title}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </FormCell>
              </FormRow>
            )}
          </Section>
        </form>
      )
    }}
  </Formik>
)

export default injectSheet(styles)(OrganisationEditionForm)
