import React, { Component } from 'react'
import styles from './presentations.styles'
import injectSheet from 'react-jss'
import Container from '../../shared/container/container'
import Main from '../../shared/main/main'
import Paper from 'pobble-components/dist/paper'
import SectionTitle from '../../shared/section-title/section-title'
import PresentationsList from './presentations-list/presentations-list.container'
import Spinner from 'pobble-components/dist/spinner'

class Presentations extends Component {
  componentDidMount() {
    this.props.onMount()
  }
  componentDidUpdate(prevProps) {
    this.props.onUpdate(prevProps)
  }
  render() {
    const { classes, pending } = this.props
    return (
      <Container>
        <Main>
          <Paper className={classes.container}>
            <SectionTitle title="Presentations" />
            {pending && <Spinner className={classes.spinner} />}
            {!pending && <PresentationsList />}
          </Paper>
        </Main>
      </Container>
    )
  }
}

export default injectSheet(styles)(Presentations)
