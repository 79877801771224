import React from 'react'
import injectSheet from 'react-jss'
import styles from './navigation.styles'

const Navigation = ({ classes, title, children }) => (
  <dl className={classes.navigation}>
    {title && <dt className={classes.navigationTitle}>{title}</dt>}
    {children && <ul className={classes.navigationLinks}>{children}</ul>}
  </dl>
)

export default injectSheet(styles)(Navigation)
