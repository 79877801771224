import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Teacher from './teacher'
import {
  promoteTeacher,
  demoteTeacher,
  deleteTeacher,
  resendInvitation
} from '../../../../stores/school-teachers/school-teachers.actions'
import { isTeacherBeingUpdated } from '../../../../stores/school-teachers/school-teachers.selectors'
import { errorHandler } from 'pobble-components/dist/error-handler'
import { toast } from 'pobble-components/dist/toast'
import { getErrorInfo } from 'pobble-components/dist/utils'

const mapStateToProps = (state, { id }) => ({
  showLoading: isTeacherBeingUpdated(state, id)
})

const mapDispatchToProps = (dispatch, { match }) => ({
  onSchoolLeaderChange: (checked, teacherId) => {
    return checked
      ? dispatch(promoteTeacher(teacherId)).catch(errorHandler)
      : dispatch(demoteTeacher(teacherId)).catch(errorHandler)
  },
  onDelete: teacherId =>
    dispatch(deleteTeacher(match.params.id, teacherId)).catch(errorHandler),
  onResendInvitation: userId =>
    dispatch(resendInvitation(userId))
      .then(() => toast.success('The invitation has now been resent.'))
      .catch(e => {
        if (getErrorInfo(e).ref === 'e0000') {
          toast.warn('This teacher already confirmed its email')
        } else {
          errorHandler(e)
        }
      })
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Teacher))
