import React from 'react'
import styles from './student-details-head.styles'
import injectSheet from 'react-jss'
import { Link } from 'react-router-dom'
import CarretLeftIcon from 'react-icons/lib/fa/caret-left'
import UserDetailsAvatar from '../student-details-avatar/student-details-avatar.container'

const StudentDetailsHead = ({ classes, fullName, displayName, match }) => (
  <div className={classes.container}>
    <Link className={classes.backLink} to={'../students'}>
      <CarretLeftIcon />
      Back to students list
    </Link>
    <div className={classes.studentInfo}>
      <UserDetailsAvatar className={classes.avatar} />
      <div className={classes.names}>
        <strong>{fullName}</strong>
        <span>{displayName}</span>
      </div>
    </div>
  </div>
)

export default injectSheet(styles)(StudentDetailsHead)
