import { connect } from 'react-redux'
import dateFns from 'date-fns'
import Teacher from './teacher'
import {
  approveTeacher,
  disapproveTeacher
} from '../../../../stores/teachers/teachers.actions'
import {
  isTeacherBeingUpdated,
  getTeacher
} from '../../../../stores/teachers/teachers.selectors'
import { errorHandler } from 'pobble-components/dist/error-handler'

const mapStateToProps = (state, { id }) => {
  const teacher = getTeacher(state, id)
  return {
    showLoading: isTeacherBeingUpdated(state, id),
    signUp: dateFns.format(teacher.created_at, 'MM/DD/YYYY H:s'),
    requestedAt:
      teacher.pending_teacher_type &&
      dateFns.format(teacher.pending_teacher_type.created_at, 'MM/DD/YYYY H:s'),
    name: teacher.full_name,
    email: teacher.email_identity.email,
    school: teacher.pending_teacher_type.school,
    suggestedSchool: teacher.pending_teacher_type.description,
    status: teacher.status,
    notes: teacher.note,
    pobbleSchool:
      teacher.pending_teacher_type.school &&
      teacher.pending_teacher_type.school.pobble_school,
    individualSubscription: teacher.individual_subscriptionion,
    schoolSeatsBought:
      teacher.pending_teacher_type.school &&
      teacher.pending_teacher_type.school.group_subs_seats_bought,
    schoolSeatsAssigned:
      teacher.pending_teacher_type.school &&
      teacher.pending_teacher_type.school.group_subs_seats_assigned,
    schoolInactivity:
      teacher.pending_teacher_type.school &&
      teacher.pending_teacher_type.school.leaders_inactive,
    schoolLastLogin:
      teacher.pending_teacher_type.school &&
      teacher.pending_teacher_type.school.last_login,
    schoolOrgs:
      teacher.pending_teacher_type.school &&
      teacher.pending_teacher_type.school.school_organisations,
    schoolOrgsSubs:
      teacher.pending_teacher_type.school &&
      teacher.pending_teacher_type.school.organisation_subscriptions &&
      teacher.pending_teacher_type.school.organisation_subscriptions.length
  }
}

const mapDispatchToProps = dispatch => ({
  onApprove: teacherId =>
    dispatch(approveTeacher(teacherId)).catch(errorHandler),
  onDisapprove: teacherId =>
    dispatch(disapproveTeacher(teacherId)).catch(errorHandler)
})

export default connect(mapStateToProps, mapDispatchToProps)(Teacher)
