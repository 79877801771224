import { createReducer } from 'pobble-components/dist/redux-helpers'

let defaultState = {
  loading: false,
  pendingLogo: false,
  pendingCover: false,
  school: {}
}

const SchoolStore = createReducer(defaultState, {
  'SCHOOL:FETCHING_REQUEST': state => ({
    ...state,
    loading: true
  }),
  'SCHOOL:FETCHING_REQUEST_SUCCESS': (state, { response }) => ({
    ...state,
    loading: false,
    school: response.data.school
  }),
  'SCHOOL:FETCHING_REQUEST_ERROR': state => ({
    ...state,
    loading: false
  }),
  'SCHOOL:UPLOAD_LOGO_REQUEST': state => ({
    ...state,
    pendingLogo: true
  }),
  'SCHOOL:UPLOAD_LOGO_REQUEST_SUCCESS': (state, { response }) => ({
    ...state,
    pendingLogo: false,
    school: {
      ...state.school,
      logo_url: response.data.school.logo_url
    }
  }),
  'SCHOOL:UPLOAD_LOGO_REQUEST_ERROR': state => ({
    ...state,
    pendingLogo: false
  }),
  'SCHOOL:DELETE_LOGO_REQUEST': state => ({
    ...state,
    pendingLogo: true
  }),
  'SCHOOL:DELETE_LOGO_REQUEST_SUCCESS': (state, { response }) => ({
    ...state,
    pendingLogo: false,
    school: {
      ...state.school,
      logo_url: null
    }
  }),
  'SCHOOL:DELETE_LOGO_REQUEST_ERROR': state => ({
    ...state,
    pendingLogo: false
  }),
  'SCHOOL:UPLOAD_COVER_REQUEST': state => ({
    ...state,
    pendingCover: true
  }),
  'SCHOOL:UPLOAD_COVER_REQUEST_SUCCESS': (state, { response }) => ({
    ...state,
    pendingCover: false,
    school: {
      ...state.school,
      background_image_url: response.data.school.background_image_url
    }
  }),
  'SCHOOL:UPLOAD_COVER_REQUEST_ERROR': state => ({
    ...state,
    pendingCover: false
  }),
  'SCHOOL:DELETE_COVER_REQUEST': state => ({
    ...state,
    pendingCover: true
  }),
  'SCHOOL:DELETE_COVER_REQUEST_SUCCESS': (state, { response }) => ({
    ...state,
    pendingCover: false,
    school: {
      ...state.school,
      background_image_url: null
    }
  }),
  'SCHOOL:DELETE_COVER_REQUEST_ERROR': state => ({
    ...state,
    pendingCover: false
  }),
  'SCHOOL:SET_AS_POBBLE_SCHOOL_REQUEST_SUCCESS': (
    state,
    { params, response }
  ) => {
    return {
      ...state,
      school: {
        ...state.school,
        pobble_school: true
      }
    }
  },
  'SCHOOL:UNSET_AS_POBBLE_SCHOOL_REQUEST_SUCCESS': (
    state,
    { params, response }
  ) => {
    return {
      ...state,
      school: {
        ...state.school,
        pobble_school: false
      }
    }
  },
  'SCHOOL:SET_SCHOOL_SUB_REQUEST_SUCCESS': (state, { params, response }) => {
    return {
      ...state

      /*school: {
        ...state.school,
        pobble_school: false
      }*/
    }
  }
})

export default SchoolStore
