const getFetchSchoolDataRequest = state =>
  state.SchoolDataStore.fetchSchoolRequest
const getSchoolData = state => state.SchoolDataStore.school

const getConnectSchoolRequest = state =>
  state.SchoolDataStore.connectSchoolRequest
const getDisconnectSchoolRequest = state =>
  state.SchoolDataStore.disconnectSchoolRequest
const getPullSchoolRequest = state => state.SchoolDataStore.pullSchoolRequest

export {
  getFetchSchoolDataRequest,
  getSchoolData,
  getConnectSchoolRequest,
  getDisconnectSchoolRequest,
  getPullSchoolRequest
}
