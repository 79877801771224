import React from 'react'
import styles from './child-entry.styles'
import injectSheet from 'react-jss'
import { Cell } from 'pobble-components/dist/table'
import CircleStatus from 'pobble-components/dist/circle-status'
import Tooltip from 'pobble-components/dist/tooltip'
import Button from 'pobble-components/dist/button'

const ChildEntry = ({
  classes,
  name,
  permissionStatus,
  permissionTooltip,
  onDelete
}) => (
  <tr>
    <Cell>{name}</Cell>
    <Cell className={classes.permissionCell}>
      <Tooltip content={permissionTooltip}>
        <CircleStatus status={permissionStatus} />
      </Tooltip>
    </Cell>
    <Cell className={classes.deleteCell}>
      <Button action={onDelete} tall dangerSecondary>
        Delete
      </Button>
    </Cell>
  </tr>
)

export default injectSheet(styles)(ChildEntry)
