import { createSelector } from 'reselect'
import { getAllComments } from '../comments/comments.selectors.js'

const getFilterStatus = state => state.CommentsFilter.status
const getFilterSearch = state => state.CommentsFilter.search

const getCommentsFilteredByStatus = createSelector(
  [getFilterStatus, getAllComments],
  (statusFilter, comments) =>
    statusFilter
      ? statusFilter === 'flagged'
        ? comments.filter(comment => comment.flag)
        : comments.filter(comment => comment.status === statusFilter)
      : comments
)

const getFilteredComments = createSelector(
  [getFilterSearch, getCommentsFilteredByStatus],
  (searchValue, comments) =>
    !searchValue
      ? comments
      : comments.filter(comment => comment.work.id.indexOf(searchValue) !== -1)
)

export { getFilterStatus, getFilteredComments, getFilterSearch }
