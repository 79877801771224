import React, { Component } from 'react'
import injectSheet from 'react-jss'
import styles from './school-data.styles'
import Paper from 'pobble-components/dist/paper'
import Container from '../../shared/container/container'
import Main from '../../shared/main/main'
import SectionTitle from '../../shared/section-title/section-title'
import Spinner from 'pobble-components/dist/spinner'
import Button from 'pobble-components/dist/button'
import StatusColor from './status-color/status-color'
import classNames from 'classnames'
import FormCheckbox from 'pobble-components/dist/form-elements/form-checkbox'
import Autocomplete from 'react-autocomplete'

class SchoolData extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
      schools: []
    }
  }

  getSchools(value, cb) {
    this.props
      .onSearchChange(value)
      .then(({ data }) => this.setState({ schools: data.wonde_schools }))
  }

  componentDidMount() {
    this.props.onMount()
  }
  render() {
    const {
      classes,
      id,
      title,
      status,
      statusColor,
      lastSync,
      misWonde,
      wondeSchoolInfo,
      pullParents,
      schoolEstab,
      misGroupCall,
      enabledGroupCall,
      finishedAtGroupCall,
      pending,
      onPull,
      onConnect,
      onDisconnect,
      pendingActions,
      onPullParentsChange,
      onPullGroupCall,
      onConnectGroupCall,
      onDisconnectGroupCall
    } = this.props
    return (
      <Container>
        <Main>
          {!pending && (
            <Paper className={classes.container}>
              <SectionTitle title="School data" />
              <h4>{title}</h4>
              <h5 className={classes.title}>Wonde</h5>
              <dl className={classes.data}>
                <dt>Status</dt>
                <dd>
                  <StatusColor color={statusColor} /> {status}
                </dd>
                <dt>Last sync.</dt>
                <dd>{lastSync}</dd>
                <dt>MIS</dt>
                <dd>{misWonde}</dd>
                <dt>Wonde school info</dt>
                <dd>{wondeSchoolInfo}</dd>
                <dt>Pulling parents</dt>
                <dd>
                  <FormCheckbox
                    checked={pullParents}
                    onChange={e => onPullParentsChange(id, e)}
                  />
                  <span className={classes.label}>Pull parents</span>
                </dd>
              </dl>
              <div
                className={classNames(classes.actions, {
                  [classes.pendingActions]: pendingActions
                })}>
                {status === 'finished' && (
                  <Button secondary tall action={() => onPull(id)}>
                    Pull
                  </Button>
                )}
                {status === 'not_connected' && (
                  <div className={classes.select}>
                    <Autocomplete
                      inputProps={{
                        id: 'schools-autocomplete',
                        className: classes.field,
                        placeholder: 'Ex: Holywell Primary School'
                      }}
                      wrapperStyle={{ position: 'relative' }}
                      value={this.state.value}
                      items={this.state.schools}
                      getItemValue={item => item.name}
                      onSelect={(value, item) => {
                        this.setState({
                          value,
                          schools: [item],
                          selectedSchool: item
                        })
                      }}
                      onChange={(event, value) => {
                        this.setState({ value, selectedSchool: null })
                        this.getSchools(value)
                      }}
                      renderMenu={children => (
                        <div className={classes.menu}>{children}</div>
                      )}
                      renderItem={(item, isHighlighted) => (
                        <div
                          className={classNames(classes.item, {
                            [classes.highlighted]: isHighlighted
                          })}
                          key={item.wonde_id}>
                          {`
                            ${item.name}
                            ${item.city ? ' (' + item.city + ')' : ''}
                            ${item.address ? ' (' + item.address + ')' : ''}
                            ${item.postcode ? ' (' + item.postcode + ')' : ''}
                            ${item.wonde_id ? ' (' + item.wonde_id + ')' : ''}
                          `}
                        </div>
                      )}
                    />
                    <br />
                    <Button
                      primary
                      tall
                      action={() =>
                        onConnect(id, this.state.selectedSchool.wonde_id)
                      }>
                      Connect
                    </Button>
                  </div>
                )}
                {(status === 'connected' ||
                  status === 'pending' ||
                  status === 'finished') && (
                  <Button danger tall action={() => onDisconnect(id)}>
                    Disconnect
                  </Button>
                )}
              </div>
              <h5 className={classes.title}>GroupCall</h5>
              <dl className={classes.data}>
                <dt>Status</dt>
                <dd>{enabledGroupCall ? 'On' : 'Off'}</dd>
                <dt>School Estab</dt>
                <dd>{schoolEstab || '-'}</dd>
                <dt>MIS</dt>
                <dd>{misGroupCall || '-'}</dd>
                <dt>Finished at</dt>
                <dd>{finishedAtGroupCall || '-'}</dd>
              </dl>
              <div
                className={classNames(classes.actions, {
                  [classes.pendingActions]: pendingActions
                })}>
                {enabledGroupCall && (
                  <Button secondary tall action={() => onPullGroupCall(id)}>
                    Pull
                  </Button>
                )}
                {!enabledGroupCall && (
                  <Button primary tall action={() => onConnectGroupCall(id)}>
                    Enable
                  </Button>
                )}
                {enabledGroupCall && (
                  <Button danger tall action={() => onDisconnectGroupCall(id)}>
                    Disable
                  </Button>
                )}
              </div>
            </Paper>
          )}
          {pending && <Spinner />}
        </Main>
      </Container>
    )
  }
}

export default injectSheet(styles)(SchoolData)
