import { connect } from 'react-redux'
import SchoolStudentsList from './students-list'
import { withRouter } from 'react-router-dom'
import {
  getStudents,
  areStudentsFetching,
  isStudentsFetched,
  getPagination
} from '../../../../stores/school-retirees/school-students.selectors'
import { fetchStudents } from '../../../../stores/school-retirees/school-students.actions'
import { errorHandler } from 'pobble-components/dist/error-handler'

const mapStateToProps = state => {
  return {
    noStudents: !getStudents(state),
    showLoading: areStudentsFetching(state),
    students: getStudents(state),
    isStudentsFetched: isStudentsFetched(state),
    pagination: getPagination(state)
  }
}

const mapDispatchToProps = dispatch => ({ dispatch })

const mergeProps = (stateProps, { dispatch }, ownProps) => ({
  ...stateProps,
  ...ownProps,
  onMount: () =>
    dispatch(fetchStudents(ownProps.match.params.id)).catch(errorHandler),
  onPageChange: ({ selected }) =>
    dispatch(fetchStudents(ownProps.match.params.id, { page: selected })).catch(
      errorHandler
    )
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(SchoolStudentsList)
)
