import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import StudentDetailsHead from './student-details-head'
import { getStudent } from '../../../../../stores/student-details/student-details.selectors'

const mapStateToProps = state => {
  const student = getStudent(state)
  const fullName = student.full_name === ' ' ? '' : student.full_name
  return {
    fullName: fullName || '-',
    displayName: student.display_name || '-'
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(StudentDetailsHead)
)
