const styles = ({ COLORS }) => ({
  button: {
    margin: '0 2px'
  },
  action: {
    textAlign: 'right'
  },
  loading: {
    opacity: '.5',
    pointerEvents: 'none',
    transition: '300ms opacity ease-in-out'
  },
  link: {
    padding: 0,
    display: 'block',
    height: 'auto',
    fontWeight: 'bold'
  },
  plainLink: {
    textDecoration: 'none',
    fontWeight: 'bold',
    color: COLORS.BLUE.NORMAL
  },
  inactive: {
    fontWeight: 'bold',
    color: COLORS.RED.NORMAL
  },
  highlighted: {
    fontWeight: 'bold !important',
    '& td': {
      color: '#555 !important'
    }
  },
  hasSchoolAlready: {
    display: 'none'
  }
})

export default styles
