import { connect } from 'react-redux'
import TeacherNotesModal from './teacher-notes-modal'
import { updateTeacherNotes } from '../../../../stores/teachers/teachers.actions'
import { isTeacherBeingUpdated } from '../../../../stores/teachers/teachers.selectors'
import { errorHandler } from 'pobble-components/dist/error-handler'

const mapStateToProps = (state, { id }) => ({
  showLoading: isTeacherBeingUpdated(state, id)
})

const mapDispatchToProps = dispatch => ({
  onUpdate: (teacherId, notes) =>
    dispatch(updateTeacherNotes(teacherId, notes)).catch(errorHandler)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeacherNotesModal)
