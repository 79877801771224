import { createSelector } from 'reselect'

const getWorksData = state => state.WorksStore.works
const getFilter = state => state.WorksStore.filter

const getServerFilterParams = createSelector([getFilter], filter => ({
  limit: filter.currentLimit,
  published: filter.published || null,
  unpublished: filter.unpublished || null,
  deleted: filter.deleted || null,
  student_id: filter.studentId || null,
  school_id: filter.schoolId || null,
  match_title: filter.workTitle || null,
  work_id: filter.workId || null,
  flagged: filter.flagged || null
}))

const getWorksResult = createSelector(
  [getWorksData],
  works => (!works ? null : works.result)
)

const getWorks = createSelector(
  [getWorksData],
  works => (!works ? null : works.entities.works)
)

const getWorksList = createSelector(
  [getWorksResult, getWorks],
  (result, works) => (!result ? null : result.map(id => works[id]))
)

const getFetchWorksRequest = state => state.WorksStore.fetchWorksRequest
const getFetchWorksRequestInitiator = createSelector(
  [getFetchWorksRequest],
  req => (!req.params ? null : req.params.initiator)
)
const getWork = (state, id) => getWorks(state)[id]

const lessWorksThanLimit = createSelector(
  [getWorksResult, getFilter],
  (works, filter) => (!works ? true : works.length < filter.currentLimit)
)

export {
  getWorksList,
  getWork,
  getFetchWorksRequest,
  getFetchWorksRequestInitiator,
  lessWorksThanLimit,
  getFilter,
  getServerFilterParams
}
