import React from 'react'
import styles from './form-label.styles'
import injectSheet from 'react-jss'

const FormLabel = ({ classes, children, htmlFor }) => (
  <label className={classes.label} htmlFor={htmlFor}>
    {children}
  </label>
)

export default injectSheet(styles)(FormLabel)
