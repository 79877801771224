import { connect } from 'react-redux'
import UserDetailsHead from './user-details-head'
import { getUser } from '../../../../../stores/user-details/user-details.selectors'

const mapStateToProps = state => {
  const user = getUser(state)
  const fullName = user.full_name === ' ' ? '' : user.full_name
  return {
    fullName: fullName || '-',
    displayName: user.display_name || '-',
    userEmail: user.email_identity && user.email_identity.email
  }
}

export default connect(
  mapStateToProps,
  null
)(UserDetailsHead)
