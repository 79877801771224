import React from 'react'
import styles from './user-details-component.styles'
import injectSheet from 'react-jss'
import UserDetailsHead from './user-details-head/user-details-head.container'
import UserDetailsForm from './user-details-form/user-details-form.container'
import UserDetailsEmail from './user-details-email/user-details-email.container'
import UserDetailsAvatarForm from './user-details-avatar-form/user-details-avatar-form.container'
import UserDetailsSchool from './user-details-school/user-details-school.container'
import UserDetailsChildren from './user-details-children/user-details-children.container'
import UserDetailsRoles from './user-details-roles/user-details-roles.container'
import UserDetailsActions from './user-details-actions/user-details-actions.container'
import UserDetailsSubs from './user-details-subs/user-details-subs.container'
import UserDetailsGroupSubs from './user-details-group-subs/user-details-group-subs.container'
import UserDetailsModSubs from './user-details-mod-subs/user-details-mod-subs.container'

const UserDetailsComponent = ({ classes }) => (
  <div>
    <UserDetailsHead />
    <UserDetailsForm />
    <UserDetailsEmail />
    <UserDetailsSubs />
    <UserDetailsModSubs />
    <UserDetailsGroupSubs />
    <UserDetailsAvatarForm />
    <UserDetailsSchool />
    <UserDetailsChildren />
    <UserDetailsRoles />
    <UserDetailsActions />
  </div>
)

export default injectSheet(styles)(UserDetailsComponent)
