import { apiClient } from 'pobble-components/dist/api'

const getComments = params =>
  apiClient.get(`v3/comments?order=desc`, { params })

const apiUpdateComment = (id, data) =>
  apiClient.put(`v3/comments/${id}?include=comment.details`, data)

const apiApproveComment = id =>
  apiClient.put(`v3/comments/${id}/approve?include=comment.details`)

const apiDeclineComment = id =>
  apiClient.put(`v3/comments/${id}/decline?include=comment.details`)

export { getComments, apiUpdateComment, apiApproveComment, apiDeclineComment }
