import React from 'react'
import injectSheet from 'react-jss'

import styles from './form-label.styles'

const FormLabel = ({ classes, label, htmlFor, required }) => (
  <label className={classes.label} htmlFor={htmlFor}>
    {label} {required && '*'}
  </label>
)

export default injectSheet(styles)(FormLabel)
