import { connect } from 'react-redux'
import WorksFilters from './works-filters'
import { getFilter } from '../../../../stores/works/works.selectors'
import {
  resetFilters,
  setSearchFilters
} from '../../../../stores/works/works.actions'

const mapStateToProps = state => {
  const filter = getFilter(state)
  return {
    studentId: filter.studentId,
    published: filter.published,
    workTitle: filter.workTitle,
    workId: filter.workId,
    schoolId: filter.schoolId,
    unpublished: filter.unpublished,
    deleted: filter.deleted,
    flagged: filter.flagged
  }
}

const mapDispatchToProps = dispatch => ({
  onFiltersUpdate: values => dispatch(setSearchFilters(values)),
  onClearFilters: () => dispatch(resetFilters())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorksFilters)
