import { connect } from 'react-redux'
import Sidebar from './sidebar'
import { getPendingCommentsNumber } from '../../stores/comments/comments.selectors'
import {
  getPendingTeachersNumber,
  getPendingTeachersWithoutSchoolNumber
} from '../../stores/teachers/teachers.selectors'
import { getPresentationsNumber } from '../../stores/presentations/presentations.selectors'
import { getSchoolsNumber } from '../../stores/schools/schools.selectors'
import { fetchTeachers } from '../../stores/teachers/teachers.actions.js'
import { fetchComments } from '../../stores/comments/comments.actions.js'
import { fetchPresentations } from '../../stores/presentations/presentations.actions'
import { withRouter } from 'react-router'
import { withMe } from 'pobble-components/dist/pobble-user'

import { fetchReviewSchools } from '../../stores/schools/schools.actions'

const mapStateToProps = (state, { me }) => ({
  pendingComments: getPendingCommentsNumber(state),
  pendingTeachers: getPendingTeachersWithoutSchoolNumber(state),
  pendingPresentations: getPresentationsNumber(state),
  pendingSchools: getSchoolsNumber(state),
  adminPolicies: me.account.admin_policy
})

const mapDispatchToProps = dispatch => ({
  onMount: () => {
    dispatch(fetchComments())
    dispatch(fetchPresentations())
    dispatch(fetchReviewSchools())
    return dispatch(fetchTeachers())
  }
})

export default withMe(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar))
)
