import { connect } from 'react-redux'
import UserDetailsModSubs from './user-details-mod-subs'
import {
  getUser,
  getUpdateAccountRequest
} from '../../../../../stores/user-details/user-details.selectors'
import { format } from 'date-fns'
import { withRouter } from 'react-router'
import { assignModerationAddon } from '../../../../../stores/user-details/user-details.actions'
import { withMe } from 'pobble-components/dist/pobble-user'
import { toast } from 'pobble-components/dist/toast'

const mapStateToProps = (state, { me }) => {
  const user = getUser(state)
  const updateAccountDetails = getUpdateAccountRequest(state)
  return {
    pending: updateAccountDetails.pending,
    subsPlan: user.individual_subscription && user.individual_subscription.plan,
    subsStatus:
      user.individual_subscription && user.individual_subscription.status,
    subsEnd:
      user.individual_subscription &&
      user.individual_subscription.period_end &&
      format(user.individual_subscription.period_end, 'YYYY-MM-DD'),
    subsAddon:
      user.individual_subscription &&
      user.addons &&
      user.addons[0] &&
      user.addons[0].length
  }
}

const mapDispatchToProps = (dispatch, { match, me }) => ({
  onSubmit: values => {
    const data = {
      end_time: values.endDate
    }
    dispatch(assignModerationAddon(match.params.id, data))
      .then(toast.success('Add-on successfully updated'))
      .catch(e =>
        toast.error(
          (e.response.data.errors && e.response.data.errors[0].msg) ||
            'Something went wrong'
        )
      )
  }
})

export default withMe(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(UserDetailsModSubs))
)
