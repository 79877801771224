import { connect } from 'react-redux'
import SchoolEntry from './school-entry'
import { getUserSchools } from '../../../../../../../stores/user-details/user-details.selectors'
import {
  promoteTeacher,
  demoteTeacher,
  deleteTeacher,
  declineTeacher
} from '../../../../../../../stores/user-details/user-details.actions'
import { withRouter } from 'react-router'

const mapStateToProps = (state, { id }) => {
  const entry = getUserSchools(state).entries[id]
  return {
    role: entry.role,
    schoolId: entry.school.id,
    schoolTitle: entry.school.title,
    accountType:
      entry.role === 'teacher'
        ? 'Teacher'
        : entry.role === 'pending_teacher'
          ? 'Pending teacher'
          : '',
    disableSchoolLead: entry.role !== 'teacher',
    schoolLeader: entry.school_leader || false
  }
}

const mapDispatchToProps = (dispatch, { match }) => ({ dispatch })

const mergeProps = (stateProps, { dispatch }, ownProps) => ({
  ...ownProps,
  ...stateProps,
  onSchoolLeadChange: e =>
    e.target.checked
      ? dispatch(promoteTeacher(ownProps.match.params.id))
      : dispatch(demoteTeacher(ownProps.match.params.id)),
  onDelete: () =>
    stateProps.role === 'teacher'
      ? dispatch(deleteTeacher(stateProps.schoolId, ownProps.match.params.id))
      : dispatch(declineTeacher(ownProps.match.params.id))
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(SchoolEntry)
)
