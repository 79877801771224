const styles = () => ({
  container: {
    textAlign: 'center',
    margin: '20px 20px 0 0'
  },
  nothing: {
    padding: '10px 0'
  },
  image: {
    display: 'block'
  }
})

export default styles
