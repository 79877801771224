const styles = {
  navigation: {
    margin: '20px 0 30px'
  },
  navigationTitle: {
    fontWeight: 'bold',
    padding: '0 20px'
  },
  navigationLinks: {
    listStyle: 'none',
    padding: '0'
  }
}

export default styles
