import React from 'react'
import styles from './section.styles'
import injectSheet from 'react-jss'
import classNames from 'classnames'

const Section = ({ classes, children, title, pending, renderHeader }) => (
  <div
    className={classNames(classes.container, { [classes.pending]: pending })}>
    <div className={classes.head}>
      {title && <h3 className={classes.title}>{title}</h3>}
      {renderHeader && renderHeader()}
    </div>
    <div className={classes.holder}>{children}</div>
  </div>
)

export default injectSheet(styles)(Section)
