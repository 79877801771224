import React from 'react'
import styles from './user-details-subs.styles'
import injectSheet from 'react-jss'
import Section from '../../../../shared/details/section/section'
import FormRow from '../../../../shared/details/form-row/form-row'
import FormCell from '../../../../shared/details/form-cell/form-cell'
import FormLabel from '../../../../shared/details/form-label/form-label'
import CellText from '../../../../shared/details/cell-text/cell-text'
import FormInput from 'pobble-components/dist/form-elements/form-input'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Button from 'pobble-components/dist/button'
import FormSelect from 'pobble-components/dist/form-elements/form-select'
import classNames from 'classnames'

const requiredMsg = 'This field should not be empty'

const UserDetailsSubs = ({
  classes,
  pending,
  plan,
  endDate,
  subsPlan,
  subsPlanMkt,
  subsEnd,
  subsStatus,
  onSubmit
}) => (
  <Formik
    onSubmit={onSubmit}
    initialValues={{
      plan: subsPlan || '',
      endDate: subsEnd || ''
    }}
    validationSchema={Yup.object().shape({
      plan: Yup.string().required(requiredMsg),
      endDate: Yup.date('').required(requiredMsg)
    })}>
    {formikProps => {
      const {
        values,
        handleBlur,
        handleChange,
        handleSubmit,
        isValid,
        errors,
        touched
      } = formikProps
      return (
        <form
          onSubmit={handleSubmit}
          className={classNames(classes.form, { [classes.pending]: pending })}>
          <Section
            title="Subscriptions"
            renderHeader={() => (
              <Button
                className={classes.submitButton}
                disabled={!isValid}
                type="submit"
                tall>
                Edit subscription
              </Button>
            )}>
            <FormRow>
              <FormCell>
                <FormLabel>Subscription plan</FormLabel>
                <CellText>
                  {subsPlan || '-'} {subsPlanMkt}
                </CellText>
              </FormCell>
              <FormCell>
                <FormLabel>Status</FormLabel>
                <CellText>{subsStatus || '-'}</CellText>
              </FormCell>
              <FormCell>
                <FormLabel>End date</FormLabel>
                <CellText>{subsEnd || '-'}</CellText>
              </FormCell>
            </FormRow>
            <FormRow>
              <FormCell>
                <FormLabel>Assign a Subscription</FormLabel>
                <FormSelect
                  name="plan"
                  value={values.plan}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  validationError={touched.plan && errors.plan}>
                  <option value="">-</option>
                  <option value="free-trial">Free Trial</option>
                  <option value="pobble-bronze">Bronze</option>
                  <option value="pobble-silver">Silver (T&L)</option>
                  <option value="pobble-gold">Gold</option>
                </FormSelect>
              </FormCell>
              <FormCell>
                <FormLabel>Assign an end date</FormLabel>
                <FormInput
                  name="endDate"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.endDate}
                  placeholder={'Ex: 2020-08-30'}
                  validationError={touched.endDate && errors.endDate}
                />
              </FormCell>
            </FormRow>
          </Section>
        </form>
      )
    }}
  </Formik>
)

export default injectSheet(styles)(UserDetailsSubs)
