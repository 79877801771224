import React from 'react'
import styles from './remove-avatar.styles'
import injectSheet from 'react-jss'
import Button from 'pobble-components/dist/button'

const RemoveAvatar = ({ classes, onClick }) => (
  <Button tall dangerSecondary action={onClick}>
    Delete avatar
  </Button>
)

export default injectSheet(styles)(RemoveAvatar)
