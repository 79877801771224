import { apiClient } from 'pobble-components/dist/api'

export const apiGetAccounts = params =>
  apiClient.get('v3/admin/accounts', { params })

export const apiUpdateAccountDetails = (id, data, params) =>
  apiClient.put(`v3/admin/accounts/${id}`, data, { params })

export const apiUpdateAccountEmail = (id, data, params) =>
  apiClient.put(`v3/email_identities/${id}/request_email_change`, data, {
    params
  })

export const apiPromotePobbler = (id, data) =>
  apiClient.post(`v3/admin/accounts/${id}/pobblers`, data)

export const apiDemotePobbler = id =>
  apiClient.delete(`v3/admin/accounts/${id}/pobblers`)

export const apiRedactAccount = id =>
  apiClient.put(`v3/admin/accounts/${id}/redact`)

export const apiRedactStudentAccount = id =>
  apiClient.put(`v3/admin/students/${id}/redact`)

export const apiUploadSuccessCriteria = file => {
  const formData = new FormData()
  formData.append('file', file)
  return apiClient.post(`/v3/admin/success_criteria/upload`, formData)
}
