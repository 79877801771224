import { createReducer } from 'pobble-components/dist/redux-helpers'
import { normalize, schema } from 'normalizr'

const normalizeStudents = data => {
  const student = new schema.Entity(
    'students',
    {},
    { idAttribute: student => student.account.id }
  )
  return normalize(data, [student])
}

let defaultState = {
  loading: false,
  studentBeingAdded: false,
  studentsFetched: false,
  studentsUpdating: {},
  school: {
    id: null,
    title: null
  },
  students: {
    result: [],
    entities: { students: {} }
  }
}

const SchoolRetireesStore = createReducer(defaultState, {
  'SCHOOL_RETIREES:FETCHING_REQUEST': state => ({
    ...state,
    loading: true
  }),
  'SCHOOL_RETIREES:FETCHING_REQUEST_SUCCESS': (state, { response }) => {
    return {
      ...state,
      loading: false,
      pagination: response.data.school.retired_student_types_pagination,
      students: normalizeStudents(response.data.school.retired_student_types),
      school: {
        id: response.data.school.id,
        title: response.data.school.title
      },
      studentsFetched: true
    }
  },
  'SCHOOL_RETIREES:FETCHING_REQUEST_ERROR': state => ({
    ...state,
    loading: false
  })
})

export default SchoolRetireesStore
