import React from 'react'
import styles from './organisation-creation.styles'
import injectSheet from 'react-jss'
import Container from '../../shared/container/container'
import Main from '../../shared/main/main'
import Paper from 'pobble-components/dist/paper'
import OrganisationCreationForm from './organisation-creation-form/organisation-creation-form.container'

const OrganisationCreation = ({ classes }) => (
  <Container>
    <Main>
      <Paper className={classes.paper}>
        <OrganisationCreationForm />
      </Paper>
    </Main>
  </Container>
)

export default injectSheet(styles)(OrganisationCreation)
