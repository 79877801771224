import React, { Component } from 'react'
import injectSheet from 'react-jss'
import styles from './school-details.styles.js'

import Paper from 'pobble-components/dist/paper'

import Container from '../../shared/container/container.jsx'
import Main from '../../shared/main/main.jsx'

import SchoolDetailsForm from './school-details-form/school-details-form.container'
import SchoolDetailsSub from './school-details-sub/school-details-sub.container'
import SchoolDetailsOrgSub from './school-details-org-sub/school-details-org-sub.container'
import SchoolDetailsGroupAddon from './school-details-group-addon/school-details-group-addon.container'
import SchoolDetailsSubPlan from './school-details-sub-plan/school-details-sub-plan.container'
import SchoolOrganisations from './school-organisations/school-organisations.container'
import SectionTitle from '../../shared/section-title/section-title.jsx'

class SchoolDetails extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    const { classes } = this.props
    return (
      <Container>
        <Main>
          <Paper className={classes.container}>
            <SectionTitle title="School details" />
            <SchoolDetailsForm />
            <SchoolDetailsSub />
            <SchoolDetailsOrgSub />
            <SchoolDetailsGroupAddon />
            <SchoolDetailsSubPlan />
            <SchoolOrganisations />
          </Paper>
        </Main>
      </Container>
    )
  }
}

export default injectSheet(styles)(SchoolDetails)
