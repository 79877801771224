import React, { Component } from 'react'
import styles from './users-filter.styles'
import injectSheet from 'react-jss'
import Cell from './cell/cell'
import FormInput from 'pobble-components/dist/form-elements/form-input'
import Button from 'pobble-components/dist/button'
import debounce from 'lodash/debounce'
import every from 'lodash/every'
import isEmpty from 'lodash/isEmpty'

const DEBOUNCE_DELAY = 500

class UsersFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: props.firstName,
      lastName: props.lastName,
      displayName: props.displayName,
      email: props.email
    }
  }
  areFiltersEmpty = () => every(this.state, isEmpty)
  onClearFilters = () => {
    const filters = {
      firstName: '',
      lastName: '',
      displayName: '',
      email: ''
    }
    this.setState(filters)
    this.props.onFiltersUpdate(filters)
  }
  debouncedFiltersUpdate = debounce(
    () => this.props.onFiltersUpdate(this.state),
    DEBOUNCE_DELAY
  )
  handleChange = (value, key, callback) => {
    this.setState({ [key]: value })
    this.debouncedFiltersUpdate()
  }
  render() {
    const { firstName, lastName, displayName, email } = this.state
    return (
      <tr>
        <Cell>
          <FormInput
            value={displayName}
            placeholder="Search"
            onChange={e =>
              this.handleChange(
                e.target.value,
                'displayName',
                'onDisplayNameChange'
              )
            }
          />
        </Cell>
        <Cell>
          <FormInput
            value={firstName}
            placeholder="Search"
            onChange={e =>
              this.handleChange(
                e.target.value,
                'firstName',
                'onFirstNameChange'
              )
            }
          />
        </Cell>
        <Cell>
          <FormInput
            value={lastName}
            placeholder="Search"
            onChange={e =>
              this.handleChange(e.target.value, 'lastName', 'onLastNameChange')
            }
          />
        </Cell>
        <Cell>
          <FormInput
            value={email}
            placeholder="Search"
            onChange={e =>
              this.handleChange(e.target.value, 'email', 'onEmailChange')
            }
          />
        </Cell>
        <Cell cellProps={{ colSpan: 6 }}>
          <Button
            disabled={this.areFiltersEmpty()}
            action={this.onClearFilters}
            tall
            wide
            secondary>
            Clear all filters
          </Button>
        </Cell>
      </tr>
    )
  }
}

export default injectSheet(styles)(UsersFilter)
