import React from 'react'
import injectSheet from 'react-jss'

import styles from './form-field.styles'

const FormField = ({ classes, children }) => (
  <div className={classes.field}>{children}</div>
)

export default injectSheet(styles)(FormField)
