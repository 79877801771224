import React from 'react'
import styles from './permission.styles'
import injectSheet from 'react-jss'
import Tooltip from 'pobble-components/dist/tooltip'
import CircleStatus from 'pobble-components/dist/circle-status'

const permissionMap = {
  approved: {
    status: true,
    tooltip: 'Approved'
  },
  declined: {
    status: false,
    tooltip: 'Declined'
  },
  disapproved: {
    status: false,
    tooltip: 'Declined'
  },
  pending: {
    status: 'pending',
    tooltip: 'Pending'
  }
}

const Permission = ({ classes, children }) => (
  <Tooltip content={permissionMap[children].tooltip}>
    <CircleStatus status={permissionMap[children].status} />
  </Tooltip>
)

export default injectSheet(styles)(Permission)
