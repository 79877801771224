import React, { Component } from 'react'
import injectSheet from 'react-jss'

import styles from './students-filter.styles.js'

import FormInput from 'pobble-components/dist/form-elements/form-input'

class SchoolStudentsFilter extends Component {
  render() {
    const { classes, searchValue, onSearchChange } = this.props
    return (
      <div className={classes.container}>
        <strong className={classes.title}>Student</strong>
        <FormInput
          onChange={onSearchChange}
          value={searchValue}
          placeholder="Last name, first name"
        />
      </div>
    )
  }
}

export default injectSheet(styles)(SchoolStudentsFilter)
