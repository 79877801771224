import { createSelector } from 'reselect'

const getFetchStudentRequest = state => state.StudentDetails.fetchStudentRequest
const getFetchStudentDocumentsRequest = state =>
  state.StudentDetails.fetchStudentDocumentsRequest

const getStudent = state => state.StudentDetails.student

const getStudentAvatar = createSelector(
  [getStudent],
  student => (!student ? null : student.avatar)
)

const getNormalizedStudentDocuments = state =>
  state.StudentDetails.student.student_type &&
  state.StudentDetails.student.student_type.documents

const getStudentDocumentsResult = createSelector(
  [getNormalizedStudentDocuments],
  documents => (!documents ? null : documents.result)
)

const getStudentDocuments = createSelector(
  [getNormalizedStudentDocuments],
  documents => (!documents ? null : documents.entities.documents)
)

export {
  getFetchStudentRequest,
  getStudentAvatar,
  getStudent,
  getFetchStudentDocumentsRequest,
  getStudentDocuments,
  getStudentDocumentsResult
}
