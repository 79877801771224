import React, { Component } from 'react'
import styles from './student-details-documents.styles'
import injectSheet from 'react-jss'
import DocumentsTable from './documents-table/documents-table.container'
import Section from '../../../../shared/details/section/section'

class Documents extends Component {
  componentDidMount() {
    this.props.onMount()
  }
  render() {
    const { classes, permissions, pendingPermissions } = this.props
    return (
      <Section title="Permission documents">
        {!pendingPermissions &&
          permissions && (
            <div>
              {!!permissions.length && (
                <DocumentsTable permissions={permissions} />
              )}
              {!permissions.length && (
                <p className={classes.noDocuments}>No documents yet</p>
              )}
            </div>
          )}
      </Section>
    )
  }
}

export default injectSheet(styles)(Documents)
