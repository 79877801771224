import React from 'react'
import styles from './status-color.styles'
import injectSheet from 'react-jss'
import classNames from 'classnames'

const StatusColor = ({ classes, color }) => (
  <span className={classNames(classes.statusColor, classes[color])} />
)

export default injectSheet(styles)(StatusColor)
