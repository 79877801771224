import React from 'react'
import styles from './user-details-roles.styles'
import injectSheet from 'react-jss'
import Section from '../../../../shared/details/section/section'
import FormCheckbox from 'pobble-components/dist/form-elements/form-checkbox'

const UserDetailsRoles = ({
  classes,
  pobbler,
  admin,
  localAuthorityManager,
  onPobblerChange,
  onAdminChange,
  onLocalAuthorityManagerChange,
  disableAdmin,
  pending
}) => (
  <Section title="Other roles" pending={pending}>
    <div className={classes.holder}>
      <label>
        <FormCheckbox
          checked={localAuthorityManager}
          onChange={onLocalAuthorityManagerChange}
        />
        <span>Local authority manager</span>
      </label>
      <label>
        <FormCheckbox checked={pobbler} onChange={onPobblerChange} />
        <span>Pobbler</span>
      </label>
      <label>
        <FormCheckbox
          checked={admin}
          disabled={disableAdmin}
          onChange={onAdminChange}
        />
        <span>Admin</span>
      </label>
    </div>
  </Section>
)

export default injectSheet(styles)(UserDetailsRoles)
