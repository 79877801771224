import React from 'react'
import styles from './form-row.styles'
import injectSheet from 'react-jss'
import classNames from 'classnames'

const FormRow = ({ classes, children, noBorder }) => (
  <div
    className={classNames(classes.container, { [classes.noBorder]: noBorder })}>
    <div className={classes.holder}>{children}</div>
  </div>
)

export default injectSheet(styles)(FormRow)
