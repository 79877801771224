import { createReducer } from 'pobble-components/dist/redux-helpers'

let defaultState = {
  loading: false,
  work: {}
}

const workStartsUpdating = state => ({ ...state, workBeingUpdated: true })
const workStopsUpdating = state => ({ ...state, workBeingUpdated: false })

const WorkDetails = createReducer(defaultState, {
  'WORK:FETCHING_REQUEST': state => ({
    ...state,
    loading: true
  }),
  'WORK:FETCHING_REQUEST_SUCCESS': (state, { response }) => ({
    ...state,
    loading: false,
    work: response.data.work
  }),
  'WORK:UPDATE_REQUEST': workStartsUpdating,
  'WORK:UPDATE_REQUEST_SUCCESS': (state, { response }) => ({
    ...state,
    workBeingUpdated: false,
    work: {
      ...state.work,
      title: response.data.work.title,
      description: response.data.work.description
    }
  }),
  'WORK:UPDATE_REQUEST_ERROR': workStopsUpdating,
  'WORK:FETCHING_REQUEST_ERROR': state => ({
    ...state,
    loading: false
  }),
  'WORK:DELETE_MEDIA_REQUEST': state => ({
    ...state,
    pendingMedia: true
  }),
  'WORK:DELETE_MEDIA_REQUEST_SUCCESS': (state, { params, response }) => ({
    ...state,
    pendingMedia: false,
    work: {
      ...state.work,
      media: state.work.media.filter(media => media.id !== params.mediaId)
    }
  }),
  'WORK:DELETE_MEDIA_REQUEST_ERROR': state => ({
    ...state,
    pendingMedia: false
  }),
  'WORK:PUBLISH_REQUEST': workStartsUpdating,
  'WORK:PUBLISH_REQUEST_SUCCESS': (state, { response }) => ({
    ...state,
    workBeingUpdated: false,
    work: {
      ...state.work,
      published_at: response.data.work.published_at,
      status: response.data.work.status
    }
  }),
  'WORK:PUBLISH_REQUEST_ERROR': workStopsUpdating,
  'WORK:UNPUBLISH_REQUEST': workStartsUpdating,
  'WORK:UNPUBLISH_REQUEST_SUCCESS': (state, { response }) => ({
    ...state,
    workBeingUpdated: false,
    work: {
      ...state.work,
      published_at: response.data.work.published_at,
      status: response.data.work.status
    }
  }),
  'WORK:UNPUBLISH_REQUEST_ERROR': workStopsUpdating,
  'WORK:FLAG_REQUEST': workStartsUpdating,
  'WORK:FLAG_REQUEST_SUCCESS': (state, { response }) => ({
    ...state,
    workBeingUpdated: false,
    work: {
      ...state.work,
      flag: response.data.work.flag,
      status: 'flag'
    }
  }),
  'WORK:FLAG_REQUEST_ERROR': workStopsUpdating,
  'WORK:UNFLAG_REQUEST': workStartsUpdating,
  'WORK:UNFLAG_REQUEST_SUCCESS': (state, { response }) => ({
    ...state,
    workBeingUpdated: false,
    work: {
      ...state.work,
      flag: response.data.work.flag,
      status: 'unpublished'
    }
  }),
  'WORK:UNFLAG_REQUEST_ERROR': workStopsUpdating
})

export default WorkDetails
