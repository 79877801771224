export default ({ COLORS }) => ({
  link: {
    color: COLORS.BLUE.NORMAL,
    textDecoration: 'none',
    fontWeight: 'bold'
  },
  action: {
    textAlign: 'right'
  }
})
