import { createSelector } from 'reselect'

const getPresentationsData = state => state.PresentationsStore.presentations
const getFilter = state => state.PresentationsStore.filter

const getServerFilterParams = createSelector([getFilter], filter => ({
  limit: filter.currentLimit
}))

const getPresentationsResult = createSelector(
  [getPresentationsData],
  presentations => (!presentations ? null : presentations.result)
)

const getPresentations = createSelector(
  [getPresentationsData],
  presentations =>
    !presentations ? null : presentations.entities.presentations
)

const getPresentationsList = createSelector(
  [getPresentationsResult, getPresentations],
  (result, presentations) =>
    !result ? null : result.map(id => presentations[id])
)

const getFetchPresentationsRequest = state =>
  state.PresentationsStore.fetchPresentationsRequest
const getFetchPresentationsRequestInitiator = createSelector(
  [getFetchPresentationsRequest],
  req => (!req.params ? null : req.params.initiator)
)
const getPresentation = (state, id) => getPresentations(state)[id]

const getPresentationsNumber = createSelector(
  [getPresentationsResult],
  presentations => presentations && presentations.length
)

const lessPresentationsThanLimit = createSelector(
  [getPresentationsResult, getFilter],
  (presentations, filter) =>
    !presentations ? true : presentations.length < filter.currentLimit
)

export {
  getPresentationsList,
  getPresentation,
  getFetchPresentationsRequest,
  getFetchPresentationsRequestInitiator,
  lessPresentationsThanLimit,
  getFilter,
  getPresentationsNumber,
  getServerFilterParams
}
