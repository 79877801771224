import { connect } from 'react-redux'
import PresentationsList from './presentations-list'
import {
  getPresentationsList,
  lessPresentationsThanLimit,
  getFetchPresentationsRequest,
  getFetchPresentationsRequestInitiator
} from '../../../../stores/presentations/presentations.selectors'

const mapStateToProps = state => {
  const req = getFetchPresentationsRequest(state)
  const initiator = getFetchPresentationsRequestInitiator(state)
  return {
    presentations: getPresentationsList(state) || [],
    pending: req.pending && initiator === 'SEARCH',
    hideLoadMore:
      !(req.pending && initiator === 'LOAD_MORE') &&
      lessPresentationsThanLimit(state)
  }
}

export default connect(
  mapStateToProps,
  null
)(PresentationsList)
