import { connect } from 'react-redux'
import SchoolData from './school-data'
import { withRouter } from 'react-router-dom'
import {
  getSchoolData,
  getFetchSchoolDataRequest,
  getConnectSchoolRequest,
  getDisconnectSchoolRequest,
  getPullSchoolRequest
} from '../../../stores/school-data/school-data.selectors'
import {
  fetchSchoolData,
  connectToWonde,
  disconnectFromWonde,
  pullFromWonde,
  updateParentsPulling,
  searchWondeSchoolsToRequest,
  pullFromGroupCall,
  connectToGroupCall,
  disconnectFromGroupCall
} from '../../../stores/school-data/school-data.actions'
import dateFns from 'date-fns'
import { errorHandler } from 'pobble-components/dist/error-handler'
import { toast } from 'pobble-components/dist/toast'
import { getErrorInfo } from 'pobble-components/dist/utils'

const mapStateToProps = state => {
  const school = getSchoolData(state)
  const pendingActions =
    getConnectSchoolRequest(state).pending ||
    getDisconnectSchoolRequest(state).pending ||
    getPullSchoolRequest(state).pending
  return {
    pendingActions,
    pending: getFetchSchoolDataRequest(state).pending,
    title: school && school.title,
    id: school && school.id,
    status: school && school.wonde && school.wonde.status,
    statusColor: school && school.wonde && school.wonde.color,
    lastSync:
      (school &&
        school.wonde &&
        school.wonde.last_update_at &&
        dateFns.format(school.wonde.last_update_at, 'MM/DD/YYYY H:s')) ||
      '-',
    misWonde: (school && school.wonde && school.wonde.mis) || '-',
    wondeSchoolInfo:
      (school && school.wonde && school.wonde.wonde_school_info) || '-',
    pullParents: school && school.wonde && school.wonde.pull_parents,
    schoolEstab: school && school.groupcall && school.groupcall.school_estab,
    misGroupCall: school && school.groupcall && school.groupcall.mis,
    finishedAtGroupCall:
      school && school.groupcall && school.groupcall.finished_at,
    canPullGroupCall: school && school.groupcall && school.groupcall.can_pull,
    enabledGroupCall: school && school.groupcall && school.groupcall.on
  }
}

const mapDispatchToProps = (dispatch, { match }) => ({
  onMount: () => dispatch(fetchSchoolData(match.params.id)).catch(errorHandler),
  onConnect: (schoolId, wondeId) =>
    dispatch(connectToWonde(schoolId, wondeId)).catch(errorHandler),
  onDisconnect: schoolId =>
    dispatch(disconnectFromWonde(schoolId)).catch(errorHandler),
  onPull: schoolId =>
    dispatch(pullFromWonde(schoolId)).catch(e => {
      if (getErrorInfo(e).ref === 'e0026') {
        toast.warn('Wonde - School is in invalid state to pull')
      } else {
        errorHandler(e)
      }
    }),
  onPullParentsChange: (schoolId, e) => {
    return dispatch(
      updateParentsPulling(schoolId, { wonde_pull_parents: e.target.checked })
    ).catch(errorHandler)
  },
  onSearchChange: term =>
    dispatch(searchWondeSchoolsToRequest(term)).catch(errorHandler),
  onPullGroupCall: schoolId =>
    dispatch(pullFromGroupCall(schoolId)).catch(errorHandler),
  onDisconnectGroupCall: schoolId =>
    dispatch(disconnectFromGroupCall(schoolId)).catch(errorHandler),
  onConnectGroupCall: schoolId =>
    dispatch(connectToGroupCall(schoolId)).catch(errorHandler)
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SchoolData)
)
