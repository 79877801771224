import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Presentation from './presentation'
import { getPresentation } from '../../../../../stores/presentations/presentations.selectors'
import {
  approvePresentation,
  declinePresentation
} from '../../../../../stores/presentations/presentations.actions'
import { errorHandler } from 'pobble-components/dist/error-handler'

import dateFns from 'date-fns'

const mapStateToProps = (state, { id }) => {
  const presentation = getPresentation(state, id)
  return {
    title: presentation.title,
    createdAt:
      (presentation &&
        dateFns.format(presentation.created_at, 'MM/DD/YYYY H:s')) ||
      '',
    approved: presentation.approved,
    lessonId: presentation.id,
    fullName: (presentation.account && presentation.account.full_name) || '-'
  }
}

const mapDispatchToProps = (dispatch, { id }) => ({
  onPresentationApprove: id =>
    dispatch(approvePresentation(id)).catch(errorHandler),
  onPresentationDecline: id =>
    dispatch(declinePresentation(id)).catch(errorHandler)
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Presentation)
)
