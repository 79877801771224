const styles = ({ COLORS }) => ({
  button: {
    margin: '0 2px'
  },
  action: {
    textAlign: 'right'
  },
  loading: {
    opacity: '.5',
    pointerEvents: 'none',
    transition: '300ms opacity ease-in-out'
  },
  body: {
    maxWidth: '200px',
    wordBreak: 'break-all',
    textAlign: 'left'
  },
  link: {
    textDecoration: 'none',
    fontWeight: 'bold',
    color: COLORS.BLUE.NORMAL
  },
  studentIcon: {
    marginLeft: '4px',
    color: '#a5a5a5'
  }
})

export default styles
