const styles = () => ({
  container: {
    border: '1px solid #e5e5e5'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px'
  },
  link: {
    textDecoration: 'none'
  }
})

export default styles
