import React, { Component, Fragment } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import styles from './app.styles'
import injectSheet from 'react-jss'
import ApproveComments from '../pages/approve-comments/approve-comments.jsx'
import ApproveTeachers from '../pages/approve-teachers/approve-teachers.jsx'
import ApprovePresentations from '../pages/approve-presentations/presentations.container'
import Schools from '../pages/schools/schools.jsx'
import ReviewSchools from '../pages/review-schools/review-schools.jsx'
import Users from '../pages/users/users.container'
import UserDetails from '../pages/user-details/user-details.container'
import SchoolDetails from '../pages/school-details/school-details.jsx'
import SchoolTeachers from '../pages/school-teachers/school-teachers.container'
import SchoolStudents from '../pages/school-students/school-students.container'
import SchoolRetirees from '../pages/school-retirees/school-students.container'
import StudentDetails from '../pages/student-details/student-details.container'
import SchoolData from '../pages/school-data/school-data.container'
import Works from '../pages/works/works.container'
import WorkDetails from '../pages/work-details/work-details'
import Campaigns from '../pages/campaigns/campaigns.container'
import CampaignCreation from '../pages/campaign-creation/campaign-creation'
import CampaignEdition from '../pages/campaign-edition/campaign-edition.container'
import Organisations from '../pages/organisations/organisations.container'
import OrganisationCreation from '../pages/organisation-creation/organisation-creation'
import OrganisationEdition from '../pages/organisation-edition/organisation-edition.container'
import Header from 'pobble-components/dist/pobble-header'
import SidebarContainer from '../sidebar/sidebar.container'
import Container from '../shared/container/container.jsx'
import { UserProvider, UserInspector } from 'pobble-components/dist/pobble-user'
import EnvironmentLine from 'pobble-components/dist/environment-line'
import { ToastContainer } from 'pobble-components/dist/toast'
import Other from '../pages/other/other'
import PageNotFound from 'pobble-components/dist/page-not-found'

class App extends Component {
  render() {
    const APP_ROOT = process.env.REACT_APP_ENV
      ? 'https://' +
        (process.env.REACT_APP_ENV === 'stg' ? 'stg-' : '') +
        'app.pobble.com'
      : ''
    const { fetchMe } = this.props
    return (
      <UserProvider fetchMe={fetchMe}>
        <Router basename="/">
          <Fragment>
            <EnvironmentLine env={window.location.host.split('-')[0]} />
            <Header appUrl={APP_ROOT} />
            <UserInspector haveRights={me => me.account.policy.can_see_admin}>
              <Container>
                <SidebarContainer />
                <Switch>
                  <Route exact path="/" component={ApproveComments} />
                  <Route
                    exact
                    path="/approve-comments"
                    component={ApproveComments}
                  />
                  <Route
                    exact
                    path="/approve-teachers"
                    component={ApproveTeachers}
                  />
                  <Route
                    exact
                    path="/approve-presentations"
                    component={ApprovePresentations}
                  />
                  <Route exact path="/users" component={Users} />
                  <Route exact path="/users/:id" component={UserDetails} />
                  <Route
                    exact
                    path="/review-schools"
                    component={ReviewSchools}
                  />
                  <Route exact path="/schools" component={Schools} />
                  <Route exact path="/schools/:id" component={SchoolDetails} />
                  <Route
                    exact
                    path="/schools/:id/teachers"
                    component={SchoolTeachers}
                  />
                  <Route
                    exact
                    path="/schools/:id/students"
                    component={SchoolStudents}
                  />
                  <Route
                    exact
                    path="/schools/:id/retired-students"
                    component={SchoolRetirees}
                  />
                  <Route
                    exact
                    path="/schools/:schoolId/students/:id"
                    component={StudentDetails}
                  />
                  <Route
                    exact
                    path="/schools/:schoolId/retired-students/:id"
                    component={StudentDetails}
                  />
                  <Route
                    exact
                    path="/schools/:id/data"
                    component={SchoolData}
                  />
                  <Route exact path="/works" component={Works} />
                  <Route exact path="/works/:id" component={WorkDetails} />
                  <Route exact path="/campaigns" component={Campaigns} />
                  <Route
                    exact
                    path="/campaigns/new"
                    component={CampaignCreation}
                  />
                  <Route
                    exact
                    path="/campaigns/:id"
                    component={CampaignEdition}
                  />
                  <Route
                    exact
                    path="/organisations"
                    component={Organisations}
                  />
                  <Route
                    exact
                    path="/organisations/new"
                    component={OrganisationCreation}
                  />
                  <Route
                    exact
                    path="/organisations/:id"
                    component={OrganisationEdition}
                  />
                  <Route exact path="/other" component={Other} />
                  <Route component={PageNotFound} />
                </Switch>
              </Container>
            </UserInspector>
            <ToastContainer />
          </Fragment>
        </Router>
      </UserProvider>
    )
  }
}

export default injectSheet(styles)(App)
