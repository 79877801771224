import { connect } from 'react-redux'
import AddTeacher from './add-teacher'
import { addTeacher } from '../../../../stores/school-teachers/school-teachers.actions'
import { withRouter } from 'react-router-dom'
import { errorHandler } from 'pobble-components/dist/error-handler'

const mapDispatchToProps = (dispatch, { match }) => ({
  onSubmit: teacher =>
    dispatch(addTeacher(match.params.id, teacher)).catch(errorHandler)
})

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(AddTeacher)
)
