import React from 'react'
import injectSheet from 'react-jss'
import styles from './review-schools.styles.js'

import Paper from 'pobble-components/dist/paper'

import Container from '../../shared/container/container.jsx'
import Main from '../../shared/main/main.jsx'

/*import SchoolsFilter from './schools-filter/schools-filter.container.js'*/
import SchoolsList from './review-schools-list/review-schools-list.container.js'
import SectionTitle from '../../shared/section-title/section-title.jsx'

const ReviewSchools = ({ classes }) => {
  return (
    <Container>
      <Main>
        <Paper className={classes.container}>
          <SectionTitle title="Review schools" />
          {/*<SchoolsFilter />*/}
          <SchoolsList />
        </Paper>
      </Main>
    </Container>
  )
}

export default injectSheet(styles)(ReviewSchools)
