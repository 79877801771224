import { apiClient } from 'pobble-components/dist/api'

const apiGetCampaigns = params =>
  apiClient.get(`v3/admin/campaigns`, { params })

const apiGetCampaign = (id, params) =>
  apiClient.get(`v3/admin/campaigns/${id}`, { params })

const apiCreateCampaign = data => {
  const formData = new FormData()
  for (let key in data) {
    formData.append(key, data[key])
  }
  return apiClient.post(`v3/admin/campaigns`, formData)
}

const apiDeleteCampaign = id => apiClient.delete(`v3/admin/campaigns/${id}`)

const apiEditingCampaign = (id, data) => {
  const formData = new FormData()
  for (let key in data) {
    formData.append(key, data[key])
  }
  return apiClient.put(`v3/admin/campaigns/${id}`, formData)
}

export {
  apiGetCampaigns,
  apiGetCampaign,
  apiCreateCampaign,
  apiDeleteCampaign,
  apiEditingCampaign
}
