import React from 'react'
import styles from './school-entry.styles'
import injectSheet from 'react-jss'
import { Cell } from 'pobble-components/dist/table'
import FormCheckbox from 'pobble-components/dist/form-elements/form-checkbox'
import Button from 'pobble-components/dist/button'
import { Link } from 'react-router-dom'

const SchoolEntry = ({
  classes,
  schoolId,
  schoolTitle,
  schoolLink,
  accountType,
  schoolLeader,
  disableSchoolLead,
  onSchoolLeadChange,
  onDelete
}) => (
  <tr>
    <Cell>
      <Link className={classes.link} to={`/schools/${schoolId}/`}>
        {schoolTitle}
      </Link>
    </Cell>
    <Cell>{accountType}</Cell>
    <Cell className={classes.schoolLeadCell}>
      <FormCheckbox
        onChange={onSchoolLeadChange}
        checked={schoolLeader}
        disabled={disableSchoolLead}
      />
    </Cell>
    <Cell className={classes.removeCell}>
      <Button tall dangerSecondary action={onDelete}>
        Remove
      </Button>
    </Cell>
  </tr>
)

export default injectSheet(styles)(SchoolEntry)
