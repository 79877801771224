export default {
  spinner: {
    fontSize: '20px'
  },
  add: {
    float: 'right',
    position: 'relative',
    zIndex: 10
  }
}
