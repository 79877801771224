import { connect } from 'react-redux'
import TransferSub from './transfer-sub'
import { transferGroupSubscription } from '../../../../../../stores/user-details/user-details.actions'
import { errorHandler } from 'pobble-components/dist/error-handler'

const mapDispatchToProps = dispatch => ({
  onSubmit: (teacher, id) =>
    dispatch(transferGroupSubscription(id, teacher))
      .then(() => window.location.reload())
      .catch(errorHandler)
})

export default connect(null, mapDispatchToProps)(TransferSub)
