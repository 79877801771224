import { connect } from 'react-redux'
import SchoolDetailsSubPlan from './school-details-sub-plan'
import { withRouter } from 'react-router-dom'
import { getSchool } from '../../../../stores/schools/schools.selectors'
import { assignSchoolPlan } from '../../../../stores/school/school.actions'
import { toast } from 'pobble-components/dist/toast'

const mapStateToProps = state => {
  const school = getSchool(state)
  return {
    id: school.id,
    subscriptionIds: school && school.subscription_ids
  }
}

const mapDispatchToProps = (dispatch, { match }) => ({
  onSubmit: values => {
    const data = {
      end_time: values.endDate,
      plan_type: values.plan
    }
    dispatch(assignSchoolPlan(match.params.id, data))
      .then(toast.success('Subscription successfully updated'))
      .catch(e =>
        toast.error(
          (e.response.data.errors && e.response.data.errors[0].msg) ||
            'Something went wrong'
        )
      )
  }
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SchoolDetailsSubPlan)
)
