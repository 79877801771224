import React from 'react'
import injectSheet from 'react-jss'

import styles from './transfer-sub.styles.js'

import Button from 'pobble-components/dist/button'
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter
} from 'pobble-components/dist/modal'
import FormInput from 'pobble-components/dist/form-elements/form-input'

import { Formik } from 'formik'
import * as Yup from 'yup'

const TransferSub = ({ classes, onSubmit, isOpen, onClose, id }) => (
  <Modal legacy isOpen={isOpen} closeButton requestClose={onClose}>
    <ModalHeader>
      Transfer a group subscription {id} to another email address
    </ModalHeader>
    <Formik
      initialValues={{
        id,
        email: ''
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .required('Please provide an email address')
          .email('Please provide a correct email address')
      })}
      onSubmit={({ id, email }) => onSubmit({ id, email })}>
      {props => {
        const {
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          isValid,
          touched
        } = props
        return (
          <form onSubmit={handleSubmit}>
            <ModalContent>
              <FormInput type="hidden" name="id" title="id" value={values.id} />
              <label htmlFor="email" className={classes.label}>
                Email address <sup className={classes.sup}>*</sup>
              </label>
              <FormInput
                name="email"
                id="email"
                placeholder="Ex: teacher@school.com"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                validationError={touched.email && errors.email}
                className={classes.input}
              />
            </ModalContent>
            <ModalFooter className={classes.footer}>
              <Button simple action={onClose}>
                Cancel
              </Button>
              <Button type="submit" disabled={!isValid}>
                Transfer
              </Button>
            </ModalFooter>
          </form>
        )
      }}
    </Formik>
  </Modal>
)

export default injectSheet(styles)(TransferSub)
