import { createSelector } from 'reselect'

const getStudentsIds = state => state.SchoolRetireesStore.students.result
const getStudentsData = state =>
  state.SchoolRetireesStore.students.entities.students

const getStudents = createSelector(
  [getStudentsIds, getStudentsData],
  (studentsIds, studentsData) => studentsIds.map(id => studentsData[id])
)

const getStudentsSchool = state => state.SchoolRetireesStore.school.title

const getStudent = (state, id) => getStudentsData(state)[id]

const isStudentsFetched = state => state.SchoolRetireesStore.studentsFetched

const areStudentsFetching = state => state.SchoolRetireesStore.loading

const getPagination = state => state.SchoolRetireesStore.pagination

export {
  getStudents,
  getStudent,
  areStudentsFetching,
  isStudentsFetched,
  getStudentsSchool,
  getPagination
}
