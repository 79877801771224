import React from 'react'
import styles from './users-list.styles'
import injectSheet from 'react-jss'
import { Table, HeadCell } from 'pobble-components/dist/table'
import User from './user/user.container'
import LoadMoreButton from './load-more-button/load-more-button.container'
import UsersFilter from './users-filter/users-filter.container'
import classNames from 'classnames'

const UsersList = ({ classes, users, hideLoadMore, pending }) => (
  <div
    className={classNames(classes.container, { [classes.pending]: pending })}>
    <Table className={classes.table}>
      <thead>
        <tr>
          <HeadCell>Display name</HeadCell>
          <HeadCell>First name</HeadCell>
          <HeadCell>Last name</HeadCell>
          <HeadCell>Email</HeadCell>
          <HeadCell>School</HeadCell>
          <HeadCell className={classes.center}>
            <span title="Pending teacher">PT</span>
          </HeadCell>
          <HeadCell className={classes.center}>
            <span title="Teacher">T</span>
          </HeadCell>
          <HeadCell className={classes.center}>
            <span title="School leader">SL</span>
          </HeadCell>
          <HeadCell className={classes.center}>
            <span title="Subscriptions">Subs</span>
          </HeadCell>
          <HeadCell className={classes.center}>
            <span title="Subscriptions Status">Status</span>
          </HeadCell>
          <HeadCell className={classes.center}>
            <span title="Seats">Seats</span>
          </HeadCell>
          <HeadCell className={classes.center}>
            <span title="Assigned a seat">Assigned</span>
          </HeadCell>
          <HeadCell className={classes.center}>Open</HeadCell>
        </tr>
      </thead>
      <tbody>
        <UsersFilter />
        {users.map(user => (
          <User key={user.id} id={user.id} />
        ))}
      </tbody>
    </Table>
    {!hideLoadMore && <LoadMoreButton />}
  </div>
)

export default injectSheet(styles)(UsersList)
