import { connect } from 'react-redux'
import UserDetails from './user-details'
import { getFetchUserRequest } from '../../../stores/user-details/user-details.selectors'
import { fetchUser } from '../../../stores/user-details/user-details.actions'
import { withRouter } from 'react-router'
import { errorHandler } from 'pobble-components/dist/error-handler'

const mapStateToProps = state => {
  const fetchUserRequest = getFetchUserRequest(state)
  return {
    pending: fetchUserRequest.pending
  }
}

const mapDispatchToProps = (dispatch, { match }) => ({
  onMount: () => dispatch(fetchUser(match.params.id)).catch(errorHandler)
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserDetails)
)
