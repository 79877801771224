import React, { Component } from 'react'
import injectSheet from 'react-jss'
import { withRouter, Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from 'yup'
import styles from './work-details-form.styles.js'
import FormLabel from '../../../shared/form-elements/form-label/form-label.jsx'
import FormFields from '../../../shared/form-elements/form-fields/form-fields.jsx'
import FormField from '../../../shared/form-elements/form-field/form-field.jsx'
import FormLine from '../../../shared/form-elements/form-line/form-line.jsx'
import WorkMediaList from '../work-media-list/work-media-list.container'
import Spinner from 'pobble-components/dist/spinner'
import FormInput from 'pobble-components/dist/form-elements/form-input'
import Button from 'pobble-components/dist/button'
import dateFns from 'date-fns'

class WorkDetailsForm extends Component {
  componentDidMount() {
    const { onMount } = this.props
    onMount()
  }

  backToWorksList = () => this.props.history.push('/works')

  render() {
    const {
      classes,
      showLoading,
      onWorkUpdate,
      onWorkPublish,
      onWorkUnpublish,
      onWorkFlag,
      onWorkUnflag,
      isWorkBeingUpdated,
      notFound,
      id,
      title,
      description,
      student,
      created_at,
      uploaded_by,
      delivered_at,
      status,
      flag
    } = this.props
    const requiredMsg = 'This field should not be empty'

    return showLoading ? (
      <Spinner className={classes.spinner} />
    ) : notFound ? (
      <span>Work not found</span>
    ) : (
      <Formik
        initialValues={{
          id: id,
          title: title || '',
          description: description || ''
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().required(requiredMsg),
          description: Yup.string().required(requiredMsg)
        })}
        onSubmit={({ id, title, description }) =>
          onWorkUpdate(id, {
            id,
            title,
            description
          })
        }>
        {props => {
          const {
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            errors,
            isValid,
            touched
          } = props
          return (
            <form onSubmit={handleSubmit}>
              <FormFields>
                <FormField>
                  <FormInput
                    type="hidden"
                    name="id"
                    title="id"
                    value={values.id}
                  />
                  <FormLabel label={'Title'} htmlFor={'title'} required />
                  <FormInput
                    name="title"
                    id="title"
                    placeholder="Ex: Red Riding Hood"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    validationError={touched.title && errors.title}
                  />
                </FormField>
                <FormField>
                  <FormLabel
                    label={'Description'}
                    htmlFor={'description'}
                    required
                  />
                  <FormInput
                    name="description"
                    id="description"
                    placeholder="Ex: A poem about Red Riding Hood"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    validationError={touched.description && errors.description}
                  />
                </FormField>
              </FormFields>
              <FormLine />
              <FormFields>
                <FormField>
                  <FormLabel label={'Id:'} htmlFor={'id'} />
                  <a
                    href={`https://app.pobble.com/works/${id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.plainLink}>
                    {id}
                  </a>
                </FormField>
              </FormFields>
              <FormFields>
                <FormField>
                  <FormLabel label={'Status'} htmlFor={'status'} />
                  <div>
                    <strong>{status}</strong>
                  </div>
                </FormField>
                <FormField>
                  <FormLabel label={'Student'} htmlFor={'student'} />
                  <div>
                    <strong>{student}</strong>
                  </div>
                </FormField>
              </FormFields>
              <FormFields>
                <FormField>
                  <FormLabel
                    label={'Lesson delivered at'}
                    htmlFor={'delivered_at'}
                  />
                  <div>
                    <strong>{delivered_at}</strong>
                  </div>
                </FormField>
                <FormField>
                  <FormLabel label={'Uploaded by'} htmlFor={'uploaded_by'} />
                  <div>
                    <strong>{uploaded_by}</strong>
                  </div>
                </FormField>
              </FormFields>
              <FormFields>
                <FormField>
                  <FormLabel label={'Uploaded at'} htmlFor={'uploaded_at'} />
                  <div>
                    <strong>{created_at}</strong>
                  </div>
                </FormField>
              </FormFields>
              <FormFields>
                {flag &&
                  flag.flagged_at && (
                    <FormField>
                      <FormLabel
                        label={'Flagged details'}
                        htmlFor={'delivered_at'}
                      />
                      <div>
                        <strong>
                          {dateFns.format(flag.flagged_at, 'MM/DD/YYYY H:s')}
                        </strong>
                      </div>
                      <em>"{flag.reason}"</em>
                      <div>
                        by{' '}
                        <Link
                          to={`/users/${flag.account.id}`}
                          className={classes.plainLink}>
                          {flag.account.full_name}
                        </Link>
                      </div>
                    </FormField>
                  )}
              </FormFields>
              <FormFields>
                <FormField>
                  {!flag && (
                    <Button
                      tall
                      className={classes.buttons}
                      action={onWorkFlag}
                      disabled={isWorkBeingUpdated}>
                      Flag
                    </Button>
                  )}
                  {flag && (
                    <Button
                      tall
                      className={classes.buttons}
                      action={onWorkUnflag}
                      disabled={isWorkBeingUpdated}>
                      Unflag
                    </Button>
                  )}
                  {status === 'unpublished' && (
                    <Button
                      tall
                      className={classes.buttons}
                      action={onWorkPublish}
                      disabled={isWorkBeingUpdated}>
                      Publish
                    </Button>
                  )}
                  {status === 'published' && (
                    <Button
                      tall
                      className={classes.buttons}
                      action={onWorkUnpublish}
                      disabled={isWorkBeingUpdated}>
                      Unpublish
                    </Button>
                  )}
                </FormField>
              </FormFields>
              <FormLine />
              <FormFields>
                <FormField>
                  <FormLabel label={'Media'} htmlFor={'Media'} />
                  <WorkMediaList />
                </FormField>
              </FormFields>
              <div className={classes.footer}>
                <Button simple tall action={this.backToWorksList}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  tall
                  disabled={!isValid || isWorkBeingUpdated}>
                  {isWorkBeingUpdated ? 'Updating...' : 'Update'}
                </Button>
              </div>
            </form>
          )
        }}
      </Formik>
    )
  }
}

export default withRouter(injectSheet(styles)(WorkDetailsForm))
