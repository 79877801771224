import React, { Component, Fragment } from 'react'
import injectSheet from 'react-jss'
import classNames from 'classnames'

import styles from './teachers-list.styles.js'

import Teacher from '../teacher/teacher.container.js'

import { Table, HeadCell } from 'pobble-components/dist/table'
import Spinner from 'pobble-components/dist/spinner'

class SchoolTeachersList extends Component {
  componentDidMount() {
    this.props.onMount()
  }
  render() {
    const {
      classes,
      showLoading,
      teachers,
      noTeachers,
      noTeachersMatchingFilters,
      teacherGettingAdded
    } = this.props
    return showLoading ? (
      <Spinner className={classes.spinner} />
    ) : noTeachers ? (
      <span>No teachers</span>
    ) : (
      <Fragment>
        {!noTeachersMatchingFilters && (
          <Table
            className={classNames({
              [classes.pendingAddition]: teacherGettingAdded
            })}>
            <thead>
              <tr>
                <HeadCell className={classes.cell}>Name</HeadCell>
                <HeadCell className={classes.cell}>Email</HeadCell>
                <HeadCell>School Leader</HeadCell>
                <HeadCell className={classes.cell}>Created at</HeadCell>
                <HeadCell className={classes.cell}>Last login</HeadCell>
                <HeadCell>Nb of Logins</HeadCell>
                <HeadCell>Seats</HeadCell>
                <HeadCell>Assigned</HeadCell>
                <HeadCell />
              </tr>
            </thead>
            <tbody>
              {teachers.map(teacher => (
                <Teacher
                  key={teacher.account.id}
                  id={teacher.account.id}
                  name={teacher.account.full_name}
                  email={
                    teacher.account.email_identity &&
                    teacher.account.email_identity.email
                  }
                  schoolLeader={teacher.school_leader}
                  nbOfLogins={teacher.account.number_of_sign_ins}
                  lastLogin={teacher.account.last_sign_in_at}
                  longInactivity={teacher.account.long_inactivity}
                  createdAt={teacher.account.created_at}
                  seatAssigned={
                    teacher.account
                      .group_subscription_assignments_from_my_school.length
                  }
                  seatsOwned={
                    teacher.account.all_my_subscriptions_group_addons.length
                  }
                />
              ))}
            </tbody>
          </Table>
        )}
        {noTeachersMatchingFilters && <span>No teachers found.</span>}
      </Fragment>
    )
  }
}

export default injectSheet(styles)(SchoolTeachersList)
