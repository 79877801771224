import React, { Component } from 'react'
import injectSheet from 'react-jss'

import styles from './teachers-filter.styles.js'

import AddTeacher from '../add-teacher/add-teacher.container.js'

import Button from 'pobble-components/dist/button'
import FormInput from 'pobble-components/dist/form-elements/form-input'

class SchoolTeachersFilter extends Component {
  constructor(props) {
    super(props)
    this.state = { openModal: false }
  }

  openModalHandler = () => {
    this.setState({ openModal: true })
  }

  closeModalHandler = () => {
    this.setState({ openModal: false })
  }

  render() {
    const { classes, searchValue, onSearchChange } = this.props
    return (
      <div className={classes.container}>
        <strong className={classes.title}>Teacher</strong>
        <FormInput
          onChange={onSearchChange}
          value={searchValue}
          placeholder="Last name, first name, email address"
        />
        <span className={classes.separator} />
        <Button tall action={this.openModalHandler}>
          Add
        </Button>
        <AddTeacher
          isOpen={this.state.openModal}
          onClose={this.closeModalHandler}
        />
      </div>
    )
  }
}

export default injectSheet(styles)(SchoolTeachersFilter)
