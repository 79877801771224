import { connect } from 'react-redux'
import LoadMoreButton from './load-more-button'
import { loadMoreComments } from '../../../../stores/comments/comments.actions'
import {
  getFetchCommentsRequest,
  getFetchCommentsRequestInitiator
} from '../../../../stores/comments/comments.selectors'

const mapStateToProps = state => ({
  pending:
    getFetchCommentsRequest(state) &&
    getFetchCommentsRequestInitiator(state) === 'LOAD_MORE'
})

const mapDispatchToProps = dispatch => ({
  onLoadMore: () => dispatch(loadMoreComments())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoadMoreButton)
