import React from 'react'
import styles from './details-avatar.styles'
import injectSheet from 'react-jss'
import classNames from 'classnames'
import UserAvatar from 'pobble-components/dist/user-avatar'

const UserDetailsAvatar = ({ classes, url, className, name, pending }) => (
  <div
    className={classNames(classes.container, className, {
      [classes.imageAvatar]: !!url,
      [classes.pending]: pending
    })}
    style={{ backgroundImage: url ? `url(${url})` : null }}>
    {!url && <UserAvatar className={classes.userAvatar} name={name} />}
  </div>
)

export default injectSheet(styles)(UserDetailsAvatar)
