const styles = () => ({
  container: {
    background: '#f8f8f8',
    padding: '10px',
    borderRadius: '4px',
    marginBottom: '20px',
    border: '1px solid #e5e5e5',
    display: 'flex',
    alignItems: 'center'
  },
  row: {
    display: 'flex',
    alignItems: 'center'
  },
  separator: {
    width: '1px',
    height: '16px',
    marginRight: '20px',
    background: '#e5e5e5',
    display: 'block'
  },
  title: {
    marginRight: '20px'
  },
  filter: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px'
  },
  label: {
    margin: '0 10px 0 6px',
    cursor: 'pointer'
  }
})

export default styles
