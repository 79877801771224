import { apiClient } from 'pobble-components/dist/api'

const apiRetireStudent = studentId =>
  apiClient.delete(`v3/students/${studentId}/retire`)

const apiUnretireStudent = studentId =>
  apiClient.post(`v3/students/${studentId}/unretire`)

const apiStudentDocuments = (id, params) =>
  apiClient.get(`v3/accounts/${id}/permission_slips`, { params })

export { apiRetireStudent, apiUnretireStudent, apiStudentDocuments }
