import React from 'react'
import styles from './user-details-head.styles'
import injectSheet from 'react-jss'
import { Link } from 'react-router-dom'
import CarretLeftIcon from 'react-icons/lib/fa/caret-left'
import UserDetailsAvatar from '../user-details-avatar/user-details-avatar.container'

const UserDetailsHead = ({ classes, fullName, displayName, userEmail }) => (
  <div className={classes.container}>
    <Link className={classes.backLink} to="/users/">
      <CarretLeftIcon />
      Back to users list
    </Link>
    <div className={classes.userInfo}>
      <UserDetailsAvatar className={classes.avatar} />
      <div className={classes.names}>
        <strong>{fullName}</strong>
        <span>{displayName}</span>
        <br />
        <span>{userEmail}</span>
      </div>
    </div>
  </div>
)

export default injectSheet(styles)(UserDetailsHead)
