import { connect } from 'react-redux'
import Users from './users'
import { fetchUsers } from '../../../stores/users/users.actions'
import {
  getFetchUsersRequest,
  getServerFilterParams,
  getFetchUsersRequestInitiator
} from '../../../stores/users/users.selectors'

const mapStateToProps = state => ({
  pending:
    getFetchUsersRequest(state).pending &&
    getFetchUsersRequestInitiator(state) === 'INITIAL',
  filterParams: getServerFilterParams(state)
})

const mapDispatchToProps = dispatch => ({ dispatch })

const mergeProps = (stateProps, { dispatch }, ownProps) => ({
  ...ownProps,
  ...stateProps,
  onMount: () => dispatch(fetchUsers(stateProps.filterParams, 'INITIAL')),
  onUpdate: prevProps => {
    if (prevProps.filterParams !== stateProps.filterParams) {
      const initiator =
        prevProps.filterParams.limit !== stateProps.filterParams.limit
          ? 'LOAD_MORE'
          : 'SEARCH'
      dispatch(fetchUsers(stateProps.filterParams, initiator))
    }
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Users)
