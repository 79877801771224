import { connect } from 'react-redux'
import PendingTeachersList from './pending-teachers-list'
import { withRouter } from 'react-router-dom'
import { getPendingTeachers } from '../../../../stores/school-teachers/school-teachers.selectors'

const mapStateToProps = state => {
  return {
    noPendingTeachers: !getPendingTeachers(state).length,
    pendingTeachers: getPendingTeachers(state)
  }
}

const mapDispatchToProps = dispatch => ({ dispatch })

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PendingTeachersList)
)
