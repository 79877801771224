import React, { Component } from 'react'
import injectSheet from 'react-jss'
import styles from './logo.styles.js'
import Button from 'pobble-components/dist/button'

class Logo extends Component {
  render() {
    const { classes, onDelete, logo } = this.props
    return logo ? (
      <div className={classes.container}>
        <img src={logo} className={classes.image} width="100" alt="Logo" />
        <Button simple action={() => onDelete()}>
          Remove this logo
        </Button>
      </div>
    ) : (
      <div className={classes.nothing}>No logo</div>
    )
  }
}

export default injectSheet(styles)(Logo)
