const styles = ({ COLORS }) => ({
  button: {
    margin: '0 2px',
    textDecoration: 'none'
  },
  action: {
    textAlign: 'right'
  },
  link: {
    textDecoration: 'none',
    fontWeight: 'bold',
    color: COLORS.BLUE.NORMAL
  }
})

export default styles
