export default ({ COLORS }) => ({
  container: {
    width: '96px',
    height: '96px',
    borderRadius: '96px'
  },
  imageAvatar: {
    border: `1px solid rgba(0, 0, 0, 0.2)`,
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  userAvatar: {
    fontSize: '48px'
  },
  pending: {
    display: 'none'
  }
})
