const styles = () => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  nothing: {
    padding: '10px 0'
  },
  image: {
    marginBottom: '10px'
  }
})

export default styles
