import { connect } from 'react-redux'
import StudentDetailsActions from './student-details-actions'
import { withRouter } from 'react-router'
import { redactStudentAccount } from '../../../../../stores/student-details/student-details.actions'
import { errorHandler } from 'pobble-components/dist/error-handler'
import { toast } from 'pobble-components/dist/toast'

const mapDispatchToProps = (dispatch, { match }) => ({
  onRedact: () =>
    dispatch(redactStudentAccount(match.params.id))
      .catch(errorHandler)
      .then(toast.success('This student was redacted'))
      .catch(errorHandler)
})

export default withRouter(
  connect(null, mapDispatchToProps)(StudentDetailsActions)
)
