import { connect } from 'react-redux'
import Works from './works'
import { fetchWorks } from '../../../stores/works/works.actions'
import {
  getFetchWorksRequest,
  getServerFilterParams,
  getFetchWorksRequestInitiator
} from '../../../stores/works/works.selectors'

const mapStateToProps = state => ({
  pending:
    getFetchWorksRequest(state).pending &&
    getFetchWorksRequestInitiator(state) === 'INITIAL',
  filterParams: getServerFilterParams(state)
})

const mapDispatchToProps = dispatch => ({ dispatch })

const mergeProps = (stateProps, { dispatch }, ownProps) => ({
  ...ownProps,
  ...stateProps,
  onMount: () => dispatch(fetchWorks(stateProps.filterParams, 'INITIAL')),
  onUpdate: prevProps => {
    if (prevProps.filterParams !== stateProps.filterParams) {
      const initiator =
        prevProps.filterParams.limit !== stateProps.filterParams.limit
          ? 'LOAD_MORE'
          : 'SEARCH'
      dispatch(fetchWorks(stateProps.filterParams, initiator))
    }
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Works)
