import { connect } from 'react-redux'
import UserDetailsRoles from './user-details-roles'
import {
  getUser,
  getPromotePobblerRequest,
  getDemotePobblerRequest,
  getPromoteAdminRequest,
  getDemoteAdminRequest
} from '../../../../../stores/user-details/user-details.selectors'
import {
  promotePobbler,
  demotePobbler,
  promoteAdmin,
  demoteAdmin
} from '../../../../../stores/user-details/user-details.actions'
import { withRouter } from 'react-router'
import { errorHandler } from 'pobble-components/dist/error-handler'

const mapStateToProps = state => {
  const user = getUser(state)
  return {
    pobbler: !!user.pobbler_type,
    admin: !user.pobbler_type ? false : user.pobbler_type.admin,
    disableAdmin: !user.pobbler_type,
    pending:
      getPromotePobblerRequest(state).pending ||
      getDemotePobblerRequest(state).pending ||
      getPromoteAdminRequest(state).pending ||
      getDemoteAdminRequest(state).pending
  }
}

const mapDispatchToProps = (dispatch, { match }) => ({
  onPobblerChange: e =>
    e.target.checked
      ? dispatch(promotePobbler(match.params.id)).catch(errorHandler)
      : dispatch(demotePobbler(match.params.id)).catch(errorHandler),
  onAdminChange: e =>
    e.target.checked
      ? dispatch(promoteAdmin(match.params.id)).catch(errorHandler)
      : dispatch(demoteAdmin(match.params.id)).catch(errorHandler)
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserDetailsRoles)
)
