import React from 'react'
import styles from './organisation.styles'
import injectSheet from 'react-jss'
import { Cell } from 'pobble-components/dist/table'
import { Link } from 'react-router-dom'
import { openConfirmationDialog } from 'pobble-components/dist/modal/confirmation-dialog'
import Button from 'pobble-components/dist/button'
import CircleStatus from 'pobble-components/dist/circle-status'

const Organisation = ({
  classes,
  id,
  title,
  active,
  date,
  onDelete,
  localAuthority
}) => (
  <tr>
    <Cell>
      <Link className={classes.link} to={`/organisations/${id}`}>
        {title}
      </Link>
    </Cell>
    <Cell>{localAuthority && <CircleStatus status={true} />}</Cell>
    <Cell className={classes.action}>
      <Button
        tall
        danger
        action={() =>
          openConfirmationDialog({
            bodyText: `Are you sure you want to delete this organisation?`,
            confirmText: 'Delete',
            action: () => onDelete(id)
          })
        }>
        Remove
      </Button>
    </Cell>
  </tr>
)

export default injectSheet(styles)(Organisation)
