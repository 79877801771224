const styles = ({ COLORS }) => ({
  button: {
    margin: '0 2px',
    textDecoration: 'none'
  },
  action: {
    textAlign: 'right',
    '& button': {
      marginLeft: '10px'
    }
  },
  loading: {
    opacity: '.5',
    pointerEvents: 'none',
    transition: '300ms opacity ease-in-out'
  },
  link: {
    fontWeight: 'bold',
    textDecoration: 'none',
    color: COLORS.BLUE.NORMAL
  },
  center: {
    textAlign: 'center'
  },
  longInactivity: {
    fontWeight: 'bold',
    color: COLORS.RED.NORMAL
  }
})

export default styles
