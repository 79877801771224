import { combineReducers } from 'redux'
import {
  createReducer,
  createRequestReducer
} from 'pobble-components/dist/redux-helpers'
import { normalize, schema } from 'normalizr'

const normalizeCampaigns = data => {
  const campaign = new schema.Entity('campaigns')
  return normalize(data, [campaign])
}

const CampaignsReducer = combineReducers({
  fetchCampaignsRequest: createRequestReducer('CAMPAIGNS:FETCHING'),
  fetchCampaignRequest: createRequestReducer('CAMPAIGN:FETCHING'),
  campaigns: createReducer(null, {
    'CAMPAIGNS:FETCHING_REQUEST_SUCCESS': (state, { response }) =>
      normalizeCampaigns(response.data.campaigns),
    'CAMPAIGNS:DELETING_REQUEST_SUCCESS': (state, { response, params }) => {
      return {
        ...state,
        result: state.result.filter(id => id !== params.campaignId)
      }
    }
  }),
  campaign: createReducer(null, {
    'CAMPAIGN:FETCHING_REQUEST_SUCCESS': (state, { response }) =>
      response.data.campaign
  })
})

export default CampaignsReducer
