import React from 'react'
import styles from './organisations-list.styles'
import injectSheet from 'react-jss'
import { Table, HeadCell } from 'pobble-components/dist/table'
import Organisation from './organisation/organisation.container'

const OrganisationsList = ({ classes, organisations, pending }) => (
  <div className={classes.container}>
    <Table className={classes.table}>
      <thead>
        <tr>
          <HeadCell>Title</HeadCell>
          <HeadCell>Local Authority</HeadCell>
        </tr>
      </thead>
      <tbody>
        {organisations.map(organisation => (
          <Organisation key={organisation.id} id={organisation.id} />
        ))}
      </tbody>
    </Table>
  </div>
)

export default injectSheet(styles)(OrganisationsList)
