import React from 'react'
import { Link } from 'react-router-dom'
import styles from './work.styles'
import injectSheet from 'react-jss'
import { Cell } from 'pobble-components/dist/table'
import Button from 'pobble-components/dist/button'
import { openConfirmationDialog } from 'pobble-components/dist/modal/confirmation-dialog'

const Work = ({
  classes,
  id,
  updated_at,
  title,
  school,
  status,
  lessonDelivered,
  history,
  onWorkFlag,
  onWorkUnflag,
  onWorkDelete
}) => (
  <tr>
    <Cell>{updated_at}</Cell>
    <Cell>
      <Link to={`/works/${id}`} className={classes.plainLink}>
        {title}
      </Link>
    </Cell>
    <Cell>{school}</Cell>
    <Cell>{status}</Cell>
    <Cell className={classes.action}>
      {status === 'published' && (
        <Button tall secondary disabled className={classes.button}>
          Work
        </Button>
      )}
      {status === 'unpublished' && (
        <Button success disabled tall className={classes.button}>
          Publish
        </Button>
      )}
      {status === 'flagged' && (
        <Button
          success
          tall
          action={() => onWorkUnflag(id)}
          className={classes.button}>
          Unflag
        </Button>
      )}
      {status !== 'flagged' && (
        <Button
          warning
          tall
          action={() => onWorkFlag(id)}
          className={classes.button}>
          Flag
        </Button>
      )}
      {status !== 'deleted' && (
        <Button
          danger
          tall
          className={classes.button}
          action={() =>
            openConfirmationDialog({
              bodyText: `Are you sure you want to delete this work?`,
              confirmText: 'Delete',
              action: () => onWorkDelete(id)
            })
          }>
          Delete
        </Button>
      )}
      {status === 'deleted' && (
        <Button warning tall disabled className={classes.button}>
          Undelete
        </Button>
      )}
      <Button action={() => history.push(`/works/${id}`)} tall>
        Edit
      </Button>
    </Cell>
  </tr>
)

export default injectSheet(styles)(Work)
