const styles = ({ COLORS }) => ({
  spinner: {
    fontSize: '20px'
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  pending: {
    opacity: '.5',
    pointerEvents: 'none'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  navLink: {
    marginLeft: '10px',
    textDecoration: 'none'
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    '& strong': {
      marginLeft: '5px'
    }
  }
})

export default styles
