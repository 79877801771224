import { createSelector } from 'reselect'
import { getSchools } from '../schools/schools.selectors.js'

const getFilterSearch = state => state.SchoolsFilter.search

const getFilteredSchools = createSelector(
  [getFilterSearch, getSchools],
  (searchValue, schools) =>
    !searchValue
      ? schools
      : schools.filter(school => {
          let searchTerm = searchValue.toLowerCase()
          return (
            school.title.toLowerCase().indexOf(searchTerm) !== -1 ||
            school.city.toLowerCase().indexOf(searchTerm) !== -1
          )
        })
)

export { getFilteredSchools, getFilterSearch }
