import { connect } from 'react-redux'
import CampaignEditionForm from './campaign-edition-form'
import { editCampaign } from '../../../../stores/campaigns/campaigns.actions'
import { getEditedCampaign } from '../../../../stores/campaigns/campaigns.selectors'
import { errorHandler } from 'pobble-components/dist/error-handler'
import { withRouter } from 'react-router'

const mapStateToProps = (state, { id }) => {
  const campaign = getEditedCampaign(state, id)
  return {
    title: campaign.title,
    description: campaign.description,
    link_label: campaign.link_label,
    link_url: campaign.link_url,
    type: campaign.type,
    audience: campaign.audience,
    date: campaign.date,
    active: campaign.active,
    imageUrl: campaign.image_url
  }
}

const mapDispatchToProps = (dispatch, { match }) => ({
  onSubmit: values => {
    const data = {
      title: values.title,
      description: values.description,
      link_label: values.buttonLabel,
      link_url: values.buttonUrl,
      date: values.date,
      type: values.type,
      audience: values.audience,
      active: values.active,
      image: values.file
    }
    dispatch(editCampaign(match.params.id, data))
      .then(() => window.location.reload())
      .catch(errorHandler)
  }
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CampaignEditionForm)
)
