import React from 'react'
import styles from './user-details-form.styles'
import injectSheet from 'react-jss'
import Section from '../../../../shared/details/section/section'
import FormRow from '../../../../shared/details/form-row/form-row'
import FormCell from '../../../../shared/details/form-cell/form-cell'
import FormLabel from '../../../../shared/details/form-label/form-label'
import CellText from '../../../../shared/details/cell-text/cell-text'
import FormInput from 'pobble-components/dist/form-elements/form-input'
import { Formik } from 'formik'
import Button from 'pobble-components/dist/button'
import FormTextArea from 'pobble-components/dist/form-elements/form-text-area'
import classNames from 'classnames'

const UserDetailsForm = ({
  classes,
  showNote,
  pending,
  created,
  signIns,
  lastSignIn,
  firstName,
  lastName,
  displayName,
  email,
  onSubmit,
  note,
  subsPlan,
  subsEnd
}) => (
  <Formik
    onSubmit={onSubmit}
    initialValues={{
      firstName: firstName || '',
      lastName: lastName || '',
      displayName: displayName || '',
      email: email || '',
      note: note || ''
    }}>
    {formikProps => {
      const {
        values,
        handleBlur,
        handleChange,
        handleSubmit,
        isValid
      } = formikProps
      return (
        <form
          onSubmit={handleSubmit}
          className={classNames(classes.form, { [classes.pending]: pending })}>
          <Section
            title="Info"
            renderHeader={() => (
              <Button
                className={classes.submitButton}
                disabled={!isValid}
                type="submit"
                tall>
                Save changes
              </Button>
            )}>
            <FormRow>
              <FormCell>
                <FormLabel>Created</FormLabel>
                <CellText>{created}</CellText>
              </FormCell>
              <FormCell>
                <FormLabel>Number of sign ins</FormLabel>
                <CellText>{signIns}</CellText>
              </FormCell>
              <FormCell>
                <FormLabel>Last sign in</FormLabel>
                <CellText>{lastSignIn}</CellText>
              </FormCell>
            </FormRow>
            <FormRow>
              <FormCell>
                <FormLabel>First name</FormLabel>
                <FormInput
                  name="firstName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                />
              </FormCell>
              <FormCell>
                <FormLabel>Last name</FormLabel>
                <FormInput
                  name="lastName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                />
              </FormCell>
              <FormCell>
                <FormLabel>Display name</FormLabel>
                <FormInput
                  name="displayName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.displayName}
                />
              </FormCell>
            </FormRow>
            {showNote && (
              <FormRow noBorder>
                <FormCell size="L">
                  <FormLabel>Note</FormLabel>
                  <FormTextArea
                    name="note"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.note}
                  />
                </FormCell>
              </FormRow>
            )}
          </Section>
        </form>
      )
    }}
  </Formik>
)

export default injectSheet(styles)(UserDetailsForm)
