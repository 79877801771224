import React from 'react'
import styles from './documents-table.styles'
import injectSheet from 'react-jss'
import { Table, HeadCell } from 'pobble-components/dist/table'
import DocumentEntry from './document-entry/document-entry.container'

const DocumentsTable = ({ classes, permissions, id }) => (
  <Table>
    <thead>
      <tr>
        <HeadCell className={classes.left}>File</HeadCell>
        <HeadCell className={classes.left}>Uploaded on</HeadCell>
        <HeadCell />
      </tr>
    </thead>
    <tbody>
      {permissions.map(permissionId => (
        <DocumentEntry key={permissionId} id={permissionId} studentId={id} />
      ))}
    </tbody>
  </Table>
)

export default injectSheet(styles)(DocumentsTable)
