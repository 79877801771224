import { createSelector } from 'reselect'

const getSchoolsIds = state => state.SchoolsStore.schools.result
const getSchoolsData = state => state.SchoolsStore.schools.entities.schools

const getSchools = createSelector(
  [getSchoolsIds, getSchoolsData],
  (schoolsIds, schoolsData) => schoolsIds.map(id => schoolsData[id])
)

const getFetchSchoolsRequest = state => state.SchoolsStore.loading

const getFetchSchoolsRequestInitiator = createSelector(
  [getFetchSchoolsRequest],
  req => (!req.params ? null : req.params.initiator)
)

const getLimit = state => state.SchoolsStore.currentLimit

const getServerParams = createSelector([getLimit], currentLimit => ({
  limit: currentLimit
}))

const lessSchoolsThanLimit = createSelector(
  [getSchoolsIds, getLimit],
  (schools, currentLimit) => (!schools ? true : schools.length < currentLimit)
)

const isSchoolsFetched = state => state.SchoolsStore.schoolsFetched

const getSchool = state => state.SchoolStore.school

const isSchoolFetching = state => state.SchoolStore.loading
const areSchoolsFetching = state => state.SchoolsStore.loading
const isSchoolBeingAdded = state => state.SchoolsStore.schoolBeingAdded || false
const isSchoolBeingUpdated = state =>
  state.SchoolsStore.schoolBeingUpdated || false

const getSchoolsNumber = createSelector(
  [getSchools],
  schools => schools && schools.length
)

export {
  getSchools,
  areSchoolsFetching,
  isSchoolBeingAdded,
  getSchool,
  isSchoolBeingUpdated,
  isSchoolsFetched,
  isSchoolFetching,
  getFetchSchoolsRequest,
  getFetchSchoolsRequestInitiator,
  lessSchoolsThanLimit,
  getServerParams,
  getSchoolsNumber
}
