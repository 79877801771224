import { connect } from 'react-redux'
import WorkMedia from './work-media'
import { withRouter } from 'react-router'
import { deleteMedia } from '../../../../stores/work-details/work-details.actions'
import {
  getMedia,
  getMediaCount
} from '../../../../stores/work-details/work-details.selectors'
import { errorHandler } from 'pobble-components/dist/error-handler'

const mapStateToProps = (state, { id }) => {
  const media = getMedia(state, id)
  return {
    imageUrl: media.image_url,
    canBeDeleted: getMediaCount(state, id) > 1
  }
}

const mapDispatchToProps = (dispatch, { id }) => ({
  onDelete: state => dispatch(deleteMedia(id)).catch(errorHandler)
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(WorkMedia)
)
