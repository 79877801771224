import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import WorkMediaList from './work-media-list'
import { getWork } from '../../../../stores/work-details/work-details.selectors'
import { orderMedia } from '../../../../stores/work-details/work-details.actions'
import { errorHandler } from 'pobble-components/dist/error-handler'

const mapStateToProps = state => {
  const work = getWork(state)
  return {
    media: work.media || []
  }
}

const mapDispatchToProps = (dispatch, { match }) => ({
  onOrderingMedia: orderedIds =>
    dispatch(orderMedia(match.params.id, orderedIds)).catch(errorHandler)
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(WorkMediaList)
)
