import { connect } from 'react-redux'
import CampaignCreationForm from './campaign-creation-form'
import { createCampaign } from '../../../../stores/campaigns/campaigns.actions'
import { errorHandler } from 'pobble-components/dist/error-handler'

const mapDispatchToProps = dispatch => ({
  onSubmit: values => {
    const data = {
      title: values.title,
      description: values.description,
      link_label: values.buttonLabel,
      link_url: values.buttonUrl,
      date: values.date,
      type: values.type,
      audience: values.audience,
      active: values.active,
      image: values.file
    }
    dispatch(createCampaign(data))
      .then(() => (window.location = '/campaigns'))
      .catch(errorHandler)
  }
})

export default connect(
  null,
  mapDispatchToProps
)(CampaignCreationForm)
