import React from 'react'
import injectSheet from 'react-jss'
import styles from './nav-item.styles'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'

const NavItem = ({ classes, children, counter, path, disabled }) => (
  <li>
    <NavLink
      exact
      to={path}
      activeClassName={classes.navigationLinkActive}
      className={classNames(classes.navigationLink, {
        [classes.navigationLinkDisabled]: disabled
      })}>
      {children}
      {!!counter && (
        <span className={classes.navigationLinkCounter}>{counter}</span>
      )}
    </NavLink>
  </li>
)

export default injectSheet(styles)(NavItem)
