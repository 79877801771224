import { connect } from 'react-redux'
import User from './user'
import {
  getUser,
  getUserSchools
} from '../../../../../stores/users/users.selectors'
import { withRouter } from 'react-router'

const mapStateToProps = (state, { id }) => {
  const user = getUser(state, id)
  const schools = getUserSchools(state, id)
  return {
    id,
    firstName: user.first_name || '-',
    lastName: user.last_name || '-',
    displayName: user.display_name || '-',
    email: !user.email_identity ? '-' : user.email_identity.email,
    pt: user.pending_teacher_type,
    t: user.teacher_type,
    sl: !user.teacher_type ? null : user.teacher_type.school_leader,
    schools: schools,
    subs: user.individual_subscription && user.individual_subscription.plan,
    subStatus:
      user.individual_subscription && user.individual_subscription.status,
    nbOfSeats: user.number_of_seats_account_can_attribute || '-',
    seatAssigned: user.has_subs_group_seat_assigned
  }
}

const mapDispatchToProps = (dispatch, { id, history }) => ({
  onOpen: () => history.push(`/users/${id}`)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(User))
