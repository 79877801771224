import React from 'react'
import styles from './school-details-sub-plan.styles'
import injectSheet from 'react-jss'
import FormRow from '../../../shared/details/form-row/form-row'
import FormCell from '../../../shared/details/form-cell/form-cell'
import FormLabel from '../../../shared/details/form-label/form-label'
import FormInput from 'pobble-components/dist/form-elements/form-input'
import FormSelect from 'pobble-components/dist/form-elements/form-select'
import { Formik } from 'formik'
import Button from 'pobble-components/dist/button'
import * as Yup from 'yup'

const requiredMsg = 'This field should not be empty'

const SchoolDetailsPobbleSchool = ({ classes, onSubmit, id }) => (
  <div>
    {id && (
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          plan: '',
          endDate: ''
        }}
        validationSchema={Yup.object().shape({
          plan: Yup.string().required(requiredMsg),
          endDate: Yup.date('').required(requiredMsg)
        })}>
        {formikProps => {
          const {
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            isValid,
            touched,
            errors
          } = formikProps
          return (
            <form onSubmit={handleSubmit}>
              <FormRow>
                <FormCell>
                  <FormLabel>Assign a plan</FormLabel>
                  <FormSelect
                    name="plan"
                    value={values.plan}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    validationError={touched.plan && errors.plan}>
                    <option value="">-</option>
                    <option value="pobble-school-a">
                      T&L (pobble-school-a)
                    </option>
                    <option value="pobble-school-b">
                      Moderation (pobble-school-b)
                    </option>
                    <option value="pobble-school-ab">
                      T&L + Moderation (pobble-school-ab)
                    </option>
                  </FormSelect>
                </FormCell>
                <FormCell>
                  <FormLabel>Assign an end date</FormLabel>
                  <FormInput
                    name="endDate"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.endDate}
                    placeholder={'Ex: 2022-08-30'}
                    validationError={touched.endDate && errors.endDate}
                  />
                </FormCell>
                <FormCell>
                  <Button
                    className={classes.submitButton}
                    disabled={!isValid}
                    type="submit"
                    tall>
                    Create and assign subscription
                  </Button>
                </FormCell>
              </FormRow>
            </form>
          )
        }}
      </Formik>
    )}
  </div>
)

export default injectSheet(styles)(SchoolDetailsPobbleSchool)
