import React from 'react'
import injectSheet from 'react-jss'

import styles from './add-school.styles.js'

import Button from 'pobble-components/dist/button'
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter
} from 'pobble-components/dist/modal'
import FormInput from 'pobble-components/dist/form-elements/form-input'

import { Formik } from 'formik'
import * as Yup from 'yup'

const requiredMsg = 'This field should not be empty'

const AddSchool = ({ classes, onSubmit, isOpen, onClose }) => (
  <Modal legacy isOpen={isOpen}>
    <ModalHeader>Add a school</ModalHeader>
    <Formik
      initialValues={{
        title: '',
        urn: '',
        city: '',
        county: '',
        country: '',
        postcode: '',
        address: ''
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().required(requiredMsg),
        urn: Yup.string().required(requiredMsg),
        city: Yup.string().required(requiredMsg),
        country: Yup.string().required(requiredMsg)
      })}
      onSubmit={({ title, urn, city, county, country, postcode, address }) =>
        onSubmit({ title, urn, city, county, country, postcode, address })
      }>
      {props => {
        const {
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          isValid,
          touched
        } = props
        return (
          <form onSubmit={handleSubmit}>
            <ModalContent>
              <label htmlFor="title" className={classes.label}>
                Title <sup className={classes.sup}>*</sup>
              </label>
              <FormInput
                name="title"
                id="title"
                placeholder="Ex: St Peters Primary School"
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                validationError={touched.title && errors.title}
                className={classes.input}
              />
              <label htmlFor="urn" className={classes.label}>
                URN <sup className={classes.sup}>*</sup>
              </label>
              <FormInput
                name="urn"
                id="urn"
                placeholder="Ex: EN_123456789"
                value={values.urn}
                onChange={handleChange}
                onBlur={handleBlur}
                validationError={touched.urn && errors.urn}
                className={classes.input}
              />
              <label htmlFor="city" className={classes.label}>
                City <sup className={classes.sup}>*</sup>
              </label>
              <FormInput
                name="city"
                id="city"
                placeholder="Ex: Brussels"
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
                validationError={touched.city && errors.city}
                className={classes.input}
              />
              <label htmlFor="postcode" className={classes.label}>
                Postcode
              </label>
              <FormInput
                name="postcode"
                id="postcode"
                placeholder="Ex: NW3 4TL"
                value={values.postcode}
                onChange={handleChange}
                onBlur={handleBlur}
                validationError={touched.postcode && errors.postcode}
                className={classes.input}
              />
              <label htmlFor="address" className={classes.label}>
                Address
              </label>
              <FormInput
                name="address"
                id="address"
                placeholder="Ex: Chalcot Road, 23"
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
                validationError={touched.address && errors.address}
                className={classes.input}
              />
              <label htmlFor="county" className={classes.label}>
                County
              </label>
              <FormInput
                name="county"
                id="county"
                placeholder="Ex: Greater London"
                value={values.county}
                onChange={handleChange}
                onBlur={handleBlur}
                validationError={touched.county && errors.county}
                className={classes.input}
              />
              <label htmlFor="country" className={classes.label}>
                Country <sup className={classes.sup}>*</sup>
              </label>
              <FormInput
                name="country"
                id="country"
                placeholder="Ex: UK"
                value={values.country}
                onChange={handleChange}
                onBlur={handleBlur}
                validationError={touched.country && errors.country}
                className={classes.input}
              />
            </ModalContent>
            <ModalFooter className={classes.footer}>
              <Button simple action={onClose}>
                Cancel
              </Button>
              <Button type="submit" disabled={!isValid}>
                Save
              </Button>
            </ModalFooter>
          </form>
        )
      }}
    </Formik>
  </Modal>
)

export default injectSheet(styles)(AddSchool)
