import React, { Component, Fragment } from 'react'
import injectSheet from 'react-jss'

import styles from './comments-list.styles.js'

import CommentEntry from '../comment-entry/comment-entry.container.js'
import LoadMoreButton from '../load-more-button/load-more-button.container'

import { Table, HeadCell } from 'pobble-components/dist/table'
import Spinner from 'pobble-components/dist/spinner'

class CommentsList extends Component {
  componentWillMount() {
    if (this.props.isOnlyPobbler) window.location = '/schools'
  }

  componentDidMount() {
    this.props.onMount()
  }

  componentDidUpdate(prevProps) {
    this.props.onUpdate(prevProps)
  }

  render() {
    const {
      classes,
      showLoading,
      comments,
      noComments,
      noCommentsMatchingFilters,
      hideLoadMore
    } = this.props
    return showLoading ? (
      <Spinner className={classes.spinner} />
    ) : noComments ? (
      <span>No comments</span>
    ) : (
      <Fragment>
        {!noCommentsMatchingFilters && (
          <Table>
            <thead>
              <tr>
                <HeadCell className={classes.cell}>
                  <span>Date</span>
                </HeadCell>
                <HeadCell className={classes.cell}>Comment</HeadCell>
                <HeadCell className={classes.cell}>By</HeadCell>
                <HeadCell className={classes.cell}>Work ID</HeadCell>
                <HeadCell className={classes.cell}>Status</HeadCell>
                <HeadCell className={classes.cell} />
              </tr>
            </thead>
            <tbody>
              {comments.map(comment => (
                <CommentEntry
                  key={comment.id}
                  id={comment.id}
                  date={comment.created_at}
                  body={comment.content}
                  work={comment.work}
                  userType={comment.commentor_type}
                  userName={
                    comment.account &&
                    (comment.account.display_name || comment.account.full_name)
                  }
                  status={comment.status}
                  authorId={comment.account && comment.account.id}
                />
              ))}
            </tbody>
          </Table>
        )}
        {noCommentsMatchingFilters && (
          <span>No comments matching those criteria.</span>
        )}
        {!hideLoadMore && <LoadMoreButton />}
      </Fragment>
    )
  }
}

export default injectSheet(styles)(CommentsList)
