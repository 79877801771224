import { connect } from 'react-redux'
import RemoveAvatar from './remove-avatar'
import { removeAvatar } from '../../../../../../stores/user-details/user-details.actions'
import { withRouter } from 'react-router'

const mapDispatchToProps = (dispatch, { match }) => ({
  onClick: () => dispatch(removeAvatar(match.params.id))
})

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(RemoveAvatar)
)
