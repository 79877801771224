import { apiClient } from 'pobble-components/dist/api'

const apiGetOrganisations = params =>
  apiClient.get(`v3/admin/school_organisations`, { params })

const apiGetOrganisation = (id, params) =>
  apiClient.get(`v3/admin/school_organisations?id=${id}`, { params })

const apiCreateOrganisation = data => {
  return apiClient.post(`v3/admin/school_organisations`, data)
}

const apiDeleteOrganisation = id =>
  apiClient.delete(`v3/admin/school_organisations/${id}`)

const apiEditingOrganisation = (id, data) => {
  return apiClient.put(`v3/admin/school_organisations/${id}`, data)
}

const apiAddToOrganisation = (id, data) => {
  return apiClient.post(
    `v3/admin/schools/${id}/assign_school_organisation`,
    data
  )
}

const apiRemoveFromOrganisation = (id, data) => {
  return apiClient.post(
    `v3/admin/schools/${id}/unassign_school_organisation`,
    data
  )
}

export {
  apiGetOrganisations,
  apiGetOrganisation,
  apiCreateOrganisation,
  apiDeleteOrganisation,
  apiEditingOrganisation,
  apiAddToOrganisation,
  apiRemoveFromOrganisation
}
