import { hover } from 'pobble-components/dist/jss-mixins'

export default ({ COLORS }) => ({
  actionCell: {
    textAlign: 'right'
  },
  fileName: {
    display: 'inline-block',
    textDecoration: 'none',
    color: COLORS.TEXT.LIGHT_GREY.NORMAL,
    transition: 'color 0.3s',
    ...hover({
      color: COLORS.BLUE.NORMAL
    })
  },
  spinner: {
    fontSize: '20px !important',
    margin: '10px 10px',
    display: 'inline-flex !important'
  },
  pendingRow: {
    '& td': {
      position: 'relative',
      '&:after': {
        content: '""',
        position: 'absolute',
        left: '0',
        top: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.5)'
      }
    }
  }
})
