const styles = () => ({
  cell: {
    textAlign: 'left'
  },
  spinner: {
    fontSize: '20px'
  },
  pendingAddition: {
    opacity: '.5',
    pointerEvents: 'none'
  },
  title: {
    fontSize: '16px'
  },
  container: {
    background: '#f8f8f8',
    border: '1px solid #e5e5e5',
    borderRadius: '4px',
    padding: '10px 20px',
    marginBottom: '50px',
    '&:after': {
      display: 'block',
      content: '""',
      height: '1px',
      background: '#e5e5e5',
      position: 'relative',
      top: '38px'
    }
  }
})

export default styles
