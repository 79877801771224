import {
  apiGetCampaigns,
  apiGetCampaign,
  apiCreateCampaign,
  apiDeleteCampaign,
  apiEditingCampaign
} from '../../common/api/campaigns'

const fetchCampaigns = params => ({
  requestPrefix: 'CAMPAIGNS:FETCHING',
  request: () =>
    apiGetCampaigns({
      include: 'campaign.details,campaign.image',
      ...params
    })
})

const fetchCampaign = (id, params) => ({
  requestPrefix: 'CAMPAIGN:FETCHING',
  params: id,
  request: () =>
    apiGetCampaign(id, {
      include: 'campaign.details,campaign.image',
      ...params
    })
})

const createCampaign = params => ({
  requestPrefix: 'CAMPAIGNS:CREATING',
  request: () => apiCreateCampaign(params)
})

const deleteCampaign = campaignId => ({
  requestPrefix: 'CAMPAIGNS:DELETING',
  params: { campaignId },
  request: () => apiDeleteCampaign(campaignId)
})

const editCampaign = (campaignId, data) => ({
  requestPrefix: 'CAMPAIGNS:EDITING',
  params: { campaignId },
  request: () => apiEditingCampaign(campaignId, data)
})

export {
  fetchCampaigns,
  fetchCampaign,
  createCampaign,
  deleteCampaign,
  editCampaign
}
