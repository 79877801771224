const styles = ({ COLORS }) => ({
  navigationLink: {
    display: 'block',
    width: '100%',
    textDecoration: 'none',
    color: '#555',
    padding: '8px 20px',
    borderLeft: 'transparent solid 8px',
    '&:hover': {
      borderLeftColor: COLORS.BLUE.EXTRA_FADED
    }
  },
  navigationLinkActive: {
    fontWeight: 'bold',
    borderLeftColor: COLORS.BLUE.NORMAL
  },
  navigationLinkCounter: {
    float: 'right',
    padding: '0px 4px',
    borderRadius: '4px',
    backgroundColor: COLORS.RED.NORMAL,
    color: '#fff',
    fontWeight: 'bold'
  },
  navigationLinkDisabled: {
    opacity: '.3',
    pointerEvents: 'none'
  }
})

export default styles
