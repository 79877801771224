import { connect } from 'react-redux'
import WorkDetailsForm from './work-details-form'
import { withRouter } from 'react-router-dom'
import dateFns from 'date-fns'
import {
  isWorkFetching,
  isWorkBeingUpdated,
  getWork
} from '../../../../stores/work-details/work-details.selectors'
import {
  fetchWork,
  updateWork,
  publishWork,
  unpublishWork,
  flagWork,
  unflagWork
} from '../../../../stores/work-details/work-details.actions'
import { errorHandler } from 'pobble-components/dist/error-handler'

const mapStateToProps = state => {
  const work = getWork(state)
  return {
    notFound: !getWork(state).id,
    showLoading: isWorkFetching(state),
    isWorkBeingUpdated: isWorkBeingUpdated(state),
    id: work.id,
    title: work.title || '',
    description: work.description || '',
    student: (work.student_type && work.student_type.account.full_name) || '',
    created_at: dateFns.format(work.created_at, 'MM/DD/YYYY H:s') || '',
    status: work.status,
    flag: work.flag,
    delivered_at:
      (work.lesson &&
        work.lesson.delivered &&
        work.lesson.delivered_at &&
        dateFns.format(work.lesson.delivered_at, 'MM/DD/YYYY H:s')) ||
      '-',
    uploaded_by:
      (work.media &&
        work.media.length &&
        work.media[0].uploaded_by &&
        work.media[0].uploaded_by.full_name) ||
      '-'
  }
}

const mapDispatchToProps = (dispatch, { match }) => ({
  onMount: () => dispatch(fetchWork(match.params.id)).catch(errorHandler),
  onWorkUpdate: (id, work) =>
    dispatch(updateWork(match.params.id, work)).catch(errorHandler),
  onWorkPublish: () =>
    dispatch(publishWork(match.params.id)).catch(errorHandler),
  onWorkUnpublish: () =>
    dispatch(unpublishWork(match.params.id)).catch(errorHandler),
  onWorkFlag: () => dispatch(flagWork(match.params.id)).catch(errorHandler),
  onWorkUnflag: () => dispatch(unflagWork(match.params.id)).catch(errorHandler)
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(WorkDetailsForm)
)
