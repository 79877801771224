import React, { Component, Fragment } from 'react'
import injectSheet from 'react-jss'

import styles from './students-list.styles.js'

import Student from '../student/student.container.js'

import { Table, HeadCell } from 'pobble-components/dist/table'
import Spinner from 'pobble-components/dist/spinner'
import { Pagination } from 'pobble-components/dist/pagination'

class SchoolStudentsList extends Component {
  componentDidMount() {
    this.props.onMount()
  }
  render() {
    const {
      classes,
      showLoading,
      students,
      noStudents,
      pagination,
      onPageChange
    } = this.props
    return showLoading ? (
      <Spinner className={classes.spinner} />
    ) : noStudents ? (
      <span>No students</span>
    ) : (
      <Fragment>
        <Table>
          <thead>
            <tr>
              <HeadCell className={classes.cell}>Name</HeadCell>
              <HeadCell>Year</HeadCell>
              <HeadCell>Groups</HeadCell>
              <HeadCell>Permission</HeadCell>
              <HeadCell />
            </tr>
          </thead>
          <tbody>
            {students.map(student => (
              <Student key={student.account.id} id={student.account.id} />
            ))}
          </tbody>
        </Table>
        <div className={classes.pagination}>
          <Pagination onPageChange={onPageChange} data={pagination} />
        </div>
      </Fragment>
    )
  }
}

export default injectSheet(styles)(SchoolStudentsList)
