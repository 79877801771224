export default ({ COLORS }) => ({
  greyOut: {
    color: '#ececec'
  },
  showIcon: {
    width: '24px',
    height: '24px',
    color: COLORS.BLUE.FADED,
    cursor: 'pointer'
  },
  center: {
    textAlign: 'center'
  },
  link: {
    color: COLORS.BLUE.NORMAL,
    textDecoration: 'none',
    fontWeight: 'bold'
  }
})
