import React from 'react'
import styles from './user-details-children.styles'
import injectSheet from 'react-jss'
import Section from '../../../../shared/details/section/section'
import ChildrenTable from './children-table/children-table.container'

const UserDetailsChildren = ({ classes, noChildren, pending }) => (
  <Section title="Children" pending={pending}>
    {!noChildren && <ChildrenTable />}
    {noChildren && (
      <p className={classes.noChildren}>This user has no children in pobble</p>
    )}
  </Section>
)

export default injectSheet(styles)(UserDetailsChildren)
