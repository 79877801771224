import { connect } from 'react-redux'
import Campaign from './campaign'
import { getCampaign } from '../../../../../stores/campaigns/campaigns.selectors'
import { deleteCampaign } from '../../../../../stores/campaigns/campaigns.actions'
import { withRouter } from 'react-router'
import { errorHandler } from 'pobble-components/dist/error-handler'

const mapStateToProps = (state, { id }) => {
  const campaign = getCampaign(state, id)
  return {
    title: campaign.title || '-',
    date: campaign.date || '-',
    active: campaign.active || '-'
  }
}

const mapDispatchToProps = dispatch => ({
  onDelete: campaignId =>
    dispatch(deleteCampaign(campaignId)).catch(errorHandler)
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Campaign)
)
