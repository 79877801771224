import React from 'react'
import styles from './upload-avatar.styles'
import injectSheet from 'react-jss'
import FormFile from 'pobble-components/dist/form-elements/form-file'
import Button from 'pobble-components/dist/button'

const UploadAvatar = ({ classes, onChange }) => (
  <FormFile accept=".jpg,.png" onChange={onChange}>
    <Button span tall wide secondary className={classes.button}>
      Upload avatar
    </Button>
  </FormFile>
)

export default injectSheet(styles)(UploadAvatar)
