import React from 'react'
import styles from './user-details-email.styles'
import injectSheet from 'react-jss'
import Section from '../../../../shared/details/section/section'
import FormRow from '../../../../shared/details/form-row/form-row'
import FormCell from '../../../../shared/details/form-cell/form-cell'
import FormLabel from '../../../../shared/details/form-label/form-label'
import FormInput from 'pobble-components/dist/form-elements/form-input'
import { Formik } from 'formik'
import Button from 'pobble-components/dist/button'
import classNames from 'classnames'

const UserDetailsEmail = ({ classes, pending, email, emailId, onSubmit }) => (
  <Formik
    onSubmit={onSubmit}
    initialValues={{
      emailId: emailId || '',
      email: email || ''
    }}>
    {formikProps => {
      const {
        values,
        handleBlur,
        handleChange,
        handleSubmit,
        isValid
      } = formikProps
      return (
        <form
          onSubmit={handleSubmit}
          className={classNames(classes.form, { [classes.pending]: pending })}>
          <Section
            title="Email"
            renderHeader={() => (
              <Button
                className={classes.submitButton}
                disabled={!isValid}
                type="submit"
                tall>
                Save changes
              </Button>
            )}>
            <FormRow>
              <FormCell>
                <FormLabel>
                  This user will receive a confirmation email
                </FormLabel>
                <FormInput
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
              </FormCell>
            </FormRow>
          </Section>
        </form>
      )
    }}
  </Formik>
)

export default injectSheet(styles)(UserDetailsEmail)
