import { connect } from 'react-redux'
import DocumentEntry from './document-entry'
import { getStudentDocuments } from '../../../../../../../stores/student-details/student-details.selectors'
import { format } from 'date-fns'
import { withRouter } from 'react-router-dom'

const mapStateToProps = (state, { id }) => {
  const permissionSlip = getStudentDocuments(state, id)[id]
  return {
    fileName: permissionSlip.file_name || 'File',
    url: permissionSlip.document_url,
    uploadedDate: permissionSlip.created_at
      ? format(permissionSlip.created_at, 'D MMMM YYYY')
      : ''
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(DocumentEntry)
)
