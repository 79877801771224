import { connect } from 'react-redux'
import CommentsList from './comments-list'
import {
  getAllComments,
  areCommentsFetching,
  lessCommentsThanLimit,
  getFetchCommentsRequest,
  getFetchCommentsRequestInitiator,
  getServerParams
} from '../../../../stores/comments/comments.selectors'
import { getFilteredComments } from '../../../../stores/comments-filter/comments-filter.selectors'
import { fetchComments } from '../../../../stores/comments/comments.actions'
import { withMe } from 'pobble-components/dist/pobble-user'

const mapStateToProps = (state, { me }) => {
  const filteredComments = getFilteredComments(state)
  const req = getFetchCommentsRequest(state)
  const initiator = getFetchCommentsRequestInitiator(state)
  return {
    noComments: !getAllComments(state).length,
    noCommentsMatchingFilters: !filteredComments.length,
    showLoading: initiator === 'SEARCH' && areCommentsFetching(state),
    comments: filteredComments,
    hideLoadMore:
      !(req && initiator === 'LOAD_MORE') && lessCommentsThanLimit(state),
    params: getServerParams(state),
    isOnlyPobbler:
      me.account.pobbler_type &&
      (me.account.pobbler_type.pobbler_type && !me.account.pobbler_type.admin)
  }
}

const mapDispatchToProps = dispatch => ({ dispatch })

const mergeProps = (stateProps, { dispatch }, ownProps) => ({
  ...ownProps,
  ...stateProps,
  onMount: () => dispatch(fetchComments(stateProps.params, 'INITIAL')),
  onUpdate: prevProps => {
    if (prevProps.params !== stateProps.params) {
      const initiator =
        prevProps.params.limit !== stateProps.params.limit
          ? 'LOAD_MORE'
          : 'SEARCH'
      dispatch(fetchComments(stateProps.params, initiator))
    }
  }
})

export default withMe(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(CommentsList)
)
