import React, { Component } from 'react'
import injectSheet from 'react-jss'
import styles from './school-students.styles.js'

import Paper from 'pobble-components/dist/paper'

import Container from '../../shared/container/container.jsx'
import Main from '../../shared/main/main.jsx'

import SchoolStudentsList from './students-list/students-list.container.js'
import SectionTitle from '../../shared/section-title/section-title.jsx'

class SchoolsStudents extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    const { classes, sectionTitle } = this.props
    return (
      <Container>
        <Main>
          <Paper className={classes.container}>
            <SectionTitle title={sectionTitle} />
            <SchoolStudentsList />
          </Paper>
        </Main>
      </Container>
    )
  }
}

export default injectSheet(styles)(SchoolsStudents)
