import { connect } from 'react-redux'
import LoadMoreButton from './load-more-button'
import { loadMoreWorks } from '../../../../../stores/works/works.actions'
import {
  getFetchWorksRequest,
  getFetchWorksRequestInitiator
} from '../../../../../stores/works/works.selectors'

const mapStateToProps = state => ({
  pending:
    getFetchWorksRequest(state).pending &&
    getFetchWorksRequestInitiator(state) === 'LOAD_MORE'
})

const mapDispatchToProps = dispatch => ({
  onLoadMore: () => dispatch(loadMoreWorks())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoadMoreButton)
