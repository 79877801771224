import {
  apiAccountDetails,
  apiUploadAvatar,
  apiRemoveAvatar,
  apiCreateSubscription,
  apiCreateGroupSubscription,
  apiAssignModerationAddon,
  apiTransferGroupSubscription
} from '../../common/api/profile'

import {
  apiUpdateAccountDetails,
  apiUpdateAccountEmail,
  apiPromotePobbler,
  apiDemotePobbler,
  apiRedactAccount
} from '../../common/api/admin'

import {
  apiPromoteTeacher,
  apiDemoteTeacher,
  apiDeleteTeacher,
  apiDeclinePendingTeacher
} from '../../common/api/teacher'

import { apiUnassignParent } from '../../common/api/parent-management'

const fetchUser = userId => ({
  requestPrefix: 'USER_DETAILS:FETCH',
  params: { userId },
  request: () =>
    apiAccountDetails(userId, {
      include:
        'account.names,account.avatar,account.signin_details,account.timestamps,account.email_identity.email,account.note,account.pobbler_type,account.teacher_type.school.names,account.teacher_type.status,account.pending_teacher_type.school.names,account.parent_types,account.parent_type.student_type.account.names,account.parent_type.status,account.subscriptions'
    })
})

const updateAccountDetails = (userId, data) => ({
  requestPrefix: 'USER_DETAILS:UPDATE',
  params: { userId },
  request: () =>
    apiUpdateAccountDetails(userId, data, {
      include: 'account.names'
    })
})

const updateAccountEmail = (userId, data) => ({
  requestPrefix: 'USER_DETAILS:UPDATE',
  params: { userId },
  request: () => apiUpdateAccountEmail(userId, data)
})

const uploadAvatar = (userId, img) => ({
  requestPrefix: 'USER_DETAILS:UPLOAD_AVATAR',
  params: { userId },
  request: () => apiUploadAvatar(userId, img)
})

const removeAvatar = userId => ({
  requestPrefix: 'USER_DETAILS:REMOVE_AVATAR',
  params: { userId },
  request: () => apiRemoveAvatar(userId)
})

const promoteTeacher = teacherId => ({
  requestPrefix: 'USER_DETAILS:PROMOTE_TEACHER',
  params: { teacherId },
  request: () => apiPromoteTeacher(teacherId)
})

const demoteTeacher = teacherId => ({
  requestPrefix: 'USER_DETAILS:DEMOTE_TEACHER',
  params: { teacherId },
  request: () => apiDemoteTeacher(teacherId)
})

const deleteTeacher = (schoolid, teacherId) => ({
  requestPrefix: 'USER_DETAILS:DELETE_TEACHER',
  request: () => apiDeleteTeacher(schoolid, teacherId)
})

const declineTeacher = teacherId => ({
  requestPrefix: 'USER_DETAILS:DECLINE_TEACHER',
  request: () => apiDeclinePendingTeacher(teacherId)
})

const unassignParent = (parentId, studentId) => ({
  requestPrefix: 'USER_DETAILS:UNASSIGN_PARENT',
  params: { studentId: studentId },
  request: () =>
    apiUnassignParent({ parent_id: parentId, student_id: studentId })
})

const promotePobbler = userId => ({
  requestPrefix: 'USER_DETAILS:PROMOTE_POBBLER',
  request: () => apiPromotePobbler(userId)
})

const promoteAdmin = userId => ({
  requestPrefix: 'USER_DETAILS:PROMOTE_ADMIN',
  request: () => apiPromotePobbler(userId, { admin: true })
})

const demoteAdmin = userId => ({
  requestPrefix: 'USER_DETAILS:DEMOTE_ADMIN',
  request: () =>
    apiDemotePobbler(userId).then(() =>
      apiPromotePobbler(userId, { admin: false })
    )
})

const demotePobbler = userId => ({
  requestPrefix: 'USER_DETAILS:DEMOTE_POBBLER',
  request: () => apiDemotePobbler(userId)
})

const createSubscription = (userId, data) => ({
  requestPrefix: 'USER_DETAILS:CREATE_SUBSCRIPTION',
  request: () => apiCreateSubscription(userId, data)
})

const assignModerationAddon = (userId, data) => ({
  requestPrefix: 'USER_DETAILS:ASSIGN_ADDON',
  request: () => apiAssignModerationAddon(userId, data)
})

const createGroupSubscription = (userId, data) => ({
  requestPrefix: 'USER_DETAILS:CREATE_GROUP_SUBSCRIPTION',
  request: () => apiCreateGroupSubscription(userId, data)
})

const redactAccount = userId => ({
  requestPrefix: 'USER_DETAILS:REDACT',
  request: () => apiRedactAccount(userId)
})

const transferGroupSubscription = (userId, data) => ({
  requestPrefix: 'USER_DETAILS:TRANSFER_GROUP_SUBSCRIPTION',
  request: () => apiTransferGroupSubscription(userId, data)
})

export {
  fetchUser,
  updateAccountDetails,
  updateAccountEmail,
  uploadAvatar,
  removeAvatar,
  promoteTeacher,
  demoteTeacher,
  deleteTeacher,
  declineTeacher,
  unassignParent,
  promotePobbler,
  demotePobbler,
  promoteAdmin,
  demoteAdmin,
  createSubscription,
  createGroupSubscription,
  redactAccount,
  assignModerationAddon,
  transferGroupSubscription
}
