import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import PendingTeacher from './pending-teacher'
import { getPendingTeacher } from '../../../../../stores/school-teachers/school-teachers.selectors'
import {
  approveTeacher,
  disapproveTeacher
} from '../../../../../stores/school-teachers/school-teachers.actions'
import { errorHandler } from 'pobble-components/dist/error-handler'

import dateFns from 'date-fns'

const mapStateToProps = (state, { id }) => {
  const pendingTeacher = getPendingTeacher(state, id)
  return {
    id: pendingTeacher.account.id,
    name: pendingTeacher.account.full_name,
    email: pendingTeacher.account.email_identity.email,
    signUp:
      dateFns.format(pendingTeacher.account.created_at, 'MM/DD/YYYY H:s') || ''
  }
}

const mapDispatchToProps = dispatch => ({
  onApprove: teacherId =>
    dispatch(approveTeacher(teacherId)).catch(errorHandler),
  onDisapprove: teacherId =>
    dispatch(disapproveTeacher(teacherId)).catch(errorHandler)
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PendingTeacher)
)
