import React, { Component } from 'react'
import injectSheet from 'react-jss'

import styles from './comments-filter.styles.js'

import FormRadio from 'pobble-components/dist/form-elements/form-radio'
import FormInput from 'pobble-components/dist/form-elements/form-input'

class CommentsFilter extends Component {
  render() {
    const {
      classes,
      onFilter,
      statusFilter,
      searchValue,
      onSearchChange
    } = this.props
    return (
      <div className={classes.container}>
        <div className={classes.row}>
          <strong className={classes.title}>Show</strong>
          <div className={classes.filter}>
            <FormRadio
              name="status"
              id="commentsAll"
              onChange={() => onFilter(null)}
              checked={!statusFilter}
            />
            <label className={classes.label} htmlFor="commentsAll">
              All
            </label>
          </div>
          <div className={classes.filter}>
            <FormRadio
              name="status"
              id="commentsPending"
              onChange={() => onFilter('pending')}
              checked={statusFilter === 'pending'}
            />
            <label className={classes.label} htmlFor="commentsPending">
              Pending
            </label>
          </div>
          <div className={classes.filter}>
            <FormRadio
              name="status"
              id="commentsApproved"
              onChange={() => onFilter('approved')}
              checked={statusFilter === 'approved'}
            />
            <label className={classes.label} htmlFor="commentsApproved">
              Approved
            </label>
          </div>
          <div className={classes.filter}>
            <FormRadio
              name="status"
              id="commentsDeclined"
              onChange={() => onFilter('declined')}
              checked={statusFilter === 'declined'}
            />
            <label className={classes.label} htmlFor="commentsDeclined">
              Declined
            </label>
          </div>
          <div className={classes.filter}>
            <FormRadio
              name="status"
              id="commentsFlagged"
              onChange={() => onFilter('flagged')}
              checked={statusFilter === 'flagged'}
            />
            <label className={classes.label} htmlFor="commentsFlagged">
              Flagged
            </label>
          </div>
        </div>
        <span className={classes.separator} />
        <div className={classes.row}>
          <strong className={classes.title}>Work</strong>
          <FormInput
            onChange={onSearchChange}
            value={searchValue}
            placeholder="Url slug"
          />
        </div>
      </div>
    )
  }
}

export default injectSheet(styles)(CommentsFilter)
