import React, { Fragment } from 'react'
import injectSheet from 'react-jss'
import styles from './pending-teachers-list.styles.js'
import PendingTeacher from './pending-teacher/pending-teacher.container.js'
import { Table } from 'pobble-components/dist/table'

const PendingTeachersList = ({
  classes,
  pendingTeachers,
  noPendingTeachers
}) => (
  <Fragment>
    {!noPendingTeachers && (
      <div className={classes.container}>
        <h2 className={classes.title}>
          {pendingTeachers.length > 1
            ? `Approve these pending teachers?`
            : `Approve this pending teacher?`}
        </h2>
        <Table>
          <tbody>
            {pendingTeachers.map(pendingTeacher => (
              <PendingTeacher
                key={pendingTeacher.account.id}
                id={pendingTeacher.account.id}
              />
            ))}
          </tbody>
        </Table>
      </div>
    )}
  </Fragment>
)

export default injectSheet(styles)(PendingTeachersList)
