import { connect } from 'react-redux'
import OrganisationCreationForm from './organisation-creation-form'
import { createOrganisation } from '../../../../stores/organisations/organisations.actions'
import { errorHandler } from 'pobble-components/dist/error-handler'

const mapDispatchToProps = dispatch => ({
  onSubmit: values => {
    const data = {
      title: values.title,
      local_authority: values.local_authority
    }
    dispatch(createOrganisation(data))
      .then(() => (window.location = '/organisations'))
      .catch(errorHandler)
  }
})

export default connect(null, mapDispatchToProps)(OrganisationCreationForm)
