const styles = () => ({
  cell: {
    textAlign: 'left'
  },
  cellCenter: {
    textAlign: 'center'
  },
  spinner: {
    fontSize: '20px'
  },
  loading: {
    opacity: '.5',
    pointerEvents: 'none'
  }
})

export default styles
