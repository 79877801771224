import {
  getPresentations,
  apiApprovePresentation,
  apiDeclinePresentation
} from '../../common/api/presentation'

const fetchPresentations = (params, initiator) => ({
  requestPrefix: 'PRESENTATIONS:FETCHING',
  params: { initiator },
  request: () =>
    getPresentations({
      include:
        'presentation.names,presentation.account.names,presentation.timestamps,presentation.status,presentation.lesson,presentation.details,presentation.lesson.timestamps',
      ...params
    })
})

const loadMorePresentations = () => ({
  type: 'PRESENTATIONS:LOAD_MORE'
})

const approvePresentation = id => ({
  requestPrefix: 'PRESENTATIONS:APPROVE',
  params: { id },
  request: () => apiApprovePresentation(id)
})

const declinePresentation = id => ({
  requestPrefix: 'PRESENTATIONS:DECLINE',
  params: { id },
  request: () => apiDeclinePresentation(id)
})

export {
  fetchPresentations,
  approvePresentation,
  declinePresentation,
  loadMorePresentations
}
