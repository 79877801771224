import React from 'react'
import styles from './student-details-parents.styles'
import injectSheet from 'react-jss'
import Section from '../../../../shared/details/section/section'
import ParentsTable from './parents-table/parents-table.container'

const StudentDetailsParents = ({ classes, parents }) => (
  <Section title="Parents">
    {!!parents.length && <ParentsTable parents={parents} />}
    {!parents.length && (
      <p className={classes.noParents}>No parents have been invited yet</p>
    )}
  </Section>
)

export default injectSheet(styles)(StudentDetailsParents)
