const styles = ({ COLORS }) => ({
  container: {
    border: '1px solid #e5e5e5'
  },
  data: {
    '& dt': {
      fontWeight: 'bold',
      fontSize: '16px',
      margin: '14px 0 4px'
    }
  },
  title: {
    fontSize: '18px',
    borderBottom: '1px solid #e5e5e5',
    borderTop: '1px solid #e5e5e5',
    padding: '20px 0'
  },
  actions: {
    '& button': {
      marginRight: '10px'
    }
  },
  pendingActions: {
    opacity: '0.4',
    pointerEvents: 'none'
  },
  label: {
    margin: '0 0 0 5px'
  },
  select: {
    maxWidth: '340px'
  },
  selection: {
    marginTop: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  field: {
    minWidth: '340px',
    height: '40px',
    border: '1px solid #ddd',
    padding: '10px 15px',
    borderRadius: '4px',
    color: '#999',
    marginTop: '10px',
    '&::placeholder': {
      color: '#bbb'
    }
  },
  menu: {
    background: '#fff',
    boxShadow: '0 0 4px rgba(0,0,0,0.2)',
    position: 'absolute',
    width: '100%',
    zIndex: '100'
  },
  item: {
    padding: '5px 15px'
  },
  highlighted: {
    background: COLORS.BLUE.NORMAL,
    color: '#fff',
    cursor: 'pointer'
  }
})

export default styles
