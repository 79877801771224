import { combineReducers } from 'redux'
import {
  createReducer,
  createRequestReducer
} from 'pobble-components/dist/redux-helpers'
import { normalize, schema } from 'normalizr'

const normalizePresentations = data => {
  const presentation = new schema.Entity('presentations')
  return normalize(data, [presentation])
}

const DEFAULT_LIMIT = 100
const LOAD_MORE_STEP = 100

const defaultFilters = {
  currentLimit: DEFAULT_LIMIT
}

const PresentationsReducer = combineReducers({
  fetchPresentationsRequest: createRequestReducer('PRESENTATIONS:FETCHING'),
  filter: createReducer(defaultFilters, {
    'PRESENTATIONS:LOAD_MORE': state => ({
      ...state,
      currentLimit: state.currentLimit + LOAD_MORE_STEP
    }),
    'PRESENTATIONS:SET_SEARCH_FILTERS': (state, { filters }) => ({
      ...state,
      currentLimit: DEFAULT_LIMIT,
      ...filters
    }),
    'PRESENTATIONS:RESET_FILTERS': state => ({ ...defaultFilters })
  }),
  presentations: createReducer(null, {
    'PRESENTATIONS:FETCHING_REQUEST_SUCCESS': (state, { response }) => {
      return normalizePresentations(response.data.presentations)
    },
    'PRESENTATIONS:APPROVE_REQUEST_SUCCESS': (state, { response, params }) => ({
      ...state,
      entities: {
        presentations: {
          ...state.entities.presentations,
          [params.id]: {
            ...state.entities.presentations[params.id],
            approved: true
          }
        }
      }
    }),
    'PRESENTATIONS:DECLINE_REQUEST_SUCCESS': (state, { response, params }) => ({
      ...state,
      entities: {
        presentations: {
          ...state.entities.presentations,
          [params.id]: {
            ...state.entities.presentations[params.id],
            approved: false
          }
        }
      }
    })
  })
})

export default PresentationsReducer
