import React from 'react'
import styles from './student-details-component.styles'
import injectSheet from 'react-jss'
import StudentDetailsHead from './student-details-head/student-details-head.container'
import StudentDetailsInfo from './student-details-info/student-details-info.container'
import StudentDetailsPermissions from './student-details-permissions/student-details-permissions.container'
import StudentDetailsParents from './student-details-parents/student-details-parents.container'
import StudentDetailsDocuments from './student-details-documents/student-details-documents.container'
import StudentDetailsActions from './student-details-actions/student-details-actions.container'

const StudentDetailsComponent = ({ classes }) => (
  <div>
    <StudentDetailsHead />
    <StudentDetailsInfo />
    <StudentDetailsPermissions />
    <StudentDetailsDocuments />
    <StudentDetailsParents />
    <StudentDetailsActions />
  </div>
)

export default injectSheet(styles)(StudentDetailsComponent)
