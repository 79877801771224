import React, { Component } from 'react'
import injectSheet from 'react-jss'

import styles from './schools-filter.styles.js'

import AddSchool from '../add-school/add-school.container.js'

import Button from 'pobble-components/dist/button'
import FormInput from 'pobble-components/dist/form-elements/form-input'

class SchoolsFilter extends Component {
  constructor(props) {
    super(props)
    this.state = { openModal: false }
  }

  openModalHandler = () => {
    this.setState({ openModal: true })
  }

  closeModalHandler = () => {
    this.setState({ openModal: false })
  }

  render() {
    const { classes, searchValue, onSearchChange } = this.props
    return (
      <div className={classes.container}>
        <strong className={classes.title}>School</strong>
        <FormInput
          onChange={onSearchChange}
          value={searchValue}
          placeholder="Type for a school, URN, city"
        />
        <span className={classes.separator} />
        <Button tall action={this.openModalHandler}>
          Add
        </Button>
        <AddSchool
          isOpen={this.state.openModal}
          onClose={this.closeModalHandler}
        />
      </div>
    )
  }
}

export default injectSheet(styles)(SchoolsFilter)
