import { connect } from 'react-redux'
import StudentDetails from './student-details'
import { getFetchStudentRequest } from '../../../stores/student-details/student-details.selectors'
import { fetchStudent } from '../../../stores/student-details/student-details.actions'
import { withRouter } from 'react-router'
import { errorHandler } from 'pobble-components/dist/error-handler'

const mapStateToProps = state => {
  const fetchStudentRequest = getFetchStudentRequest(state)
  return {
    pending: fetchStudentRequest.pending
  }
}

const mapDispatchToProps = (dispatch, { match }) => ({
  onMount: () => dispatch(fetchStudent(match.params.id)).catch(errorHandler)
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(StudentDetails)
)
