import { connect } from 'react-redux'
import CommentBodyModal from './comment-body-modal'
import { updateCommentBody } from '../../../../stores/comments/comments.actions'
import { isCommentBeingUpdated } from '../../../../stores/comments/comments.selectors'
import { errorHandler } from 'pobble-components/dist/error-handler'

const mapStateToProps = (state, { id }) => ({
  showLoading: isCommentBeingUpdated(state, id)
})

const mapDispatchToProps = dispatch => ({
  onUpdate: (commentId, body) =>
    dispatch(updateCommentBody(commentId, body)).catch(errorHandler)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommentBodyModal)
