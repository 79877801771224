const styles = ({ COLORS }) => ({
  spinner: {
    fontSize: '20px'
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  buttons: {
    marginRight: '4px'
  },
  pending: {
    opacity: '.5',
    pointerEvents: 'none'
  },
  plainLink: {
    textDecoration: 'none',
    fontWeight: 'bold',
    color: COLORS.BLUE.NORMAL
  }
})

export default styles
