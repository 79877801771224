import React from 'react'
import styles from './presentation.styles'
import injectSheet from 'react-jss'
import { Cell } from 'pobble-components/dist/table'
import Button from 'pobble-components/dist/button'
import CircleStatus from 'pobble-components/dist/circle-status'
import { URLS } from 'pobble-components/dist/setup'

const Presentation = ({
  classes,
  id,
  createdAt,
  title,
  approved,
  lessonId,
  fullName,
  history,
  onPresentationApprove,
  onPresentationDecline
}) => (
  <tr>
    <Cell>{createdAt}</Cell>
    <Cell>{fullName}</Cell>
    <Cell>
      <a
        href={`${URLS.APP}/lessons/preview/${id}`}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.plainLink}>
        {title}
      </a>
    </Cell>
    <Cell className={classes.center}>
      <CircleStatus status={approved === null ? 'pending' : approved} />
    </Cell>
    <Cell className={classes.action}>
      <Button
        href={`${URLS.APP}/lessons/lesson/${lessonId}`}
        target="_blank"
        rel="noopener noreferrer"
        tall
        className={classes.button}>
        Edit
      </Button>
      <Button
        tall
        success
        disabled={approved}
        action={() => onPresentationApprove(id)}
        className={classes.button}>
        Approve
      </Button>
      <Button
        tall
        warning
        disabled={approved === false}
        className={classes.button}
        action={() => onPresentationDecline(id)}>
        Decline
      </Button>
    </Cell>
  </tr>
)

export default injectSheet(styles)(Presentation)
