import { combineReducers } from 'redux'
import {
  createReducer,
  createRequestReducer
} from 'pobble-components/dist/redux-helpers'
import { normalize, schema } from 'normalizr'

const normalizeDocuments = data => {
  const document = new schema.Entity('documents')
  return normalize(data, [document])
}

const StudentDetails = combineReducers({
  fetchStudentRequest: createRequestReducer('STUDENT_DETAILS:FETCH', {
    pending: true
  }),
  fetchStudentDocumentsRequest: createRequestReducer(
    'STUDENT_DETAILS:FETCH_DOCUMENTS',
    {
      pending: true
    }
  ),
  retireStudentRequest: createRequestReducer('STUDENT_DETAILS:RETIRE'),
  unretireStudentRequest: createRequestReducer('STUDENT_DETAILS:UNRETIRE'),
  student: createReducer(null, {
    'STUDENT_DETAILS:FETCH_REQUEST_SUCCESS': (state, { response }) =>
      response.data.account,
    'STUDENT_DETAILS:FETCH_DOCUMENTS_REQUEST_SUCCESS': (
      state,
      { response }
    ) => {
      return {
        ...state,
        student_type: {
          ...state.student_type,
          documents: normalizeDocuments(response.data.permission_slips)
        }
      }
    },
    'STUDENT_DETAILS:RETIRE_REQUEST_SUCCESS': state => {
      return {
        ...state,
        student_type: {
          ...state.student_type,
          retired: true
        }
      }
    },
    'STUDENT_DETAILS:UNRETIRE_REQUEST_SUCCESS': state => {
      return {
        ...state,
        student_type: {
          ...state.student_type,
          retired: false
        }
      }
    }
  })
})

export default StudentDetails
