import React from 'react'
import Tooltip from 'pobble-components/dist/tooltip'

const Groups = ({ children }) =>
  !children.length ? null : children.length === 1 ? (
    <span>{children[0].title}</span>
  ) : (
    <Tooltip content={children.map(group => group.title).join(', ')}>
      {children.length} groups
    </Tooltip>
  )

export default Groups
