import { createReducer } from 'pobble-components/dist/redux-helpers'

let defaultState = {
  search: ''
}

const SchoolTeachersFilter = createReducer(defaultState, {
  'SCHOOL_TEACHERS_FILTER:SET_SEARCH_VALUE': (state, { searchValue }) => ({
    ...state,
    search: searchValue
  })
})

export default SchoolTeachersFilter
