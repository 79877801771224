import { connect } from 'react-redux'
import OrganisationsList from './organisations-list'
import {
  getOrganisationsList,
  getFetchOrganisationsRequest
} from '../../../../stores/organisations/organisations.selectors'

const mapStateToProps = state => {
  const req = getFetchOrganisationsRequest(state)
  return {
    organisations: getOrganisationsList(state) || [],
    pending: req.pending
  }
}

export default connect(mapStateToProps, null)(OrganisationsList)
