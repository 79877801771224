import React from 'react'
import styles from './document-entry.styles'
import injectSheet from 'react-jss'
import UserName from 'pobble-components/dist/user-name'
import { Cell } from 'pobble-components/dist/table'

const DocumentEntry = ({ classes, fileName, uploadedDate, url }) => (
  <tr>
    <Cell>
      <a
        className={classes.fileName}
        href={url}
        target="_blank"
        rel="noopener noreferrer">
        <UserName>{fileName}</UserName>
      </a>
    </Cell>
    <Cell>{uploadedDate}</Cell>
  </tr>
)

export default injectSheet(styles)(DocumentEntry)
