import React from 'react'
import injectSheet from 'react-jss'

import styles from './comment-body-modal.styles.js'
import Button from 'pobble-components/dist/button'
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter
} from 'pobble-components/dist/modal'
import TextArea from 'pobble-components/dist/form-elements/form-text-area'
import { Formik } from 'formik'
import * as Yup from 'yup'

const CommentBodyModal = ({ classes, id, onUpdate, isOpen, onClose, body }) => (
  <Modal legacy isOpen={isOpen}>
    <ModalHeader>Edit a comment</ModalHeader>
    <Formik
      initialValues={{
        editedBody: body
      }}
      validationSchema={Yup.object().shape({
        editedBody: Yup.string().required('The comment should not be empty')
      })}
      onSubmit={({ editedBody }) => onUpdate(id, editedBody) && onClose()}>
      {props => {
        const { handleSubmit, handleChange, values, errors, isValid } = props
        return (
          <form onSubmit={handleSubmit}>
            <ModalContent>
              <TextArea
                validationError={errors.editedBody}
                name="editedBody"
                value={values.editedBody}
                onChange={handleChange}
              />
            </ModalContent>
            <ModalFooter className={classes.footer}>
              <Button simple action={onClose}>
                Cancel
              </Button>
              <Button type="submit" disabled={!isValid}>
                Save
              </Button>
            </ModalFooter>
          </form>
        )
      }}
    </Formik>
  </Modal>
)

export default injectSheet(styles)(CommentBodyModal)
