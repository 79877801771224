import React, { Component } from 'react'
import styles from './user-details.styles'
import injectSheet from 'react-jss'
import Container from '../../shared/container/container'
import Main from '../../shared/main/main'
import Paper from 'pobble-components/dist/paper'
import Spinner from 'pobble-components/dist/spinner'
import UserDetailsComponent from './user-details-component/user-details-component'

class UserDetails extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
    this.props.onMount()
  }
  render() {
    const { classes, pending } = this.props
    return (
      <Container>
        <Main>
          <Paper className={classes.paper}>
            {pending && <Spinner />}
            {!pending && <UserDetailsComponent />}
          </Paper>
        </Main>
      </Container>
    )
  }
}

export default injectSheet(styles)(UserDetails)
