import React, { Component } from 'react'
import injectSheet from 'react-jss'
import styles from './work-media-list.styles.js'
import WorkMedia from '../work-media/work-media.container'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

class WorkMediaList extends Component {
  constructor(props) {
    super(props)
    this.state = { media: this.props.media }
    this.onDragEnd = this.onDragEnd.bind(this)
  }

  onDragEnd(result) {
    const { onOrderingMedia } = this.props

    if (!result.destination) {
      return
    }

    const media = reorder(
      this.state.media,
      result.source.index,
      result.destination.index
    )

    this.setState({ media })
    onOrderingMedia(media.map(medium => medium.id))
  }

  render() {
    const { classes, media } = this.props
    return (
      <div>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={classes.container}>
                {this.state.media.map((medium, index) => (
                  <Draggable
                    key={medium.id}
                    draggableId={medium.id}
                    index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        <WorkMedia key={medium.id} id={medium.id} />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {!media.length && <p>This work has no media.</p>}
      </div>
    )
  }
}

export default injectSheet(styles)(WorkMediaList)
