import { connect } from 'react-redux'
import UserDetailsSchool from './user-details-school'
import {
  getPromoteTeacherRequest,
  getDemoteTeacherRequest,
  getUserSchools,
  getDeleteTeacherRequest,
  getDeclineTeacherRequest
} from '../../../../../stores/user-details/user-details.selectors'

const mapStateToProps = state => ({
  noSchool: !getUserSchools(state).result.length,
  pending:
    getPromoteTeacherRequest(state).pending ||
    getDemoteTeacherRequest(state).pending ||
    getDeleteTeacherRequest(state).pending ||
    getDeclineTeacherRequest(state).pending
})

export default connect(
  mapStateToProps,
  null
)(UserDetailsSchool)
