import React from 'react'
import styles from './student-details-info.styles'
import injectSheet from 'react-jss'
import Button from 'pobble-components/dist/button'
import Section from '../../../../shared/details/section/section'
import FormRow from '../../../../shared/details/form-row/form-row'
import FormCell from '../../../../shared/details/form-cell/form-cell'
import FormLabel from '../../../../shared/details/form-label/form-label'
import CellText from '../../../../shared/details/cell-text/cell-text'
import { openConfirmationDialog } from 'pobble-components/dist/modal/confirmation-dialog'

const StudentDetailsInfo = ({
  classes,
  dob,
  age,
  passcode,
  parentcode,
  school,
  groups,
  firstName,
  lastName,
  displayName,
  isRetired,
  onRetire,
  onUnretire
}) => (
  <Section
    title="General"
    renderHeader={() => (
      <div>
        <Button className={classes.button} disabled danger tall>
          Redact
        </Button>
        {!isRetired && (
          <Button
            className={classes.button}
            tall
            danger
            action={() =>
              openConfirmationDialog({
                bodyText: `Are you sure you want to retire this pupil?`,
                confirmText: 'Retire',
                action: onRetire
              })
            }>
            Retire
          </Button>
        )}
        {isRetired && (
          <Button className={classes.button} tall success action={onUnretire}>
            Unretire
          </Button>
        )}
      </div>
    )}>
    <FormRow>
      <FormCell>
        <FormLabel>Birthday</FormLabel>
        <CellText>{dob}</CellText>
      </FormCell>
      <FormCell>
        <FormLabel>Age group</FormLabel>
        <CellText>{age}</CellText>
      </FormCell>
      <FormCell>
        <FormLabel>Passcode</FormLabel>
        <CellText>{passcode}</CellText>
      </FormCell>
    </FormRow>
    <FormRow>
      <FormCell>
        <FormLabel>School</FormLabel>
        <CellText>{school}</CellText>
      </FormCell>
      <FormCell>
        <FormLabel>Groups</FormLabel>
        <CellText>{groups}</CellText>
      </FormCell>
      <FormCell>
        <FormLabel>Parent code</FormLabel>
        <CellText>{parentcode}</CellText>
      </FormCell>
    </FormRow>
  </Section>
)

export default injectSheet(styles)(StudentDetailsInfo)
