import { connect } from 'react-redux'
import StudentDetailsInfo from './student-details-info'
import { getStudent } from '../../../../../stores/student-details/student-details.selectors'
import {
  retireStudent,
  unretireStudent
} from '../../../../../stores/student-details/student-details.actions'
import { withRouter } from 'react-router'
import { errorHandler } from 'pobble-components/dist/error-handler'

const mapStateToProps = (state, { me }) => {
  const student = getStudent(state)
  return {
    dob: student.student_type.dob_day + '/' + student.student_type.dob_month,
    age: !student.student_type.year_group
      ? 'N/A'
      : student.student_type.year_group &&
        student.student_type.year_group.title,
    passcode: student.student_type.passcode,
    school: student.student_type.school && student.student_type.school.title,
    groups: student.student_type.groups.map(group => group.title).join(', '),
    firstName: student.first_name,
    lastName: student.last_name,
    displayName: student.display_name,
    isRetired: student.student_type.retired,
    parentcode: student.student_type.parent_code
  }
}

const mapDispatchToProps = (dispatch, { match }) => ({
  onRetire: () => dispatch(retireStudent(match.params.id)).catch(errorHandler),
  onUnretire: () =>
    dispatch(unretireStudent(match.params.id)).catch(errorHandler)
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StudentDetailsInfo)
)
