import { createSelector } from 'reselect'

const getNormalizedOrganisations = state =>
  state.OrganisationsStore.organisations

const getOrganisationsResult = createSelector(
  [getNormalizedOrganisations],
  organisations => (!organisations ? null : organisations.result)
)

const getOrganisations = createSelector(
  [getNormalizedOrganisations],
  organisations =>
    !organisations ? null : organisations.entities.organisations
)

const getOrganisationsList = createSelector(
  [getOrganisationsResult, getOrganisations],
  (result, organisations) =>
    !result ? null : result.map(id => organisations[id])
)

const getFetchOrganisationsRequest = state =>
  state.OrganisationsStore.fetchOrganisationsRequest

const getOrganisation = (state, id) => getOrganisations(state)[id]

const getEditedOrganisation = state =>
  state.OrganisationsStore.organisation || {}

const getFetchOrganisationRequest = state =>
  state.OrganisationsStore.fetchOrganisationRequest

export {
  getOrganisationsList,
  getOrganisationsResult,
  getOrganisation,
  getFetchOrganisationsRequest,
  getEditedOrganisation,
  getFetchOrganisationRequest
}
