import React from 'react'
import injectSheet from 'react-jss'
import styles from './schools.styles.js'

import Paper from 'pobble-components/dist/paper'

import Container from '../../shared/container/container.jsx'
import Main from '../../shared/main/main.jsx'

import SchoolsFilter from './schools-filter/schools-filter.container.js'
import SchoolsList from './schools-list/schools-list.container.js'
import SectionTitle from '../../shared/section-title/section-title.jsx'

const Schools = ({ classes }) => {
  return (
    <Container>
      <Main>
        <Paper className={classes.container}>
          <SectionTitle title="Schools" />
          <SchoolsFilter />
          <SchoolsList />
        </Paper>
      </Main>
    </Container>
  )
}

export default injectSheet(styles)(Schools)
