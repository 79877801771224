const styles = ({ COLORS }) => ({
  statusColor: {
    marginRight: '5px',
    width: '12px',
    height: '12px',
    borderRadius: '100%',
    display: 'inline-block'
  },
  amber: {
    backgroundColor: COLORS.ALERT.WARNING.NORMAL
  },
  red: {
    backgroundColor: COLORS.RED.NORMAL
  },
  green: {
    backgroundColor: COLORS.GREEN.NORMAL
  }
})

export default styles
