import React from 'react'
import styles from './school-details-sub.styles'
import injectSheet from 'react-jss'
import FormRow from '../../../shared/details/form-row/form-row'
import FormCell from '../../../shared/details/form-cell/form-cell'
import FormLabel from '../../../shared/details/form-label/form-label'
import FormInput from 'pobble-components/dist/form-elements/form-input'
import { Formik } from 'formik'
import Button from 'pobble-components/dist/button'

const SchoolDetailsPobbleSchool = ({
  classes,
  onSubmit,
  onUnset,
  id,
  subscriptionIds,
  subscriptions
}) => (
  <div>
    {id && (
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          subscriptionId: ''
        }}>
        {formikProps => {
          const {
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            isValid,
            touched,
            errors
          } = formikProps
          return (
            <form onSubmit={handleSubmit}>
              <FormRow>
                <FormCell>
                  <FormLabel>School subscription ID</FormLabel>
                  <FormInput
                    name="subscriptionId"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.subscriptionId}
                    placeholder={'Ex: 16BjhvSLzbeHgTN7'}
                    validationError={
                      touched.subscriptionId && errors.subscriptionId
                    }
                  />
                </FormCell>
                <FormCell>
                  <Button disabled={!isValid} type="submit" tall>
                    Assign subscription ID
                  </Button>
                </FormCell>
              </FormRow>
              {subscriptionIds && subscriptionIds.length > 0 && (
                <FormRow>
                  <FormCell>
                    <FormLabel>Currently assigned subscriptions:</FormLabel>
                    <ul>
                      {subscriptionIds.map(subscriptionId => (
                        <li key={subscriptionId} id={subscriptionId}>
                          {subscriptionId}
                        </li>
                      ))}
                    </ul>
                  </FormCell>
                </FormRow>
              )}

              {subscriptions && subscriptions.length > 0 && (
                <FormRow>
                  <FormCell>
                    <FormLabel>
                      Currently assigned school subscriptions:
                    </FormLabel>
                    <ul>
                      {subscriptions.map(subscription => (
                        <li
                          key={subscription.subscription_id}
                          id={subscription.subscription_id}>
                          <ul>
                            {Object.keys(subscription).map(key => (
                              <li>
                                {key}:{' '}
                                {subscription[key] &&
                                  subscription[key].toString()}
                              </li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </FormCell>
                </FormRow>
              )}
            </form>
          )
        }}
      </Formik>
    )}
  </div>
)

export default injectSheet(styles)(SchoolDetailsPobbleSchool)
