import { getSchool } from '../../common/api/school'

const fetchStudents = id => ({
  requestPrefix: 'SCHOOL_STUDENTS:FETCHING',
  request: () =>
    getSchool(id, {
      include:
        'school.names,school.student_types,school.student_types.id,school.student_type.status,school.student_type.account.names,school.student_type.account.student_type.year_group,school.student_type.account.student_type.year_group.order,school.student_type.account.student_type.year_group.names,school.student_type.account.student_type.groups,school.student_type.account.student_type.group.names,school.student_type.policy'
    })
})

export { fetchStudents }
