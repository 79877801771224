import { connect } from 'react-redux'
import LoadMoreButton from './load-more-button'
import { loadMorePresentations } from '../../../../../stores/presentations/presentations.actions'
import {
  getFetchPresentationsRequest,
  getFetchPresentationsRequestInitiator
} from '../../../../../stores/presentations/presentations.selectors'

const mapStateToProps = state => ({
  pending:
    getFetchPresentationsRequest(state).pending &&
    getFetchPresentationsRequestInitiator(state) === 'LOAD_MORE'
})

const mapDispatchToProps = dispatch => ({
  onLoadMore: () => dispatch(loadMorePresentations())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoadMoreButton)
