import { connect } from 'react-redux'
import Organisations from './organisations'
import { fetchOrganisations } from '../../../stores/organisations/organisations.actions'
import { getFetchOrganisationsRequest } from '../../../stores/organisations/organisations.selectors'

const mapStateToProps = state => ({
  pending: getFetchOrganisationsRequest(state).pending
})

const mapDispatchToProps = dispatch => ({
  onMount: () => dispatch(fetchOrganisations())
})

export default connect(mapStateToProps, mapDispatchToProps)(Organisations)
