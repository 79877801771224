import { connect } from 'react-redux'
import SchoolTeachers from './school-teachers'
import { getTeachersSchool } from '../../../stores/school-teachers/school-teachers.selectors'

const mapStateToProps = state => {
  const school = getTeachersSchool(state)
  return {
    sectionTitle: school ? `${school} - Teachers` : ''
  }
}

export default connect(
  mapStateToProps,
  null
)(SchoolTeachers)
