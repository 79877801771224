import { connect } from 'react-redux'
import UsersFilter from './users-filter'
import { getFilter } from '../../../../../stores/users/users.selectors'
import {
  resetFilters,
  setSearchFilters
} from '../../../../../stores/users/users.actions'

const mapStateToProps = state => {
  const filter = getFilter(state)
  return {
    displayName: filter.displayName,
    firstName: filter.firstName,
    lastName: filter.lastName,
    email: filter.email
  }
}

const mapDispatchToProps = dispatch => ({
  onFiltersUpdate: values => dispatch(setSearchFilters(values)),
  onClearFilters: () => dispatch(resetFilters())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersFilter)
