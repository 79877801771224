import { connect } from 'react-redux'
import SchoolStudents from './school-students'
import {
  getStudentsSchool,
  getStudentsSchoolId
} from '../../../stores/school-students/school-students.selectors'

const mapStateToProps = state => {
  const school = getStudentsSchool(state)
  return {
    sectionTitle: school ? `${school} - Students` : '',
    schoolId: getStudentsSchoolId(state)
  }
}

export default connect(
  mapStateToProps,
  null
)(SchoolStudents)
