import React, { Component } from 'react'
import injectSheet from 'react-jss'

import styles from './teachers-filter.styles.js'

import FormInput from 'pobble-components/dist/form-elements/form-input'

class TeachersFilter extends Component {
  render() {
    const { classes, searchValue, onSearchChange } = this.props
    return (
      <div className={classes.container}>
        <div className={classes.row}>
          <strong className={classes.title}>School</strong>
          <FormInput
            onChange={onSearchChange}
            value={searchValue}
            placeholder="School name, URN"
          />
        </div>
      </div>
    )
  }
}

export default injectSheet(styles)(TeachersFilter)
