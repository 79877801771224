import React, { Component } from 'react'
import injectSheet from 'react-jss'

import styles from './student.styles.js'
import UserName from 'pobble-components/dist/user-name'
import { Cell } from 'pobble-components/dist/table'
import Button from 'pobble-components/dist/button'
import AgeGroup from 'pobble-components/dist/age-group'
import CircleStatus from 'pobble-components/dist/circle-status'

import Groups from './groups/groups'

class Student extends Component {
  render() {
    const {
      classes,
      id,
      name,
      year_group,
      groups,
      permission,
      history,
      showLoading
    } = this.props
    return (
      <tr className={showLoading ? classes.loading : null}>
        <Cell>
          <UserName>{name}</UserName>
        </Cell>
        <Cell className={classes.center}>
          {year_group && (
            <AgeGroup order={year_group.order}>{year_group.title}</AgeGroup>
          )}
        </Cell>
        <Cell className={classes.center}>
          <Groups>{groups}</Groups>
        </Cell>
        <Cell className={classes.center}>
          <CircleStatus status={permission} />
        </Cell>
        <Cell className={classes.action}>
          <Button
            action={() => history.push(history.location.pathname + '/' + id)}
            tall>
            Edit
          </Button>
        </Cell>
      </tr>
    )
  }
}

export default injectSheet(styles)(Student)
