import React from 'react'
import styles from './form-cell.styles'
import injectSheet from 'react-jss'
import classNames from 'classnames'

const FormCell = ({ classes, children, size = 'S' }) => (
  <div
    className={classNames(classes.container, {
      [classes.sizeS]: size === 'S',
      [classes.sizeM]: size === 'M',
      [classes.sizeL]: size === 'L'
    })}>
    {children}
  </div>
)

export default injectSheet(styles)(FormCell)
