import React, { Component } from 'react'
import injectSheet from 'react-jss'
import { withRouter, Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from 'yup'
import dateFns from 'date-fns'

import styles from './school-details-form.styles.js'

import FormLabel from '../../../shared/form-elements/form-label/form-label.jsx'
import FormFields from '../../../shared/form-elements/form-fields/form-fields.jsx'
import FormField from '../../../shared/form-elements/form-field/form-field.jsx'
import FormLine from '../../../shared/form-elements/form-line/form-line.jsx'
import Logo from '../logo/logo.container.js'
import AddLogo from '../add-logo/add-logo.container.js'
import Cover from '../cover/cover.container.js'
import AddCover from '../add-cover/add-cover.container.js'

import Spinner from 'pobble-components/dist/spinner'
import FormInput from 'pobble-components/dist/form-elements/form-input'
import TextArea from 'pobble-components/dist/form-elements/form-text-area'
import Button from 'pobble-components/dist/button'

class SchoolDetailsForm extends Component {
  componentDidMount() {
    const { onMount } = this.props
    onMount()
  }

  backToSchoolsList = () => this.props.history.push('/schools')

  render() {
    const {
      classes,
      showLoading,
      school,
      onSchoolUpdate,
      isSchoolBeingUpdated,
      notFound,
      isLogoUpdating,
      isCoverUpdating
    } = this.props
    const requiredMsg = 'This field should not be empty'

    return showLoading ? (
      <Spinner className={classes.spinner} />
    ) : notFound ? (
      <span>School not found</span>
    ) : (
      <Formik
        initialValues={{
          id: school.id,
          title: school.title || '',
          urn: school.urn || '',
          url_slug: school.url_slug || '',
          city: school.city || '',
          postcode: school.postcode || '',
          address: school.address || '',
          contact_person: school.contact_person || '',
          description: school.description || '',
          county: school.county || '',
          country: school.country || '',
          groupcallEstab:
            (school.groupcall && school.groupcall.school_estab) || ''
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().required(requiredMsg),
          city: Yup.string().required(requiredMsg),
          country: Yup.string().required(requiredMsg)
        })}
        onSubmit={({
          id,
          title,
          urn,
          city,
          postcode,
          address,
          contact_person,
          description,
          county,
          country,
          groupcallEstab
        }) =>
          onSchoolUpdate(id, {
            id,
            title,
            urn,
            city,
            postcode,
            address,
            contact_person,
            description,
            county,
            country,
            groupcallEstab
          })
        }>
        {props => {
          const {
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            errors,
            isValid,
            touched
          } = props
          return (
            <div>
              <form onSubmit={handleSubmit}>
                <header className={classes.header}>
                  <h2>{school.title}</h2>
                  <div>
                    <Link
                      to={`/schools/${school.id}/students`}
                      className={classes.navLink}>
                      <Button secondary tall>
                        Students
                      </Button>
                    </Link>
                    <Link
                      to={`/schools/${school.id}/teachers`}
                      className={classes.navLink}>
                      <Button secondary tall>
                        Team
                      </Button>
                    </Link>
                    <Link
                      to={`/schools/${school.id}/data`}
                      className={classes.navLink}>
                      <Button secondary tall>
                        Data
                      </Button>
                    </Link>
                  </div>
                </header>
                <FormFields>
                  <FormField>
                    <FormInput
                      type="hidden"
                      name="id"
                      title="id"
                      value={values.id}
                    />
                    <FormLabel label={'Title'} htmlFor={'title'} required />
                    <FormInput
                      name="title"
                      id="title"
                      placeholder="Ex: St Peters Primary School"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      validationError={touched.title && errors.title}
                    />
                  </FormField>
                  <FormField>
                    <FormLabel label={'URN'} htmlFor={'urn'} />
                    <FormInput
                      name="urn"
                      id="urn"
                      placeholder="Ex: EN_123456789"
                      value={values.urn}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      validationError={touched.urn && errors.urn}
                    />
                  </FormField>
                </FormFields>
                <FormFields>
                  <FormField>
                    <FormLabel label={'Url slug'} htmlFor={'url_slug'} />
                    <FormInput
                      name="url_slug"
                      id="url_slug"
                      placeholder="Ex: school-name-123"
                      disabled
                      value={values.url_slug}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      validationError={touched.url_slug && errors.url_slug}
                    />
                  </FormField>
                  <FormField>
                    <FormLabel label={'Pobbler'} htmlFor={'pobbler'} />
                    <FormInput
                      name="contact_person"
                      id="contact_person"
                      placeholder="Ex: Simon"
                      value={values.contact_person}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      validationError={
                        touched.contact_person && errors.contact_person
                      }
                    />
                  </FormField>
                </FormFields>
                <FormFields>
                  <FormField>
                    <FormLabel
                      label={'GroupCall Estab'}
                      htmlFor={'groupcallEstab'}
                    />
                    <FormInput
                      name="groupcallEstab"
                      id="groupcallEstab"
                      placeholder="Ex: abc12345"
                      value={values.groupcallEstab}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      validationError={
                        touched.groupcallEstab && errors.groupcallEstab
                      }
                    />
                  </FormField>
                </FormFields>
                <FormLine />
                <FormFields>
                  <FormField>
                    <FormLabel label={'County'} htmlFor={'county'} />
                    <FormInput
                      name="county"
                      id="county"
                      placeholder="Ex: Greater London"
                      value={values.county}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      validationError={touched.county && errors.county}
                    />
                  </FormField>
                  <FormField>
                    <FormLabel label={'Country'} htmlFor={'country'} required />
                    <FormInput
                      name="country"
                      id="country"
                      placeholder="Ex: UK"
                      value={values.country}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      validationError={touched.country && errors.postcode}
                    />
                  </FormField>
                </FormFields>
                <FormFields>
                  <FormField>
                    <FormLabel label={'City'} htmlFor={'city'} required />
                    <FormInput
                      name="city"
                      id="city"
                      placeholder="Ex: Brussels"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      validationError={touched.city && errors.city}
                    />
                  </FormField>
                  <FormField>
                    <FormLabel label={'Postcode'} htmlFor={'postcode'} />
                    <FormInput
                      name="postcode"
                      id="postcode"
                      placeholder="Ex: NW1 4TL"
                      value={values.postcode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      validationError={touched.postcode && errors.postcode}
                    />
                  </FormField>
                </FormFields>
                <FormFields>
                  <FormField>
                    <FormLabel label={'Address'} htmlFor={'address'} />
                    <FormInput
                      name="address"
                      id="address"
                      placeholder="Ex: Chalcot Road, 12"
                      value={values.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      validationError={touched.address && errors.address}
                    />
                  </FormField>
                  <FormField>
                    <FormLabel label={'Description'} htmlFor={'description'} />
                    <TextArea
                      name="description"
                      id="description"
                      placeholder="Ex: 'Unlocking our potential for life-long-learning'. St Peter’s Primary School is a mixed school. It has around 338 students from 3 to 11 years old."
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      validationError={
                        touched.description && errors.description
                      }
                    />
                  </FormField>
                </FormFields>
                <div className={classes.footer}>
                  <Button simple tall action={this.backToSchoolsList}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    tall
                    disabled={!isValid || isSchoolBeingUpdated}>
                    {isSchoolBeingUpdated ? 'Updating...' : 'Update'}
                  </Button>
                </div>
              </form>
              <div>
                <FormLine />
                <FormFields>
                  <FormField className={isLogoUpdating ? classes.pending : ''}>
                    <FormLabel label={'Logo'} htmlFor={'logo'} />
                    <Logo logo={school.logo_url} />
                    <AddLogo />
                  </FormField>
                  <FormField className={isCoverUpdating ? classes.pending : ''}>
                    <FormLabel label={'Cover'} htmlFor={'cover'} />
                    <Cover cover={school.background_image_url} />
                    <AddCover />
                  </FormField>
                </FormFields>
                <FormLine />
                <FormFields>
                  <FormField>
                    <FormLabel label={'Wonde'} htmlFor={'wonde'} />
                    <div>
                      <strong>{school.wonde && school.wonde.status}</strong>
                    </div>
                  </FormField>
                  <FormField>
                    <FormLabel label={'Number of pupils'} htmlFor={'pupils'} />
                    <div>
                      <strong>{school.number_of_students}</strong>
                    </div>
                  </FormField>
                </FormFields>
                <FormFields>
                  <FormField>
                    <FormLabel label={'MIS'} htmlFor={'mis'} />
                    <div>
                      <strong>
                        {(school.wonde && school.wonde.mis) || '-'}
                      </strong>
                    </div>
                  </FormField>
                  <FormField>
                    <FormLabel
                      label={'Last update at'}
                      htmlFor={'lastUpdate'}
                    />
                    <div>
                      <strong>
                        {school.wonde &&
                          dateFns.format(
                            school.wonde.last_update_at,
                            'MM/DD/YYYY HH:ss'
                          )}
                      </strong>
                    </div>
                  </FormField>
                </FormFields>
                <FormFields>
                  <FormField>
                    <FormLabel label={'Wonde ID'} htmlFor={'wondeId'} />
                    <div>
                      <strong>
                        {(school.wonde && school.wonde.wonde_id) || '-'}
                      </strong>
                    </div>
                  </FormField>
                  <FormField>
                    <FormLabel
                      label={'Pulling parents'}
                      htmlFor={'pullParents'}
                    />
                    <div>
                      <strong>
                        {school.wonde && school.wonde.pull_parents
                          ? 'Yes'
                          : 'No'}
                      </strong>
                    </div>
                  </FormField>
                </FormFields>
                <FormLine />
                <FormFields>
                  <FormField>
                    <FormLabel label={'GroupCall'} htmlFor={'groupcall'} />
                    <div>
                      <strong>
                        {school.groupcall && school.groupcall.on ? 'On' : 'Off'}
                      </strong>
                    </div>
                  </FormField>
                  <FormField>
                    <FormLabel label={'MIS'} htmlFor={'mis'} />
                    <div>
                      <strong>
                        {(school.groupcall && school.groupcall.mis) || '-'}
                      </strong>
                    </div>
                  </FormField>
                </FormFields>
                <FormFields>
                  <FormField>
                    <FormLabel
                      label={'Last update at'}
                      htmlFor={'lastUpdate'}
                    />
                    <div>
                      <strong>
                        {school.groupcall &&
                          dateFns.format(
                            school.groupcall.finished_at,
                            'MM/DD/YYYY HH:ss'
                          )}
                      </strong>
                    </div>
                  </FormField>
                  <FormField>
                    <FormLabel label={'Estab'} htmlFor={'estab'} />
                    <div>
                      <strong>
                        {school.groupcall && school.groupcall.school_estab}
                      </strong>
                    </div>
                  </FormField>
                </FormFields>
                <FormLine />
              </div>
            </div>
          )
        }}
      </Formik>
    )
  }
}

export default withRouter(injectSheet(styles)(SchoolDetailsForm))
