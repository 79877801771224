import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Parent from './parent-entry'

const mapStateToProps = (state, { parent }) => {
  return {
    id: parent.account.id,
    email: parent.account.email_identity.email,
    permission: parent.permission
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Parent)
)
