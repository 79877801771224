import { createReducer } from 'pobble-components/dist/redux-helpers'

let defaultState = {
  search: ''
}

const SchoolStudentsFilter = createReducer(defaultState, {
  'SCHOOL_STUDENTS_FILTER:SET_SEARCH_VALUE': (state, { searchValue }) => ({
    ...state,
    search: searchValue
  })
})

export default SchoolStudentsFilter
