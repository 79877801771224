import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import injectSheet from 'react-jss'
import styles from './school.styles.js'
import { Cell } from 'pobble-components/dist/table'
import Button from 'pobble-components/dist/button'
import CircleStatus from 'pobble-components/dist/circle-status'
import dateFns from 'date-fns'

class School extends Component {
  render() {
    const {
      classes,
      id,
      title,
      urn,
      city,
      country,
      contact_person,
      pobbleSchool,
      paidUntil,
      nbOfTeachers,
      seats,
      lastLogin,
      showLoading
    } = this.props
    return (
      <tr className={showLoading ? classes.loading : null}>
        <Cell>{id}</Cell>
        <Cell>
          <Link to={`/schools/${id}`} className={classes.plainLink}>
            {title}
          </Link>
        </Cell>
        <Cell>{urn || 'n/a'}</Cell>
        <Cell>{city}</Cell>
        <Cell>{country}</Cell>
        <Cell>{contact_person || 'n/a'}</Cell>
        <Cell center>
          <CircleStatus status={pobbleSchool} />
        </Cell>
        <Cell>{paidUntil || '-'}</Cell>
        <Cell className={classes.center}>{nbOfTeachers}</Cell>
        <Cell className={classes.center}>{seats}</Cell>
        <Cell className={classes.center}>
          {lastLogin ? dateFns.format(lastLogin, 'MM/DD/YY') : '-'}
        </Cell>
        <Cell className={classes.action}>
          <Link to={`/schools/${id}/data`} className={classes.button}>
            <Button secondary tall>
              Data
            </Button>
          </Link>
          <Link to={`/schools/${id}/students`} className={classes.button}>
            <Button secondary tall>
              Students
            </Button>
          </Link>
          <Link to={`/schools/${id}/teachers`} className={classes.button}>
            <Button secondary tall>
              Team
            </Button>
          </Link>
          <Link to={`/schools/${id}`} className={classes.button}>
            <Button tall>Edit</Button>
          </Link>
        </Cell>
      </tr>
    )
  }
}

export default injectSheet(styles)(School)
