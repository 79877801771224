import { combineReducers } from 'redux'
import {
  createReducer,
  createRequestReducer
} from 'pobble-components/dist/redux-helpers'
import { normalize, schema } from 'normalizr'

const normalizeOrganisations = data => {
  const organisation = new schema.Entity('organisations')
  return normalize(data, [organisation])
}

const OrganisationsReducer = combineReducers({
  fetchOrganisationsRequest: createRequestReducer('ORGANISATIONS:FETCHING'),
  fetchOrganisationRequest: createRequestReducer('ORGANISATION:FETCHING'),
  organisations: createReducer(null, {
    'ORGANISATIONS:FETCHING_REQUEST_SUCCESS': (state, { response }) =>
      normalizeOrganisations(response.data.school_organisations),
    'ORGANISATIONS:DELETING_REQUEST_SUCCESS': (state, { response, params }) => {
      return {
        ...state,
        result: state.result.filter(id => id !== params.organisationId)
      }
    }
  }),
  organisation: createReducer(null, {
    'ORGANISATION:FETCHING_REQUEST_SUCCESS': (state, { response }) =>
      response.data.school_organisations
  })
})

export default OrganisationsReducer
