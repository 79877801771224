import React from 'react'
import styles from './school-table.styles'
import injectSheet from 'react-jss'
import { Table, HeadCell } from 'pobble-components/dist/table'
import SchoolEntry from './school-entry/school-entry.container'

const SchoolTable = ({ classes, schoolsIds }) => (
  <Table className={classes.table}>
    <thead>
      <tr>
        <HeadCell>School</HeadCell>
        <HeadCell>Account type</HeadCell>
        <HeadCell className={classes.schoolLeadCell}>School leader</HeadCell>
      </tr>
    </thead>
    <tbody>
      {schoolsIds.map(schoolId => (
        <SchoolEntry key={schoolId} id={schoolId} />
      ))}
    </tbody>
  </Table>
)

export default injectSheet(styles)(SchoolTable)
