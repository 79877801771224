import React, { useState } from 'react'
import injectSheet from 'react-jss'
import styles from './other.styles.js'

import Paper from 'pobble-components/dist/paper'

import Container from '../../shared/container/container.jsx'
import Main from '../../shared/main/main.jsx'

import SectionTitle from '../../shared/section-title/section-title.jsx'
import Button from 'pobble-components/dist/button'
import FormFile from 'pobble-components/dist/form-elements/form-file'
import { apiUploadSuccessCriteria } from '../../../common/api/admin.js'
import { errorHandler } from 'pobble-components/dist/error-handler'
import { toast } from 'pobble-components/dist/toast'

const UploadSuccessCriteria = () => {
  const [pending, setPending] = useState(false)
  return (
    <FormFile
      onChange={files => {
        if (!files.length) return
        setPending(true)
        apiUploadSuccessCriteria(files[0])
          .then(() => {
            setPending(false)
            toast.success(
              'Success criteria suggestions document has been uploaded'
            )
          })
          .catch(e => {
            setPending(false)
            errorHandler(e)
          })
      }}
      accept=".csv">
      <Button pending={pending} span tall secondary>
        {!pending && 'Upload success criteria suggestions'}
        {pending && 'Uploading...'}
      </Button>
    </FormFile>
  )
}

const Other = ({ classes }) => {
  return (
    <Container>
      <Main>
        <Paper className={classes.container}>
          <SectionTitle title="Other" />
          <section>
            <UploadSuccessCriteria />
          </section>
        </Paper>
      </Main>
    </Container>
  )
}

export default injectSheet(styles)(Other)
