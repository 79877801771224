import { combineReducers } from 'redux'
import CommentsReducer from './stores/comments/comments.reducer'
import CommentsFilter from './stores/comments-filter/comments-filter.reducer.js'
import TeachersReducer from './stores/teachers/teachers.reducer'
import TeachersFilter from './stores/teachers-filter/teachers-filter.reducer.js'
import SchoolsReducer from './stores/schools/schools.reducer'
import SchoolsFilter from './stores/schools-filter/schools-filter.reducer.js'
import SchoolReducer from './stores/school/school.reducer'
import SchoolTeachersReducer from './stores/school-teachers/school-teachers.reducer'
import SchoolTeachersFilter from './stores/school-teachers-filter/school-teachers-filter.reducer.js'
import SchoolStudentsReducer from './stores/school-students/school-students.reducer'
import SchoolRetireesReducer from './stores/school-retirees/school-students.reducer'
import SchoolStudentsFilter from './stores/school-students-filter/school-students-filter.reducer.js'
import StudentDetails from './stores/student-details/student-details.reducer'
import SchoolData from './stores/school-data/school-data.reducer'
import UsersReducer from './stores/users/users.reducer'
import UserDetailsReducer from './stores/user-details/user-details.reducer'
import WorksReducer from './stores/works/works.reducer'
import WorkDetailsReducer from './stores/work-details/work-details.reducer'
import CampaignsReducer from './stores/campaigns/campaigns.reducer'
import PresentationsReducer from './stores/presentations/presentations.reducer'
import OrganisationsReducer from './stores/organisations/organisations.reducer'

const Reducer = combineReducers({
  CommentsStore: CommentsReducer,
  CommentsFilter: CommentsFilter,
  TeachersStore: TeachersReducer,
  TeachersFilter: TeachersFilter,
  SchoolsStore: SchoolsReducer,
  SchoolsFilter: SchoolsFilter,
  SchoolStore: SchoolReducer,
  SchoolTeachersFilter: SchoolTeachersFilter,
  SchoolTeachersStore: SchoolTeachersReducer,
  SchoolStudentsFilter: SchoolStudentsFilter,
  SchoolStudentsStore: SchoolStudentsReducer,
  SchoolRetireesStore: SchoolRetireesReducer,
  SchoolDataStore: SchoolData,
  StudentDetails: StudentDetails,
  WorksStore: WorksReducer,
  WorkDetailsStore: WorkDetailsReducer,
  UsersStore: UsersReducer,
  UserDetails: UserDetailsReducer,
  CampaignsStore: CampaignsReducer,
  PresentationsStore: PresentationsReducer,
  OrganisationsStore: OrganisationsReducer
})

export default Reducer
