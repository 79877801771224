const styles = () => ({
  container: {
    fontWeight: 'bold',
    padding: '0',
    textAlign: 'left',
    height: 'auto'
  }
})

export default styles
