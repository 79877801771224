import React from 'react'
import styles from './campaigns-list.styles'
import injectSheet from 'react-jss'
import { Table, HeadCell } from 'pobble-components/dist/table'
import Campaign from './campaign/campaign.container'

const CampaignsList = ({ classes, campaigns, pending }) => (
  <div className={classes.container}>
    <Table className={classes.table}>
      <thead>
        <tr>
          <HeadCell>Title</HeadCell>
          <HeadCell>Date</HeadCell>
          <HeadCell>Active</HeadCell>
        </tr>
      </thead>
      <tbody>
        {campaigns.map(campaign => (
          <Campaign key={campaign.id} id={campaign.id} />
        ))}
      </tbody>
    </Table>
  </div>
)

export default injectSheet(styles)(CampaignsList)
