import { combineReducers } from 'redux'
import {
  createReducer,
  createRequestReducer
} from 'pobble-components/dist/redux-helpers'

const SchoolData = combineReducers({
  fetchSchoolRequest: createRequestReducer('SCHOOL_DATA:FETCH', {
    pending: true
  }),
  connectSchoolRequest: createRequestReducer('SCHOOL_DATA:CONNECT'),
  disconnectSchoolRequest: createRequestReducer('SCHOOL_DATA:DISCONNECT'),
  pullSchoolRequest: createRequestReducer('SCHOOL_DATA:PULL'),
  school: createReducer(null, {
    'SCHOOL_DATA:FETCH_REQUEST_SUCCESS': (state, { response }) =>
      response.data.school,
    'SCHOOL_DATA:PULLING_REQUEST_SUCCESS': (state, { params, response }) => {
      return {
        ...state,
        wonde: response.data.school.wonde
      }
    },
    'SCHOOL_DATA:CONNECT_REQUEST_SUCCESS': (state, { params, response }) => {
      return {
        ...state,
        wonde: response.data.school.wonde
      }
    },
    'SCHOOL_DATA:DISCONNECT_REQUEST_SUCCESS': (state, { params, response }) => {
      return {
        ...state,
        wonde: response.data.school.wonde
      }
    },
    'SCHOOL_DATA:PARENTS_REQUEST_SUCCESS': (state, { params, response }) => {
      return {
        ...state,
        wonde: {
          ...state.wonde,
          pull_parents: params.wonde_pull_parents
        }
      }
    },
    'SCHOOL_DATA:CONNECT_GC_REQUEST_SUCCESS': (state, { params, response }) => {
      return {
        ...state,
        groupcall: response.data.school.groupcall
      }
    },
    'SCHOOL_DATA:DISCONNECT_GC_REQUEST_SUCCESS': (
      state,
      { params, response }
    ) => {
      return {
        ...state,
        groupcall: response.data.school.groupcall
      }
    }
  })
})

export default SchoolData
