const styles = ({ COLORS }) => ({
  sup: {
    color: COLORS.RED
  },
  input: {
    marginBottom: '20px'
  },
  label: {
    marginBottom: '4px',
    display: 'block'
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
})

export default styles
