const styles = ({ COLORS }) => ({
  suggestion: {
    border: '1px solid #e5e5e5',
    background: '#f9f9f9',
    padding: '20px'
  },
  link: {
    textDecoration: 'none',
    fontWeight: 'bold',
    color: COLORS.BLUE.NORMAL,
    display: 'block',
    textAlign: 'center',
    marginTop: '10px'
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  line: {
    border: 'none',
    borderTop: '1px solid #e5e5e5',
    margin: '30px 0'
  },
  field: {
    width: '100%',
    height: '40px',
    border: '1px solid #ddd',
    padding: '10px 15px',
    borderRadius: '4px',
    color: '#999',
    marginTop: '10px'
  },
  menu: {
    background: '#fff',
    boxShadow: '0 0 4px rgba(0,0,0,0.2)'
  },
  item: {
    padding: '5px 15px'
  },
  highlighted: {
    background: COLORS.BLUE.NORMAL,
    color: '#fff'
  }
})

export default styles
