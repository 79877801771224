import { getSchool } from '../../common/api/school'
import {
  apiPromoteTeacher,
  apiDemoteTeacher,
  apiAddTeacher,
  apiDeleteTeacher,
  apiDeclinePendingTeacher,
  apiApprovePendingTeacher,
  apiResendInvitation
} from '../../common/api/teacher'

const fetchTeachers = id => ({
  requestPrefix: 'SCHOOL_TEACHERS:FETCHING',
  request: () =>
    getSchool(id, {
      include:
        'school.names,school.teacher_types,school.teacher_types.id,school.teacher_type.status,school.teacher_type.account.names,school.teacher_type.account.timestamps,school.teacher_type.account.email_identity.email,school.teacher_type.account.avatar,school.teacher_type.account.signin_details,school.teacher_type.account.subscriptions,school.pending_teacher_types,school.pending_teacher_type.account,school.pending_teacher_type.account.names,school.pending_teacher_type.account.email_identity.email,school.pending_teacher_type.account.timestamps'
    })
})

const promoteTeacher = teacherId => ({
  requestPrefix: 'SCHOOL_TEACHERS:PROMOTE',
  params: { teacherId },
  request: () => apiPromoteTeacher(teacherId)
})

const demoteTeacher = teacherId => ({
  requestPrefix: 'SCHOOL_TEACHERS:DEMOTE',
  params: { teacherId },
  request: () => apiDemoteTeacher(teacherId)
})

const setTeachers = teachers => ({
  type: 'SCHOOL_TEACHERS:SET',
  data: teachers
})

const addTeacher = (schoolId, teacher) => ({
  requestPrefix: 'SCHOOL_TEACHERS:ADD',
  params: { teacher },
  request: () => apiAddTeacher(schoolId, teacher.email)
})

const deleteTeacher = (schoolid, teacherId) => ({
  requestPrefix: 'SCHOOL_TEACHERS:DELETE',
  params: { schoolid, teacherId },
  request: () => apiDeleteTeacher(schoolid, teacherId)
})

const approveTeacher = teacher => ({
  requestPrefix: 'SCHOOL_TEACHERS:APPROVE',
  params: { teacher },
  request: () => apiApprovePendingTeacher(teacher.id)
})

const disapproveTeacher = teacherId => ({
  requestPrefix: 'SCHOOL_TEACHERS:DISAPPROVE',
  params: { teacherId },
  request: () => apiDeclinePendingTeacher(teacherId)
})

const resendInvitation = teacherId => ({
  requestPrefix: 'SCHOOL_TEACHERS:RESEND_INVITATION',
  request: () => apiResendInvitation(teacherId)
})

export {
  setTeachers,
  fetchTeachers,
  addTeacher,
  promoteTeacher,
  demoteTeacher,
  deleteTeacher,
  approveTeacher,
  disapproveTeacher,
  resendInvitation
}
