import { apiSearchAllSchools } from '../../common/api/school'

const setSearchValue = searchValue => ({
  type: 'SCHOOL_FILTER:SET_SEARCH_VALUE',
  searchValue
})

const searchSchools = searchTerm => ({
  requestPrefix: 'SCHOOLS:SEARCHING',
  params: { searchTerm },
  request: () =>
    apiSearchAllSchools({
      include: 'school.names,school.urn,school.paid',
      search_term: searchTerm,
      limit: 50
    })
})

export { setSearchValue, searchSchools }
