const styles = ({ COLORS }) => ({
  action: {
    textAlign: 'right'
  },
  loading: {
    opacity: '.5',
    pointerEvents: 'none',
    transition: '300ms opacity ease-in-out'
  },
  center: {
    textAlign: 'center'
  }
})

export default styles
