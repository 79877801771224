import { connect } from 'react-redux'
import StudentDetailsPermissions from './student-details-permissions'
import { withRouter } from 'react-router'
import { getStudent } from '../../../../../stores/student-details/student-details.selectors'

const mapStateToProps = (state, { match }) => {
  const student = getStudent(state, match.params.id)
  return {
    pupilName: student.full_name,
    permission: student.student_type.policy.can_publish
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(StudentDetailsPermissions)
)
