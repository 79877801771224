import { connect } from 'react-redux'
import TeachersList from './teachers-list'
import {
  getAllTeachers,
  areTeachersFetching,
  lessTeachersThanLimit,
  getFetchTeachersRequest,
  getFetchTeachersRequestInitiator,
  getServerParams
} from '../../../../stores/teachers/teachers.selectors'
import { getFilteredTeachers } from '../../../../stores/teachers-filter/teachers-filter.selectors'
import { fetchTeachers } from '../../../../stores/teachers/teachers.actions'

const mapStateToProps = state => {
  const filteredTeachers = getFilteredTeachers(state)
  const req = getFetchTeachersRequest(state)
  const initiator = getFetchTeachersRequestInitiator(state)
  return {
    noTeachers: !getAllTeachers(state).length,
    noTeachersMatchingFilters: !filteredTeachers.length,
    showLoading: initiator === 'SEARCH' && areTeachersFetching(state),
    teachers: getAllTeachers(state),
    hideLoadMore:
      !(req && initiator === 'LOAD_MORE') && lessTeachersThanLimit(state),
    filterParams: getServerParams(state)
  }
}

const mapDispatchToProps = dispatch => ({ dispatch })

const mergeProps = (stateProps, { dispatch }, ownProps) => ({
  ...ownProps,
  ...stateProps,
  onMount: () => dispatch(fetchTeachers(stateProps.filterParams, 'INITIAL')),
  onUpdate: prevProps => {
    if (prevProps.filterParams !== stateProps.filterParams) {
      const initiator =
        prevProps.filterParams.limit !== stateProps.filterParams.limit
          ? 'LOAD_MORE'
          : 'SEARCH'
      dispatch(fetchTeachers(stateProps.filterParams, initiator))
    }
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(TeachersList)
