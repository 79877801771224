import { connect } from 'react-redux'
import AssignTeacher from './assign-teacher'
import { getTeacher } from '../../../../stores/teachers/teachers.selectors'

const mapStateToProps = (state, { id }) => {
  const teacher = getTeacher(state, id)
  return {
    email: teacher.email_identity.email,
    suggestedSchool: teacher.pending_teacher_type.description,
    suggestedSchoolLabel:
      teacher.pending_teacher_type.description &&
      teacher.pending_teacher_type.description
        .split(',')
        .map(key => key && key.split(':'))[0][1]
        .trim()
  }
}

export default connect(mapStateToProps, null)(AssignTeacher)
