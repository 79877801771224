import React, { Component } from 'react'
import injectSheet from 'react-jss'
import styles from './cover.styles.js'
import Button from 'pobble-components/dist/button'

class Cover extends Component {
  render() {
    const { classes, onDelete, cover } = this.props
    return cover ? (
      <div className={classes.container}>
        <img src={cover} className={classes.image} width="70%" alt="Cover" />
        <Button simple action={() => onDelete()}>
          Remove this cover
        </Button>
      </div>
    ) : (
      <div className={classes.nothing}>No cover</div>
    )
  }
}

export default injectSheet(styles)(Cover)
