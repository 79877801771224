export default ({ FONT_SIZES, COLORS }) => ({
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    margin: '0',
    ...FONT_SIZES.HEADING_LARGE.STYLE
  },
  noDocuments: {
    margin: '30px 0',
    color: COLORS.TEXT.LIGHT_GREY.NORMAL
  }
})
