import { apiGetMe } from '../../common/api/profile'

const fetchMe = (id, data) => ({
  requestPrefix: 'ME:FETCHING',
  request: () =>
    apiGetMe({
      include:
        'account.names,account.teacher_type.school.names,account.finish_signup_status'
    })
})

export { fetchMe }
