import React from 'react'
import styles from './user.styles'
import injectSheet from 'react-jss'
import { Cell } from 'pobble-components/dist/table'
import CircleStatus from 'pobble-components/dist/circle-status'
import Tooltip from 'pobble-components/dist/tooltip'
import SignInIcon from 'react-icons/lib/fa/sign-in'
import { Link } from 'react-router-dom'

const User = ({
  classes,
  displayName,
  firstName,
  lastName,
  email,
  schools,
  pt,
  t,
  sl,
  subs,
  subStatus,
  onOpen,
  id,
  nbOfSeats,
  seatAssigned
}) => (
  <tr>
    <Cell>{displayName}</Cell>
    <Cell>{firstName}</Cell>
    <Cell>{lastName}</Cell>
    <Cell>{email}</Cell>
    <Cell>
      {schools.map(school => (
        <Link
          className={classes.link}
          key={school.id}
          to={`/schools/${school.id}`}>
          {school.title}
        </Link>
      ))}
    </Cell>
    <Cell className={classes.center}>
      {pt && (
        <Tooltip content="Pending teacher">
          <CircleStatus status={true} />
        </Tooltip>
      )}
      {!pt && <CircleStatus status={false} className={classes.greyOut} />}
    </Cell>
    <Cell className={classes.center}>
      {t && (
        <Tooltip content="Teacher">
          <CircleStatus status={true} />
        </Tooltip>
      )}
      {!t && <CircleStatus status={false} className={classes.greyOut} />}
    </Cell>
    <Cell className={classes.center}>
      {sl && (
        <Tooltip content="School leader">
          <CircleStatus status={true} />
        </Tooltip>
      )}
      {!sl && <CircleStatus status={false} className={classes.greyOut} />}
    </Cell>
    <Cell>
      {subs && (
        <Tooltip content={subs}>
          <CircleStatus status={subs === 'free-trial' ? 'pending' : true} />
        </Tooltip>
      )}
      {!subs && <CircleStatus status={false} className={classes.greyOut} />}
    </Cell>
    <Cell>
      {subStatus && (
        <Tooltip content={subStatus}>
          <CircleStatus
            status={subStatus === 'active' || subStatus === 'in_trial'}
          />
        </Tooltip>
      )}
    </Cell>
    <Cell>{nbOfSeats}</Cell>
    <Cell>
      {seatAssigned && (
        <Tooltip content="Assigned to a seat">
          <CircleStatus status={true} />
        </Tooltip>
      )}
      {!seatAssigned && (
        <CircleStatus status={false} className={classes.greyOut} />
      )}
    </Cell>
    <Cell className={classes.center}>
      <Link className={classes.link} to={`/users/${id}`}>
        <SignInIcon />
      </Link>
    </Cell>
  </tr>
)

export default injectSheet(styles)(User)
