export default {
  container: {
    background: '#f8f8f8',
    padding: '14px',
    borderRadius: '4px',
    marginBottom: '20px',
    border: '1px solid #e5e5e5'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    '&:last-child': {
      marginTop: '14px'
    }
  },
  labelRadio: {
    margin: '0 16px 0 10px',
    cursor: 'pointer'
  },
  labelInput: {
    margin: '0 16px',
    cursor: 'pointer',
    '&:first-child': {
      marginLeft: '0'
    }
  }
}
