const styles = () => ({
  cell: {
    textAlign: 'left'
  },
  spinner: {
    fontSize: '20px'
  }
})

export default styles
