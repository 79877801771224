import { connect } from 'react-redux'
import SchoolOrganisations from './school-organisations'
import { fetchOrganisations } from '../../../../stores/organisations/organisations.actions'
import {
  addToOrganisation,
  removeFromOrganisation
} from '../../../../stores/school/school.actions'
import {
  getFetchOrganisationsRequest,
  getOrganisationsList,
  getOrganisationsResult
} from '../../../../stores/organisations/organisations.selectors'
import { getSchool } from '../../../../stores/schools/schools.selectors'

import { withRouter } from 'react-router'
import { errorHandler } from 'pobble-components/dist/error-handler'

const mapStateToProps = state => {
  return {
    pending: getFetchOrganisationsRequest(state).pending,
    organisations: getOrganisationsList(state) || [],
    school: getSchool(state),
    organisationsIds: getOrganisationsResult(state) || []
  }
}

const mapDispatchToProps = (dispatch, { match }) => ({
  onMount: () => dispatch(fetchOrganisations()),
  onOrganisationChange: (e, organisationId) =>
    e.target.checked
      ? dispatch(
          addToOrganisation(match.params.id, {
            school_organisation_id: organisationId
          })
        )
          .then(() => window.location.reload())
          .catch(errorHandler)
      : dispatch(
          removeFromOrganisation(match.params.id, {
            school_organisation_id: organisationId
          })
        )
          .then(() => window.location.reload())
          .catch(errorHandler)
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SchoolOrganisations)
)
