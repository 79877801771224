import React from 'react'
import styles from './pending-teacher.styles'
import injectSheet from 'react-jss'
import { Cell } from 'pobble-components/dist/table'
import { Link } from 'react-router-dom'
import Button from 'pobble-components/dist/button'

const PendingTeacher = ({
  classes,
  id,
  signUp,
  name,
  email,
  onApprove,
  onDisapprove
}) => (
  <tr>
    <Cell>{signUp}</Cell>
    <Cell>
      <Link to={`/users/${id}`} className={classes.link}>
        {name}
      </Link>
    </Cell>
    <Cell>{email}</Cell>
    <Cell className={classes.action}>
      <Button
        success
        tall
        className={classes.button}
        action={() => onApprove({ id, name, email })}>
        Yes
      </Button>
      <Button
        danger
        tall
        className={classes.button}
        action={() => onDisapprove(id)}>
        No
      </Button>
    </Cell>
  </tr>
)

export default injectSheet(styles)(PendingTeacher)
