import React from 'react'
import injectSheet from 'react-jss'

import styles from './section-title.styles.js'

const SectionTitle = ({ classes, title }) => {
  return <h1 className={classes.heading}>{title}</h1>
}

export default injectSheet(styles)(SectionTitle)
