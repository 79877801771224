import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Student from './student'

import { getStudent } from '../../../../stores/school-students/school-students.selectors'

const mapStateToProps = (state, { id }) => {
  const student = getStudent(state, id)
  return {
    name: student.account.full_name,
    year_group: student.account.student_type.year_group,
    groups: student.account.student_type.groups,
    permission: student.policy.can_publish
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Student)
)
