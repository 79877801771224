import { createReducer } from 'pobble-components/dist/redux-helpers'
import { normalize, schema } from 'normalizr'

const normalizeComments = data => {
  const comment = new schema.Entity('comments')
  return normalize(data, [comment])
}

const DEFAULT_LIMIT = 30
const LOAD_MORE_STEP = 30

let defaultState = {
  loading: false,
  currentLimit: DEFAULT_LIMIT,
  commentsUpdating: {},
  comments: {
    result: [],
    entities: { comments: {} }
  }
}

const handleCommentRequestError = (state, { params }) => ({
  ...state,
  commentsUpdating: {
    ...state.commentsUpdating,
    [params.commentId]: true
  }
})

const CommentsStore = createReducer(defaultState, {
  'COMMENTS:FETCHING_REQUEST': state => ({
    ...state,
    loading: true
  }),
  'COMMENTS:FETCHING_REQUEST_SUCCESS': (state, { response }) => ({
    ...state,
    loading: false,
    comments: normalizeComments(response.data.comments)
  }),
  'COMMENTS:LOAD_MORE': state => ({
    ...state,
    currentLimit: state.currentLimit + LOAD_MORE_STEP
  }),
  'COMMENT:APPROVE_REQUEST_SUCCESS': (state, { response, params }) => {
    return {
      ...state,
      commentsUpdating: {
        ...state.commentsUpdating,
        [params.commentId]: false
      },
      comments: {
        ...state.comments,
        entities: {
          comments: {
            ...state.comments.entities.comments,
            [response.data.comment.id]: {
              ...state.comments.entities.comments[response.data.comment.id],
              status: response.data.comment.status
            }
          }
        }
      }
    }
  },
  'COMMENT:APPROVE_REQUEST': (state, { params }) => ({
    ...state,
    commentsUpdating: {
      ...state.commentsUpdating,
      [params.commentId]: true
    }
  }),
  'COMMENT:DISAPPROVE_REQUEST_SUCCESS': (state, { response, params }) => {
    return {
      ...state,
      commentsUpdating: {
        ...state.commentsUpdating,
        [params.commentId]: false
      },
      comments: {
        ...state.comments,
        entities: {
          comments: {
            ...state.comments.entities.comments,
            [response.data.comment.id]: {
              ...state.comments.entities.comments[response.data.comment.id],
              status: response.data.comment.status
            }
          }
        }
      }
    }
  },
  'COMMENT:DISAPPROVE_REQUEST': (state, { params }) => ({
    ...state,
    commentsUpdating: {
      ...state.commentsUpdating,
      [params.commentId]: true
    }
  }),
  'COMMENT:UPDATE_BODY_REQUEST_SUCCESS': (state, { response, params }) => {
    return {
      ...state,
      commentsUpdating: {
        ...state.commentsUpdating,
        [params.commentId]: false
      },
      comments: {
        ...state.comments,
        entities: {
          comments: {
            ...state.comments.entities.comments,
            [response.data.comment.id]: {
              ...state.comments.entities.comments[response.data.comment.id],
              content: response.data.comment.content
            }
          }
        }
      }
    }
  },
  'COMMENT:UPDATE_BODY_REQUEST': (state, { params }) => ({
    ...state,
    commentsUpdating: {
      ...state.commentsUpdating,
      [params.commentId]: true
    }
  }),
  'COMMENT:APPROVE_REQUEST_ERROR': handleCommentRequestError,
  'COMMENT:DISAPPROVE_REQUEST_ERROR': handleCommentRequestError,
  'COMMENT:UPDATE_BODY_REQUEST_ERROR': handleCommentRequestError
})

export default CommentsStore
