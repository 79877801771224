import { connect } from 'react-redux'
import AddSchool from './add-school'
import { addSchool } from '../../../../stores/schools/schools.actions'
import { errorHandler } from 'pobble-components/dist/error-handler'

const mapStateToProps = state => ({ state })

const mapDispatchToProps = dispatch => ({ dispatch })

const mergeProps = (stateProps, { dispatch }, ownProps) => ({
  ...stateProps,
  ...ownProps,
  onSubmit: school =>
    dispatch(addSchool(school)).catch(errorHandler) && ownProps.onClose()
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(AddSchool)
