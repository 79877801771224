import { combineReducers } from 'redux'
import {
  createReducer,
  createRequestReducer
} from 'pobble-components/dist/redux-helpers'
import { normalize, schema } from 'normalizr'

const normalizeUsers = data => {
  const user = new schema.Entity('users')
  return normalize(data, [user])
}

const DEFAULT_LIMIT = 20
const LOAD_MORE_STEP = 20

const defaultFilters = {
  currentLimit: DEFAULT_LIMIT,
  displayName: '',
  firstName: '',
  lastName: '',
  email: ''
}

const UsersReducer = combineReducers({
  fetchUsersRequest: createRequestReducer('USERS:FETCHING'),
  filter: createReducer(defaultFilters, {
    'USERS:LOAD_MORE': state => ({
      ...state,
      currentLimit: state.currentLimit + LOAD_MORE_STEP
    }),
    'USERS:SET_SEARCH_FILTERS': (state, { filters }) => ({
      ...state,
      currentLimit: DEFAULT_LIMIT,
      ...filters
    }),
    'USERS:RESET_FILTERS': state => ({ ...defaultFilters })
  }),
  users: createReducer(null, {
    'USERS:FETCHING_REQUEST_SUCCESS': (state, { response }) =>
      normalizeUsers(response.data.accounts)
  })
})

export default UsersReducer
