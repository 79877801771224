import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import App from './components/app/app.container'

import { Provider } from 'react-redux'
import logger from 'redux-logger'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducer'
import { requestMiddleware } from 'pobble-components/dist/redux-helpers'
import { ConfirmationDialog } from 'pobble-components/dist/modal/confirmation-dialog'
import 'normalize.css'
import { PobbleStyles } from 'pobble-components/dist/styles'

const store = createStore(
  rootReducer,
  applyMiddleware(requestMiddleware, thunk, logger)
)

ReactDOM.render(
  <Provider store={store}>
    <PobbleStyles>
      <Fragment>
        <App />
        <ConfirmationDialog />
      </Fragment>
    </PobbleStyles>
  </Provider>,
  document.getElementById('root')
)

// Lazy load external tools
setTimeout(() => import('pobble-components/dist/setup/external-tools'), 1000)
