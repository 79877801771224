import {
  searchForASchool,
  searchForAReviewSchool,
  apiAddSchool,
  apiSearchAllSchools
} from '../../common/api/school'

const fetchSchools = (params, initiator) => ({
  requestPrefix: 'SCHOOLS:FETCHING',
  params: { initiator },
  request: () =>
    searchForASchool({
      include:
        'school.names,school.urn,school.contact_details,school.address,school.wonde,school.paid,school.teacher_types,school.group_subs_seats,school.last_login',
      ...params
    })
})

const fetchReviewSchools = (params, initiator) => ({
  requestPrefix: 'SCHOOLS:FETCHING',
  params: { initiator },
  request: () =>
    searchForAReviewSchool({
      include:
        'school.names,school.urn,school.contact_details,school.address,school.wonde,school.paid,school.teacher_types,school.group_subs_seats,school.last_login',
      ...params
    })
})

const searchSchools = searchTerm => ({
  requestPrefix: 'SCHOOLS:FETCHING',
  params: { searchTerm },
  request: () =>
    apiSearchAllSchools({
      include: 'school.names,school.urn',
      search_term: searchTerm,
      limit: 50
    })
})

const loadMoreSchools = () => ({
  type: 'SCHOOLS:LOAD_MORE'
})

const updateSchool = (id, school) => ({
  requestPrefix: 'SCHOOL:UPDATE',
  params: { id, school },
  request: () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve({ id, school }), 1000)
    )
})

const addSchool = school => ({
  requestPrefix: 'SCHOOLS:ADD',
  params: school,
  request: () => apiAddSchool(school)
})

export {
  fetchSchools,
  searchSchools,
  addSchool,
  updateSchool,
  loadMoreSchools,
  fetchReviewSchools
}
