const styles = ({ COLORS }) => ({
  schoolLeadCell: {
    textAlign: 'center'
  },
  removeCell: {
    textAlign: 'right'
  },
  link: {
    textDecoration: 'none',
    fontWeight: 'bold',
    color: COLORS.BLUE.NORMAL
  }
})
export default styles
