import { connect } from 'react-redux'
import CommentsFilter from './comments-filter'
import {
  getFilterStatus,
  getFilterSearch
} from '../../../../stores/comments-filter/comments-filter.selectors'
import {
  setStatusFilter,
  setSearchValue
} from '../../../../stores/comments-filter/comments-filter.actions.js'

const mapStateToProps = state => ({
  searchValue: getFilterSearch(state),
  statusFilter: getFilterStatus(state)
})

const mapDispatchToProps = dispatch => ({
  onFilter: status => dispatch(setStatusFilter(status)),
  onSearchChange: e => dispatch(setSearchValue(e.target.value))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommentsFilter)
