import { connect } from 'react-redux'
import CampaignEdition from './campaign-edition'
import { fetchCampaign } from '../../../stores/campaigns/campaigns.actions'
import { getFetchCampaignRequest } from '../../../stores/campaigns/campaigns.selectors'
import { errorHandler } from 'pobble-components/dist/error-handler'
import { withRouter } from 'react-router'

const mapStateToProps = state => {
  const fetchCampaignRequest = getFetchCampaignRequest(state)
  return {
    pending: fetchCampaignRequest.pending
  }
}

const mapDispatchToProps = (dispatch, { match }) => ({
  onMount: () => dispatch(fetchCampaign(match.params.id)).catch(errorHandler)
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CampaignEdition)
)
