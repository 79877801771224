import { connect } from 'react-redux'
import SchoolDetailsSub from './school-details-sub'
import { withRouter } from 'react-router-dom'
import { getSchool } from '../../../../stores/schools/schools.selectors'
import { assignSchoolSub } from '../../../../stores/school/school.actions'
import { errorHandler } from 'pobble-components/dist/error-handler'
import { toast } from 'pobble-components/dist/toast'

const mapStateToProps = state => {
  const school = getSchool(state)
  return {
    id: school.id,
    subscriptionIds: school && school.subscription_ids,
    subscriptions: school && school.schools_subscriptions
  }
}

const mapDispatchToProps = (dispatch, { match }) => ({
  onSubmit: values =>
    dispatch(assignSchoolSub(match.params.id, values.subscriptionId))
      .then(toast.success('This school subscription has now been updated'))
      .catch(errorHandler)
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SchoolDetailsSub)
)
