import { connect } from 'react-redux'
import LoadMoreButton from './load-more-button'
import { loadMoreSchools } from '../../../../stores/schools/schools.actions'
import {
  getFetchSchoolsRequest,
  getFetchSchoolsRequestInitiator
} from '../../../../stores/schools/schools.selectors'

const mapStateToProps = state => ({
  pending:
    getFetchSchoolsRequest(state) &&
    getFetchSchoolsRequestInitiator(state) === 'LOAD_MORE'
})

const mapDispatchToProps = dispatch => ({
  onLoadMore: () => dispatch(loadMoreSchools())
})

export default connect(mapStateToProps, mapDispatchToProps)(LoadMoreButton)
