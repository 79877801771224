import { createReducer } from 'pobble-components/dist/redux-helpers'
import { normalize, schema } from 'normalizr'

const normalizeTeachers = data => {
  const teacher = new schema.Entity('teachers')
  return normalize(data, [teacher])
}

const DEFAULT_LIMIT = 20
const LOAD_MORE_STEP = 20

let defaultState = {
  loading: false,
  teachersUpdating: {},
  teachers: {
    result: [],
    entities: { teachers: {} }
  },
  currentLimit: DEFAULT_LIMIT
}

const handleTeacherRequestError = (state, { params }) => ({
  ...state,
  teachersUpdating: {
    ...state.teachersUpdating,
    [params.teacherId]: true
  }
})

const TeachersStore = createReducer(defaultState, {
  'TEACHERS:FETCHING_REQUEST': state => ({
    ...state,
    loading: true
  }),
  'TEACHERS:FETCHING_REQUEST_SUCCESS': (state, { response }) => {
    return {
      ...state,
      loading: false,
      teachers: normalizeTeachers(response.data.teachers)
    }
  },
  'TEACHERS:LOAD_MORE': state => ({
    ...state,
    currentLimit: state.currentLimit + LOAD_MORE_STEP
  }),
  'TEACHER:APPROVE_REQUEST_SUCCESS': (state, { response, params }) => {
    return {
      ...state,
      teachersUpdating: {
        ...state.teachersUpdating,
        [params.teacherId]: false
      },
      teachers: {
        ...state.teachers,
        entities: {
          teachers: {
            ...state.teachers.entities.teachers,
            [params.teacherId]: {
              ...state.teachers.entities.teachers[params.teacherId],
              status: 'approved'
            }
          }
        }
      }
    }
  },
  'TEACHER:APPROVE_REQUEST': (state, { params }) => ({
    ...state,
    teachersUpdating: {
      ...state.teachersUpdating,
      [params.teacherId]: true
    }
  }),
  'TEACHER:DISAPPROVE_REQUEST_SUCCESS': (state, { response, params }) => {
    return {
      ...state,
      teachersUpdating: {
        ...state.teachersUpdating,
        [params.teacherId]: false
      },
      teachers: {
        ...state.teachers,
        entities: {
          teachers: {
            ...state.teachers.entities.teachers,
            [params.teacherId]: {
              ...state.teachers.entities.teachers[params.teacherId],
              status: 'declined'
            }
          }
        }
      }
    }
  },
  'TEACHER:DISAPPROVE_REQUEST': (state, { params }) => ({
    ...state,
    teachersUpdating: {
      ...state.teachersUpdating,
      [params.teacherId]: true
    }
  }),
  'TEACHER:ADD_AS_PENDING_TEACHER_REQUEST': (state, { params }) => ({
    ...state,
    teachersUpdating: {
      ...state.teachersUpdating,
      [params.teacherId]: true
    }
  }),
  'TEACHER:ADD_AS_PENDING_TEACHER_REQUEST_SUCCESS': (
    state,
    { response, params }
  ) => {
    return {
      ...state,
      teachersUpdating: {
        ...state.teachersUpdating,
        [params.teacherId]: false
      },
      teachers: {
        ...state.teachers,
        entities: {
          teachers: {
            ...state.teachers.entities.teachers,
            [params.teacherId]: {
              ...state.teachers.entities.teachers[params.teacherId],
              pending_teacher_type: {
                school: {
                  id: params.schoolId,
                  title: params.schoolTitle
                }
              }
            }
          }
        }
      }
    }
  },
  'TEACHER:UPDATE_NOTES_REQUEST_SUCCESS': (state, { response, params }) => {
    return {
      ...state,
      teachersUpdating: {
        ...state.teachersUpdating,
        [params.teacherId]: false
      },
      teachers: {
        ...state.teachers,
        entities: {
          teachers: {
            ...state.teachers.entities.teachers,
            [response.data.account.id]: {
              ...state.teachers.entities.teachers[response.data.account.id],
              note: response.data.account.note
            }
          }
        }
      }
    }
  },
  'TEACHER:UPDATE_NOTES_REQUEST': (state, { params }) => ({
    ...state,
    teachersUpdating: {
      ...state.teachersUpdating,
      [params.teacherId]: true
    }
  }),
  'TEACHER:APPROVE_REQUEST_ERROR': handleTeacherRequestError,
  'TEACHER:DISAPPROVE_REQUEST_ERROR': handleTeacherRequestError,
  'TEACHER:UPDATE_NOTES_REQUEST_ERROR': handleTeacherRequestError
})

export default TeachersStore
