import { connect } from 'react-redux'
import CommentEntry from './comment-entry'
import {
  approveComment,
  disapproveComment
} from '../../../../stores/comments/comments.actions'
import { isCommentBeingUpdated } from '../../../../stores/comments/comments.selectors'
import { errorHandler } from 'pobble-components/dist/error-handler'

const mapStateToProps = (state, { id }) => ({
  showLoading: isCommentBeingUpdated(state, id)
})

const mapDispatchToProps = dispatch => ({
  onApprove: commentId =>
    dispatch(approveComment(commentId)).catch(errorHandler),
  onDisapprove: commentId =>
    dispatch(disapproveComment(commentId)).catch(errorHandler)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommentEntry)
