import { connect } from 'react-redux'
import OrganisationEditionForm from './organisation-edition-form'
import { editOrganisation } from '../../../../stores/organisations/organisations.actions'
import { getEditedOrganisation } from '../../../../stores/organisations/organisations.selectors'
import { errorHandler } from 'pobble-components/dist/error-handler'
import { withRouter } from 'react-router'

const mapStateToProps = (state, { id }) => {
  const organisation = getEditedOrganisation(state, id)
  return {
    title: organisation.title,
    local_authority: organisation.local_authority,
    schools: organisation.schools
  }
}

const mapDispatchToProps = (dispatch, { match }) => ({
  onSubmit: values => {
    const data = {
      title: values.title,
      local_authority: values.local_authority
    }
    dispatch(editOrganisation(match.params.id, data))
      .then(() => window.location.reload())
      .catch(errorHandler)
  }
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrganisationEditionForm)
)
