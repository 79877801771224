const styles = () => ({
  cell: {
    textAlign: 'left'
  },
  spinner: {
    fontSize: '20px'
  },
  pendingAddition: {
    opacity: '.5',
    pointerEvents: 'none'
  },
  pagination: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center'
  }
})

export default styles
