import { connect } from 'react-redux'
import ChildrenTable from './children-table'
import { getUserChildren } from '../../../../../../stores/user-details/user-details.selectors'

const mapStateToProps = state => ({
  userChildren: getUserChildren(state).result
})

export default connect(
  mapStateToProps,
  null
)(ChildrenTable)
