import { createReducer } from 'pobble-components/dist/redux-helpers'
import { normalize, schema } from 'normalizr'

const normalizeSchools = data => {
  const school = new schema.Entity('schools')
  return normalize(data, [school])
}

const DEFAULT_LIMIT = 50
const LOAD_MORE_STEP = 50

let defaultState = {
  loading: false,
  currentLimit: DEFAULT_LIMIT,
  schoolBeingAdded: false,
  schoolsFetched: false,
  schools: {
    result: [],
    entities: { schools: {} }
  }
}

const SchoolsStore = createReducer(defaultState, {
  'SCHOOLS:FETCHING_REQUEST': state => ({
    ...state,
    loading: true
  }),
  'SCHOOLS:FETCHING_REQUEST_SUCCESS': (state, { response }) => ({
    ...state,
    loading: false,
    schools: normalizeSchools(response.data.schools),
    schoolsFetched: true
  }),
  'SCHOOLS:SEARCHING_REQUEST_SUCCESS': (state, { response }) => {
    return {
      ...state,
      schools: normalizeSchools(response.data.schools)
    }
  },
  'SCHOOLS:LOAD_MORE': state => ({
    ...state,
    currentLimit: state.currentLimit + LOAD_MORE_STEP
  }),
  'SCHOOLS:ADD_REQUEST': (state, { params }) => ({
    ...state,
    schoolBeingAdded: true
  }),
  'SCHOOLS:ADD_REQUEST_SUCCESS': (state, { response, params }) => ({
    ...state,
    schoolBeingAdded: false,
    schools: {
      ...state.schools,
      result: [response.data.school.id, ...state.schools.result],
      entities: {
        schools: {
          ...state.schools.entities.schools,
          [response.data.school.id]: response.data.school
        }
      }
    }
  }),
  'SCHOOLS:ADD_REQUEST_ERROR': (state, { params }) => ({
    ...state,
    schoolBeingAdded: false
  }),
  'SCHOOL:UPDATE_REQUEST': state => ({
    ...state,
    schoolBeingUpdated: true
  }),
  'SCHOOL:UPDATE_REQUEST_SUCCESS': (state, { response }) => ({
    ...state,
    schoolBeingUpdated: false,
    schools: {
      ...state.schools,
      entities: {
        schools: {
          ...state.schools.entities.schools,
          [response.id]: response.school
        }
      }
    }
  }),
  'SCHOOLS:UPDATE_REQUEST_ERROR': state => ({
    ...state,
    schoolBeingUpdated: false
  })
})

export default SchoolsStore
