import { connect } from 'react-redux'
import UsersList from './users-list'
import {
  getUsersList,
  lessUsersThanLimit,
  getFetchUsersRequest,
  getFetchUsersRequestInitiator
} from '../../../../stores/users/users.selectors'

const mapStateToProps = state => {
  const req = getFetchUsersRequest(state)
  const initiator = getFetchUsersRequestInitiator(state)
  return {
    users: getUsersList(state) || [],
    pending: req.pending && initiator === 'SEARCH',
    hideLoadMore:
      !(req.pending && initiator === 'LOAD_MORE') && lessUsersThanLimit(state)
  }
}

export default connect(
  mapStateToProps,
  null
)(UsersList)
