import React, { Component } from 'react'
import Autocomplete from 'react-autocomplete'
import injectSheet from 'react-jss'
import styles from './assign-teacher-modal.styles.js'
import Button from 'pobble-components/dist/button'
import classNames from 'classnames'
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter
} from 'pobble-components/dist/modal'

class AssignTeacherModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
      schools: []
    }
  }

  getSchools(value, cb) {
    this.props
      .onSearchChange(value)
      .then(({ data }) => this.setState({ schools: data.schools }))
  }

  render() {
    const { classes, id, suggestion, onUpdate, isOpen, onClose } = this.props
    return (
      <Modal legacy isOpen={isOpen}>
        <ModalHeader>Assign to a school</ModalHeader>
        <ModalContent>
          <span>Suggested school details:</span>
          <pre className={classes.suggestion}>{suggestion}</pre>
          <hr className={classes.line} />
          <span>Assign to a school:</span>
          <Autocomplete
            inputProps={{
              id: 'schools-autocomplete',
              className: classes.field
            }}
            wrapperStyle={{ position: 'relative' }}
            value={this.state.value}
            items={this.state.schools}
            getItemValue={item => item.suggestable_title}
            onSelect={(value, item) =>
              this.setState({ value, schools: [item], selectedSchool: item })
            }
            onChange={(event, value) => {
              this.setState({ value, selectedSchool: null })
              this.getSchools(value)
            }}
            renderMenu={children => (
              <div className={classes.menu}>{children}</div>
            )}
            renderItem={(item, isHighlighted) => (
              <div
                className={classNames(classes.item, {
                  [classes.highlighted]: isHighlighted
                })}
                key={item.id}>
                {item.suggestable_title}
              </div>
            )}
          />
          <a href="/schools" target="_blank" className={classes.link}>
            Create a new school first
          </a>
        </ModalContent>
        <ModalFooter className={classes.footer}>
          <Button simple action={onClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={!this.state.selectedSchool}
            action={() => {
              onUpdate(
                this.state.selectedSchool.id,
                id,
                this.state.selectedSchool.title
              )
            }}>
            {!this.state.selectedSchool
              ? `Assign as a teacher to school`
              : `Assign as a teacher to ${this.state.selectedSchool.title}`}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default injectSheet(styles)(AssignTeacherModal)
