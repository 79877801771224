import { connect } from 'react-redux'
import Campaigns from './campaigns'
import { fetchCampaigns } from '../../../stores/campaigns/campaigns.actions'
import { getFetchCampaignsRequest } from '../../../stores/campaigns/campaigns.selectors'

const mapStateToProps = state => ({
  pending: getFetchCampaignsRequest(state).pending
})

const mapDispatchToProps = dispatch => ({
  onMount: () => dispatch(fetchCampaigns())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Campaigns)
