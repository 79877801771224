const styles = ({ COLORS, FONT_SIZES }) => ({
  container: {
    backgroundColor: '#fff',
    minHeight: '100vh',
    boxShadow: '5px 0 4px rgba(0, 0, 0, 0.05)',
    width: '300px'
  },
  heading: {
    color: '#fff',
    backgroundColor: COLORS.DARK_BLUE.NORMAL,
    padding: '20px',
    margin: '0',
    ...FONT_SIZES.HEADING_MEDIUM.STYLE
  },
  navigationSection: {
    margin: '0'
  }
})

export default styles
