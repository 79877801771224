import { connect } from 'react-redux'
import AddLogo from './add-logo'
import { uploadLogo } from '../../../../stores/school/school.actions'
import { withRouter } from 'react-router'
import { errorHandler } from 'pobble-components/dist/error-handler'

const mapDispatchToProps = (dispatch, { match }) => ({
  onChange: files =>
    dispatch(uploadLogo(match.params.id, files[0])).catch(errorHandler)
})

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(AddLogo)
)
