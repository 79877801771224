import { apiAccountDetails } from '../../common/api/profile'
import { apiRedactStudentAccount } from '../../common/api/admin'
import {
  apiRetireStudent,
  apiUnretireStudent,
  apiStudentDocuments
} from '../../common/api/student'

const fetchStudent = studentId => {
  return {
    requestPrefix: 'STUDENT_DETAILS:FETCH',
    params: { studentId },
    request: () =>
      apiAccountDetails(studentId, {
        include:
          'account.names,account.avatar,account.student_type.school.names,account.student_type.dob,account.student_type.year_group,account.student_type.year_group.names,account.student_type.year_group.order,account.student_type.passcode,account.student_type.groups,account.student_type.group.names,account.student_type.parent_types,account.student_type.parent_type.account,account.student_type.parent_type.status,account.student_type.parent_type.account.email_identity.email,account.student_type.policy,account.student_type.status,account.student_type.parent_code'
      })
  }
}

const fetchStudentDocuments = studentId => {
  return {
    requestPrefix: 'STUDENT_DETAILS:FETCH_DOCUMENTS',
    params: { studentId },
    request: () =>
      apiStudentDocuments(studentId, {
        include: 'permission_slip.details'
      })
  }
}

const retireStudent = studentId => ({
  requestPrefix: 'STUDENT_DETAILS:RETIRE',
  params: { studentId },
  request: () => apiRetireStudent(studentId)
})

const unretireStudent = studentId => ({
  requestPrefix: 'STUDENT_DETAILS:UNRETIRE',
  params: { studentId },
  request: () => apiUnretireStudent(studentId)
})

const redactStudentAccount = studentId => ({
  requestPrefix: 'STUDENT_DETAILS:REDACT',
  request: () => apiRedactStudentAccount(studentId)
})

export {
  fetchStudent,
  retireStudent,
  unretireStudent,
  fetchStudentDocuments,
  redactStudentAccount
}
