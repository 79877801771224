import { connect } from 'react-redux'
import UserDetailsChildren from './user-details-children'
import {
  getUserChildren,
  getUnassignParentRequest
} from '../../../../../stores/user-details/user-details.selectors'

const mapStateToProps = state => ({
  pending: getUnassignParentRequest(state).pending,
  noChildren: !getUserChildren(state).result.length
})

export default connect(
  mapStateToProps,
  null
)(UserDetailsChildren)
