import { connect } from 'react-redux'
import SchoolsList from './schools-list'
import {
  getSchools,
  areSchoolsFetching,
  isSchoolBeingAdded,
  isSchoolsFetched,
  lessSchoolsThanLimit,
  getFetchSchoolsRequest,
  getFetchSchoolsRequestInitiator,
  getServerParams
} from '../../../../stores/schools/schools.selectors'
import { getFilteredSchools } from '../../../../stores/schools-filter/schools-filter.selectors'
import { fetchSchools } from '../../../../stores/schools/schools.actions'

const mapStateToProps = state => {
  const filteredSchools = getFilteredSchools(state)
  const req = getFetchSchoolsRequest(state)
  const initiator = getFetchSchoolsRequestInitiator(state)

  return {
    noSchools: !getSchools(state),
    noSchoolsMatchingFilters: !filteredSchools.length,
    showLoading: initiator === 'SEARCH' && areSchoolsFetching(state),
    schoolGettingAdded: isSchoolBeingAdded(state),
    schools: filteredSchools,
    isSchoolsFetched: isSchoolsFetched(state),
    hideLoadMore:
      !(req && initiator === 'LOAD_MORE') && lessSchoolsThanLimit(state),
    params: getServerParams(state)
  }
}

const mapDispatchToProps = dispatch => ({ dispatch })

const mergeProps = (stateProps, { dispatch }, ownProps) => ({
  ...ownProps,
  ...stateProps,
  onMount: () => dispatch(fetchSchools(stateProps.params, 'INITIAL')),
  onUpdate: prevProps => {
    if (prevProps.params !== stateProps.params) {
      const initiator =
        prevProps.params.limit !== stateProps.params.limit
          ? 'LOAD_MORE'
          : 'SEARCH'
      dispatch(fetchSchools(stateProps.params, initiator))
    }
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(SchoolsList)
