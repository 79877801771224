import React, { Component } from 'react'
import styles from './school-organisations.styles'
import injectSheet from 'react-jss'
import Section from '../../../shared/details/section/section'
import FormCheckbox from 'pobble-components/dist/form-elements/form-checkbox'

class SchoolOrganisations extends Component {
  componentDidMount() {
    this.props.onMount()
  }

  isSchoolOnOrganisation(orgs, id) {
    return orgs.filter(org => org.id === id)
  }

  render() {
    const {
      classes,
      organisations,
      onOrganisationChange,
      pending,
      school
    } = this.props
    return (
      <Section title="Organisations" pending={pending}>
        {school && school.school_organisations && (
          <div className={classes.holder}>
            {organisations.map(organisation => (
              <label key={organisation.id}>
                <FormCheckbox
                  checked={
                    this.isSchoolOnOrganisation(
                      school.school_organisations,
                      organisation.id
                    ).length > 0
                  }
                  onChange={e => onOrganisationChange(e, organisation.id)}
                />
                <span>{organisation.title}</span>
              </label>
            ))}
          </div>
        )}
      </Section>
    )
  }
}

export default injectSheet(styles)(SchoolOrganisations)
