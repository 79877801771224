import { connect } from 'react-redux'
import UserDetailsEmail from './user-details-email'
import {
  getUser,
  getUpdateAccountRequest
} from '../../../../../stores/user-details/user-details.selectors'
import { withRouter } from 'react-router'
import { updateAccountEmail } from '../../../../../stores/user-details/user-details.actions'
import { withMe } from 'pobble-components/dist/pobble-user'
import { errorHandler } from 'pobble-components/dist/error-handler'

const mapStateToProps = (state, { me }) => {
  const user = getUser(state)
  const updateAccountDetails = getUpdateAccountRequest(state)
  return {
    pending: updateAccountDetails.pending,
    emailId: user.email_identity && user.email_identity.id,
    email: (user.email_identity && user.email_identity.email) || ''
  }
}

const mapDispatchToProps = dispatch => ({
  onSubmit: values => {
    const data = {
      new_email: values.email
    }
    dispatch(updateAccountEmail(values.emailId, data)).catch(errorHandler)
  }
})

export default withMe(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(UserDetailsEmail))
)
