import { connect } from 'react-redux'
import Organisation from './organisation'
import { getOrganisation } from '../../../../../stores/organisations/organisations.selectors'
import { deleteOrganisation } from '../../../../../stores/organisations/organisations.actions'
import { withRouter } from 'react-router'
import { errorHandler } from 'pobble-components/dist/error-handler'

const mapStateToProps = (state, { id }) => {
  const organisation = getOrganisation(state, id)
  return {
    title: organisation.title || '-',
    localAuthority: organisation.local_authority
  }
}

const mapDispatchToProps = dispatch => ({
  onDelete: organisationId =>
    dispatch(deleteOrganisation(organisationId)).catch(errorHandler)
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Organisation)
)
