import { connect } from 'react-redux'
import SchoolDetailsGroupAddon from './school-details-group-addon'
import { withRouter } from 'react-router-dom'
import { getSchool } from '../../../../stores/schools/schools.selectors'
import { assignGroupAddonSub } from '../../../../stores/school/school.actions'
import { errorHandler } from 'pobble-components/dist/error-handler'
import { toast } from 'pobble-components/dist/toast'

const mapStateToProps = state => {
  const school = getSchool(state)
  return {
    id: school.id,
    subscriptionIds: school && school.subscription_ids,
    subscriptions: school && school.group_subscriptions
  }
}

const mapDispatchToProps = (dispatch, { match }) => ({
  onSubmit: values => {
    const data = {
      id: values.subscriptionId,
      plan_type: values.plan
    }
    return dispatch(assignGroupAddonSub(match.params.id, data))
      .then(toast.success('This school subscription has now been updated'))
      .catch(errorHandler)
  }
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SchoolDetailsGroupAddon)
)
