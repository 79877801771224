import { connect } from 'react-redux'
import AssignTeacherModal from './assign-teacher-modal'
import { addAsPendingTeacher } from '../../../../stores/teachers/teachers.actions'
import { isTeacherBeingUpdated } from '../../../../stores/teachers/teachers.selectors'
import { searchSchools } from '../../../../stores/schools/schools.actions.js'
import { errorHandler } from 'pobble-components/dist/error-handler'

const mapStateToProps = (state, { id, suggestedSchool }) => ({
  showLoading: isTeacherBeingUpdated(state, id),
  suggestion: suggestedSchool.split(', ').join('\n')
})

const mapDispatchToProps = dispatch => ({ dispatch })

const mergeProps = (stateProps, { dispatch }, ownProps) => ({
  ...stateProps,
  ...ownProps,
  onUpdate: (schoolId, teacherId, schoolTitle) => {
    return (
      dispatch(addAsPendingTeacher(schoolId, teacherId, schoolTitle)).catch(
        errorHandler
      ) && ownProps.onClose()
    )
  },
  onSearchChange: term => dispatch(searchSchools(term)).catch(errorHandler)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(AssignTeacherModal)
