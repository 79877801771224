import { connect } from 'react-redux'
import SchoolTeachersList from './teachers-list'
import { withRouter } from 'react-router-dom'
import {
  getTeachers,
  areTeachersFetching,
  isTeacherBeingAdded,
  isTeachersFetched
} from '../../../../stores/school-teachers/school-teachers.selectors'
import { getFilteredTeachers } from '../../../../stores/school-teachers-filter/school-teachers-filter.selectors'
import { fetchTeachers } from '../../../../stores/school-teachers/school-teachers.actions'
import { errorHandler } from 'pobble-components/dist/error-handler'

const mapStateToProps = state => {
  const filteredTeachers = getFilteredTeachers(state)
  return {
    noTeachers: !getTeachers(state),
    noTeachersMatchingFilters: !filteredTeachers.length,
    showLoading: areTeachersFetching(state),
    teacherGettingAdded: isTeacherBeingAdded(state),
    teachers: filteredTeachers,
    isTeachersFetched: isTeachersFetched(state)
  }
}

const mapDispatchToProps = dispatch => ({ dispatch })

const mergeProps = (stateProps, { dispatch }, ownProps) => ({
  ...stateProps,
  ...ownProps,
  onMount: () =>
    dispatch(fetchTeachers(ownProps.match.params.id)).catch(errorHandler)
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(SchoolTeachersList)
)
