import { connect } from 'react-redux'
import SchoolStudentsList from './students-list'
import { withRouter } from 'react-router-dom'
import {
  getStudents,
  areStudentsFetching,
  isStudentsFetched
} from '../../../../stores/school-students/school-students.selectors'
import { getFilteredStudents } from '../../../../stores/school-students-filter/school-students-filter.selectors'
import { fetchStudents } from '../../../../stores/school-students/school-students.actions'
import { errorHandler } from 'pobble-components/dist/error-handler'

const mapStateToProps = state => {
  const filteredStudents = getFilteredStudents(state)
  return {
    noStudents: !getStudents(state),
    noStudentsMatchingFilters: !filteredStudents.length,
    showLoading: areStudentsFetching(state),
    students: filteredStudents,
    isStudentsFetched: isStudentsFetched(state)
  }
}

const mapDispatchToProps = dispatch => ({ dispatch })

const mergeProps = (stateProps, { dispatch }, ownProps) => ({
  ...stateProps,
  ...ownProps,
  onMount: () =>
    dispatch(fetchStudents(ownProps.match.params.id)).catch(errorHandler)
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(SchoolStudentsList)
)
