import { apiClient } from 'pobble-components/dist/api'

const searchForASchool = params => apiClient.get('v3/admin/schools', { params })

const searchForAReviewSchool = params =>
  apiClient.get('v3/admin/schools/schools_with_unassigned_seats', { params })

const getSchool = (id, params) => apiClient.get(`v3/schools/${id}`, { params })
const apiUpdateSchool = (id, data) => apiClient.put(`v3/schools/${id}`, data)

const apiSearchAllSchools = params =>
  apiClient.get('/v3/schools_search?all_schools=true&include_urn=true', {
    params
  })

const apiUploadLogo = (schoolId, logo, params) => {
  const formData = new FormData()
  formData.append('logo', logo)
  return apiClient.post(`/v3/schools/${schoolId}/logos`, formData, {
    params
  })
}

const apiDeleteLogo = schoolId =>
  apiClient.delete(`/v3/schools/${schoolId}/logos`)

const apiUploadCover = (schoolId, cover, params) => {
  const formData = new FormData()
  formData.append('background_image', cover)
  return apiClient.post(`/v3/schools/${schoolId}/background_images`, formData, {
    params
  })
}

const apiDeleteCover = schoolId =>
  apiClient.delete(`/v3/schools/${schoolId}/background_images`)

const apiAddSchool = school =>
  apiClient.post(
    `/v3/admin/schools?include=school.names,school.urn,school.contact_details,school.address`,
    school
  )

const apiSetAsPobbleSchool = (id, data) =>
  apiClient.put(`v3/admin/schools/${id}/set_as_pobble_school`, {
    until_date: data
  })

const apiUnsetAsPobbleSchool = id =>
  apiClient.put(`v3/admin/schools/${id}/unset_as_pobble_school`)

const apiAssignSchoolSub = (id, data) =>
  apiClient.post(`v3/admin/schools/${id}/assign_school_subscription`, {
    subscription_id: data
  })

const apiAssignSchoolOrgSub = (id, data) =>
  apiClient.post(`v3/admin/schools/${id}/assign_subscriptions_org_addon`, {
    subscriptions_org_id: data.id,
    plan_type: data.plan_type
  })

const apiAssignGroupAddonSub = (id, data) =>
  apiClient.post(`v3/admin/schools/${id}/assign_subscriptions_group_addon`, {
    subscriptions_group_id: data.id,
    plan_type: data.plan_type
  })

const apiAssignSchoolPlan = (id, data) =>
  apiClient.put(
    `v3/admin/schools/${id}/create_school_subscription_without_payment`,
    data
  )

export {
  searchForASchool,
  getSchool,
  apiUpdateSchool,
  apiUploadLogo,
  apiDeleteLogo,
  apiUploadCover,
  apiDeleteCover,
  apiAddSchool,
  apiSearchAllSchools,
  apiSetAsPobbleSchool,
  apiUnsetAsPobbleSchool,
  apiAssignSchoolSub,
  apiAssignSchoolPlan,
  apiAssignSchoolOrgSub,
  searchForAReviewSchool,
  apiAssignGroupAddonSub
}
