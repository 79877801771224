import {
  getSchool,
  apiUpdateSchool,
  apiUploadLogo,
  apiDeleteLogo,
  apiUploadCover,
  apiDeleteCover,
  apiSetAsPobbleSchool,
  apiUnsetAsPobbleSchool,
  apiAssignSchoolSub,
  apiAssignSchoolPlan,
  apiAssignSchoolOrgSub,
  apiAssignGroupAddonSub
} from '../../common/api/school'

import {
  apiAddToOrganisation,
  apiRemoveFromOrganisation
} from '../../common/api/organisations'

const fetchSchool = id => ({
  requestPrefix: 'SCHOOL:FETCHING',
  request: () =>
    getSchool(id, {
      include:
        'school.names,school.timestamps,school.address,school.contact_details,school.notes,school.pobblers,school.urn,school.policy,school.settings,school.logo,school.background_image,school.counters,school.wonde,school.student_counters,school.paid,school.groupcall,school.subscription,school.school_organisations,school.school_organisations.details'
    })
})

const updateSchool = (id, data) => ({
  requestPrefix: 'SCHOOL:UPDATE',
  request: () => apiUpdateSchool(id, data)
})

const uploadLogo = (schoolId, file) => ({
  requestPrefix: 'SCHOOL:UPLOAD_LOGO',
  params: { schoolId: schoolId, file: file },
  request: () =>
    apiUploadLogo(schoolId, file, {
      include: 'logo'
    })
})

const deleteLogo = schoolId => ({
  requestPrefix: 'SCHOOL:DELETE_LOGO',
  params: { schoolId },
  request: () => apiDeleteLogo(schoolId)
})

const uploadCover = (schoolId, file) => ({
  requestPrefix: 'SCHOOL:UPLOAD_COVER',
  params: { schoolId: schoolId, file: file },
  request: () =>
    apiUploadCover(schoolId, file, {
      include: 'background'
    })
})

const deleteCover = schoolId => ({
  requestPrefix: 'SCHOOL:DELETE_COVER',
  params: { schoolId },
  request: () => apiDeleteCover(schoolId)
})

const setAsPobbleSchool = (schoolId, expirationDate) => ({
  requestPrefix: 'SCHOOL:SET_AS_POBBLE_SCHOOL',
  params: { schoolId, expirationDate },
  request: () => apiSetAsPobbleSchool(schoolId, expirationDate)
})

const unsetAsPobbleSchool = schoolId => ({
  requestPrefix: 'SCHOOL:UNSET_AS_POBBLE_SCHOOL',
  params: { schoolId },
  request: () => apiUnsetAsPobbleSchool(schoolId)
})

const assignSchoolSub = (schoolId, subId) => ({
  requestPrefix: 'SCHOOL:SET_SCHOOL_SUB',
  params: { schoolId, subId },
  request: () => apiAssignSchoolSub(schoolId, subId)
})

const assignSchoolOrgSub = (schoolId, subId) => ({
  requestPrefix: 'SCHOOL:SET_SCHOOL_ORG_SUB',
  params: { schoolId, subId },
  request: () => apiAssignSchoolOrgSub(schoolId, subId)
})

const assignGroupAddonSub = (schoolId, subId) => ({
  requestPrefix: 'SCHOOL:SET_SCHOOL_GROUP_ADDON_SUB',
  params: { schoolId, subId },
  request: () => apiAssignGroupAddonSub(schoolId, subId)
})

const assignSchoolPlan = (schoolId, data) => ({
  requestPrefix: 'SCHOOL:SET_SCHOOL_PLAN',
  params: { schoolId, data },
  request: () => apiAssignSchoolPlan(schoolId, data)
})

const addToOrganisation = (schoolId, data) => ({
  requestPrefix: 'SCHOOL:ADD_ORGANISATION',
  params: { schoolId, data },
  request: () => apiAddToOrganisation(schoolId, data)
})

const removeFromOrganisation = (schoolId, data) => ({
  requestPrefix: 'SCHOOL:ADD_ORGANISATION',
  params: { schoolId, data },
  request: () => apiRemoveFromOrganisation(schoolId, data)
})

export {
  fetchSchool,
  updateSchool,
  uploadLogo,
  deleteLogo,
  uploadCover,
  deleteCover,
  setAsPobbleSchool,
  unsetAsPobbleSchool,
  assignSchoolSub,
  addToOrganisation,
  removeFromOrganisation,
  assignSchoolPlan,
  assignSchoolOrgSub,
  assignGroupAddonSub
}
