import {
  apiGetOrganisations,
  apiGetOrganisation,
  apiCreateOrganisation,
  apiDeleteOrganisation,
  apiEditingOrganisation
} from '../../common/api/organisations'

const fetchOrganisations = params => ({
  requestPrefix: 'ORGANISATIONS:FETCHING',
  request: () =>
    apiGetOrganisations({
      include: 'school_organisation.details',
      ...params
    })
})

const fetchOrganisation = (id, params) => ({
  requestPrefix: 'ORGANISATION:FETCHING',
  params: id,
  request: () =>
    apiGetOrganisation(id, {
      include:
        'school_organisation.details,school_organisation.schools,school_organisation.school.names',
      ...params
    })
})

const createOrganisation = params => ({
  requestPrefix: 'ORGANISATIONS:CREATING',
  request: () => apiCreateOrganisation(params)
})

const deleteOrganisation = organisationId => ({
  requestPrefix: 'ORGANISATIONS:DELETING',
  params: { organisationId },
  request: () => apiDeleteOrganisation(organisationId)
})

const editOrganisation = (organisationId, data) => ({
  requestPrefix: 'ORGANISATIONS:EDITING',
  params: { organisationId },
  request: () => apiEditingOrganisation(organisationId, data)
})

export {
  fetchOrganisations,
  fetchOrganisation,
  createOrganisation,
  deleteOrganisation,
  editOrganisation
}
