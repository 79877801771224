import { combineReducers } from 'redux'
import {
  createReducer,
  createRequestReducer
} from 'pobble-components/dist/redux-helpers'

const UserDetails = combineReducers({
  fetchUserRequest: createRequestReducer('USER_DETAILS:FETCH', {
    pending: true
  }),
  uploadAvatarRequest: createRequestReducer('USER_DETAILS:UPLOAD_AVATAR'),
  removeAvatarRequest: createRequestReducer('USER_DETAILS:REMOVE_AVATAR'),
  updateAccountRequest: createRequestReducer('USER_DETAILS:UPDATE'),
  promoteTeacherRequest: createRequestReducer('USER_DETAILS:PROMOTE_TEACHER'),
  demoteTeacherRequest: createRequestReducer('USER_DETAILS:DEMOTE_TEACHER'),
  deleteTeacherRequest: createRequestReducer('USER_DETAILS:DELETE_TEACHER'),
  declineTeacherRequest: createRequestReducer('USER_DETAILS:DECLINE_TEACHER'),
  unassignParentRequest: createRequestReducer('USER_DETAILS:UNASSIGN_PARENT'),
  promotePobblerRequest: createRequestReducer('USER_DETAILS:PROMOTE_POBBLER'),
  demotePobblerRequest: createRequestReducer('USER_DETAILS:DEMOTE_POBBLER'),
  promoteAdminRequest: createRequestReducer('USER_DETAILS:PROMOTE_ADMIN'),
  demoteAdminRequest: createRequestReducer('USER_DETAILS:DEMOTE_ADMIN'),
  user: createReducer(null, {
    'USER_DETAILS:FETCH_REQUEST_SUCCESS': (state, { response }) =>
      response.data.account,
    'USER_DETAILS:UPDATE_REQUEST_SUCCESS': (state, { response }) => ({
      ...state,
      ...response.data.account
    }),
    'USER_DETAILS:UPLOAD_AVATAR_REQUEST_SUCCESS': (state, { response }) => ({
      ...state,
      avatar: response.data.account.avatar
    }),
    'USER_DETAILS:REMOVE_AVATAR_REQUEST_SUCCESS': state => ({
      ...state,
      avatar: null
    }),
    'USER_DETAILS:PROMOTE_TEACHER_REQUEST_SUCCESS': state => ({
      ...state,
      teacher_type: {
        ...state.teacher_type,
        school_leader: true
      }
    }),
    'USER_DETAILS:DELETE_TEACHER_REQUEST_SUCCESS': state => ({
      ...state,
      teacher_type: null
    }),
    'USER_DETAILS:DEMOTE_TEACHER_REQUEST_SUCCESS': state => ({
      ...state,
      teacher_type: {
        ...state.teacher_type,
        school_leader: false
      }
    }),
    'USER_DETAILS:DECLINE_TEACHER_REQUEST_SUCCESS': state => ({
      ...state,
      pending_teacher_type: null
    }),
    'USER_DETAILS:UNASSIGN_PARENT_REQUEST_SUCCESS': (state, { params }) => ({
      ...state,
      parent_types: state.parent_types.filter(
        child => child.student_type.account.id !== params.studentId
      )
    }),
    'USER_DETAILS:DEMOTE_POBBLER_REQUEST_SUCCESS': state => ({
      ...state,
      pobbler_type: null
    }),
    'USER_DETAILS:PROMOTE_POBBLER_REQUEST_SUCCESS': state => ({
      ...state,
      pobbler_type: {
        admin: false
      }
    }),
    'USER_DETAILS:PROMOTE_ADMIN_REQUEST_SUCCESS': state => ({
      ...state,
      pobbler_type: {
        admin: true
      }
    }),
    'USER_DETAILS:DEMOTE_ADMIN_REQUEST_SUCCESS': state => ({
      ...state,
      pobbler_type: {
        admin: false
      }
    })
  })
})

export default UserDetails
