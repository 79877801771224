import { connect } from 'react-redux'
import SchoolDetailsForm from './school-details-form'
import { withRouter } from 'react-router-dom'
import {
  isSchoolFetching,
  isSchoolBeingUpdated,
  getSchool
} from '../../../../stores/schools/schools.selectors'
import { isLogoUpdating } from '../../../../stores/school/school.selectors'
import { isCoverUpdating } from '../../../../stores/school/school.selectors'
import { getFilteredSchools } from '../../../../stores/schools-filter/schools-filter.selectors'
import {
  fetchSchool,
  updateSchool
} from '../../../../stores/school/school.actions'
import { errorHandler } from 'pobble-components/dist/error-handler'

const mapStateToProps = state => {
  return {
    notFound: !getSchool(state).id,
    showLoading: isSchoolFetching(state),
    isSchoolBeingUpdated: isSchoolBeingUpdated(state),
    school: getSchool(state),
    schools: getFilteredSchools(state),
    isLogoUpdating: isLogoUpdating(state),
    isCoverUpdating: isCoverUpdating(state)
  }
}

const mapDispatchToProps = (dispatch, { match }) => ({
  onMount: () => dispatch(fetchSchool(match.params.id)).catch(errorHandler),
  onSchoolUpdate: (id, school) =>
    dispatch(updateSchool(match.params.id, school)).catch(errorHandler)
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SchoolDetailsForm)
)
