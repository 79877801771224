import React from 'react'
import styles from './student-details-actions.styles'
import injectSheet from 'react-jss'
import Section from '../../../../shared/details/section/section'
import Button from 'pobble-components/dist/button'

const StudentDetailsActions = ({ classes, onRedact }) => (
  <Section title="Actions">
    <div className={classes.container}>
      <Button className={classes.btn} tall action={onRedact}>
        Redact
      </Button>
    </div>
  </Section>
)

export default injectSheet(styles)(StudentDetailsActions)
