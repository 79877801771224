import { connect } from 'react-redux'
import UserDetailsActions from './user-details-actions'
import { withRouter } from 'react-router'
import { redactAccount } from '../../../../../stores/user-details/user-details.actions'
import { errorHandler } from 'pobble-components/dist/error-handler'
import { toast } from 'pobble-components/dist/toast'

const mapDispatchToProps = (dispatch, { match }) => ({
  onRedact: () =>
    dispatch(redactAccount(match.params.id))
      .catch(errorHandler)
      .then(toast.success('This user was redacted'))
      .catch(errorHandler)
})

export default withRouter(connect(null, mapDispatchToProps)(UserDetailsActions))
