import React from 'react'
import styles from './campaign-creation-form.styles'
import injectSheet from 'react-jss'
import Section from '../../../shared/details/section/section'
import FormRow from '../../../shared/details/form-row/form-row'
import FormCell from '../../../shared/details/form-cell/form-cell'
import FormLabel from '../../../shared/details/form-label/form-label'
import FormInput from 'pobble-components/dist/form-elements/form-input'
import { Formik } from 'formik'
import Button from 'pobble-components/dist/button'
import FormTextArea from 'pobble-components/dist/form-elements/form-text-area'
import FormCheckbox from 'pobble-components/dist/form-elements/form-checkbox'
import FormRadio from 'pobble-components/dist/form-elements/form-radio'
import FormFile from 'pobble-components/dist/form-elements/form-file'

const CampaignCreationForm = ({ classes, onSubmit }) => (
  <Formik
    onSubmit={onSubmit}
    initialValues={{
      title: '',
      description: '',
      buttonLabel: '',
      buttonUrl: '',
      date: '',
      type: 'modal',
      active: false,
      audience: 'approved_teachers'
    }}>
    {formikProps => {
      const {
        values,
        handleBlur,
        handleChange,
        handleSubmit,
        isValid,
        setFieldValue
      } = formikProps
      return (
        <form onSubmit={handleSubmit} className={classes.form}>
          <Section
            title="Creation of a Campaign"
            renderHeader={() => (
              <Button
                className={classes.submitButton}
                disabled={!isValid}
                type="submit"
                tall>
                Submit
              </Button>
            )}>
            <FormRow>
              <FormCell>
                <FormLabel>Title</FormLabel>
                <FormInput
                  name="title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title}
                  placeholder={'Ex: Join the Pobble Testing Club!'}
                />
              </FormCell>
            </FormRow>
            <FormRow>
              <FormCell size="L">
                <FormLabel>Body text</FormLabel>
                <FormTextArea
                  name="description"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                  placeholder={
                    'Ex: Have your say from anywhere in the world via video chat with us.'
                  }
                />
              </FormCell>
            </FormRow>
            <FormRow>
              <FormCell>
                <FormLabel>Image</FormLabel>
                <FormFile
                  accept=".jpg,.png"
                  onChange={files => setFieldValue('file', files[0])}>
                  <Button span tall>
                    Upload
                  </Button>
                </FormFile>
              </FormCell>
            </FormRow>
            <FormRow>
              <FormCell>
                <FormLabel>Button label</FormLabel>
                <FormInput
                  name="buttonLabel"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.buttonLabel}
                  placeholder={'Ex: Join the club!'}
                />
              </FormCell>
              <FormCell>
                <FormLabel>Button destination</FormLabel>
                <FormInput
                  name="buttonUrl"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.buttonUrl}
                  placeholder={'Ex: https://pobble.com'}
                />
              </FormCell>
            </FormRow>
            <FormRow>
              <FormCell>
                <FormLabel>Date</FormLabel>
                <FormInput
                  name="date"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.date}
                  placeholder={'Ex: 2020-01-17'}
                />
              </FormCell>
              <FormCell>
                <FormLabel>Status</FormLabel>
                <div>
                  <FormCheckbox
                    name="active"
                    id="active"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.active}
                  />
                  <FormLabel htmlFor="active">Active</FormLabel>
                </div>
              </FormCell>
              <FormCell>
                <FormLabel>Audience</FormLabel>
                <div>
                  <FormRadio
                    name="audience"
                    id="approved_teachers"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={'approved_teachers'}
                    checked={values.audience === 'approved_teachers'}
                  />
                  <FormLabel htmlFor="approved_teachers">
                    Approved teachers
                  </FormLabel>
                </div>
                <div>
                  <FormRadio
                    name="audience"
                    id="approved_teachers_not_in_pobble_school"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={'approved_teachers_not_in_pobble_school'}
                    checked={
                      values.audience ===
                      'approved_teachers_not_in_pobble_school'
                    }
                  />
                  <FormLabel htmlFor="approved_teachers_not_in_pobble_school">
                    Approved teachers not in a Pobble School
                  </FormLabel>
                </div>
                <div>
                  <FormRadio
                    name="audience"
                    id="everyone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={'everyone'}
                    checked={values.audience === 'everyone'}
                  />
                  <FormLabel htmlFor="everyone">All users</FormLabel>
                </div>
              </FormCell>
            </FormRow>
          </Section>
        </form>
      )
    }}
  </Formik>
)

export default injectSheet(styles)(CampaignCreationForm)
