import React from 'react'
import injectSheet from 'react-jss'

import styles from './teacher-notes-modal.styles.js'

import Button from 'pobble-components/dist/button'
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter
} from 'pobble-components/dist/modal'
import TextArea from 'pobble-components/dist/form-elements/form-text-area'

import { Formik } from 'formik'

const TeacherNotesModal = ({
  classes,
  id,
  onUpdate,
  isOpen,
  onClose,
  notes
}) => (
  <Modal legacy isOpen={isOpen}>
    <ModalHeader>Type a note</ModalHeader>
    <Formik
      initialValues={{
        editedNotes: notes || ''
      }}
      onSubmit={({ editedNotes }) => onUpdate(id, editedNotes) && onClose()}>
      {props => {
        const { handleSubmit, handleChange, values } = props
        return (
          <form onSubmit={handleSubmit}>
            <ModalContent>
              <TextArea
                name="editedNotes"
                value={values.editedNotes}
                onChange={handleChange}
              />
            </ModalContent>
            <ModalFooter className={classes.footer}>
              <Button simple action={onClose}>
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </ModalFooter>
          </form>
        )
      }}
    </Formik>
  </Modal>
)

export default injectSheet(styles)(TeacherNotesModal)
