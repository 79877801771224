export default ({ COLORS, FONT_SIZES }) => ({
  container: {
    display: 'flex',
    padding: '22px',
    margin: '-22px -22px -22px',
    borderBottom: '1px solid #dedede',
    justifyContent: 'center',
    position: 'relative'
  },
  info: {
    margin: '0',
    padding: '0',
    listStyle: 'none',
    ...FONT_SIZES.HEADING_DEFAULT.STYLE,
    color: COLORS.TEXT.LIGHT_GREY.NORMAL
  },
  backLink: {
    position: 'absolute',
    left: '22px',
    top: '22px',
    color: COLORS.BLUE.NORMAL,
    fontSize: '14px',
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none'
  },
  avatar: {
    margin: '0 0 10px'
  },
  names: {
    textAlign: 'center',
    '& strong': {
      display: 'block',
      fontSize: '22px',
      lineHeight: '22px',
      fontWeight: 'normal',
      color: COLORS.TEXT.MID_GREY.NORMAL
    },
    '& span': {
      fontSize: '18px',
      lineHeight: '18px',
      color: COLORS.TEXT.LIGHT_GREY.NORMAL
    }
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      flexShrink: '0'
    }
  }
})
